// source: api/access_device_profile.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var common_common_pb = require('../common/common_pb.js');
goog.object.extend(proto, common_common_pb);
goog.exportSymbol('proto.api.AccessDeviceProfile', null, global);
goog.exportSymbol('proto.api.AccessDeviceProfileProperties', null, global);
goog.exportSymbol('proto.api.AccessDeviceProfileProperties.DevicePropertiesCase', null, global);
goog.exportSymbol('proto.api.CreateAccessDeviceProfileReply', null, global);
goog.exportSymbol('proto.api.CreateAccessDeviceProfileRequest', null, global);
goog.exportSymbol('proto.api.DataPointBACnetProperties', null, global);
goog.exportSymbol('proto.api.DataPointLoRaWANProperties', null, global);
goog.exportSymbol('proto.api.DataPointModbusProperties', null, global);
goog.exportSymbol('proto.api.DataPointType', null, global);
goog.exportSymbol('proto.api.DataPointType.PropertiesCase', null, global);
goog.exportSymbol('proto.api.DeleteAccessDeviceProfileReply', null, global);
goog.exportSymbol('proto.api.DeleteAccessDeviceProfileRequest', null, global);
goog.exportSymbol('proto.api.GetAccessDeviceProfileReply', null, global);
goog.exportSymbol('proto.api.GetAccessDeviceProfileRequest', null, global);
goog.exportSymbol('proto.api.ListAccessDeviceProfileByCategoryReply', null, global);
goog.exportSymbol('proto.api.ListAccessDeviceProfileByCategoryRequest', null, global);
goog.exportSymbol('proto.api.ListAccessDeviceProfileReply', null, global);
goog.exportSymbol('proto.api.ListAccessDeviceProfileRequest', null, global);
goog.exportSymbol('proto.api.LoRaWANDeviceProfileProperties', null, global);
goog.exportSymbol('proto.api.UpdateAccessDeviceProfileReply', null, global);
goog.exportSymbol('proto.api.UpdateAccessDeviceProfileRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DataPointModbusProperties = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DataPointModbusProperties, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DataPointModbusProperties.displayName = 'proto.api.DataPointModbusProperties';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DataPointLoRaWANProperties = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DataPointLoRaWANProperties, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DataPointLoRaWANProperties.displayName = 'proto.api.DataPointLoRaWANProperties';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DataPointBACnetProperties = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DataPointBACnetProperties, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DataPointBACnetProperties.displayName = 'proto.api.DataPointBACnetProperties';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DataPointType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.DataPointType.oneofGroups_);
};
goog.inherits(proto.api.DataPointType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DataPointType.displayName = 'proto.api.DataPointType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.LoRaWANDeviceProfileProperties = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.LoRaWANDeviceProfileProperties.repeatedFields_, null);
};
goog.inherits(proto.api.LoRaWANDeviceProfileProperties, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.LoRaWANDeviceProfileProperties.displayName = 'proto.api.LoRaWANDeviceProfileProperties';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AccessDeviceProfileProperties = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.AccessDeviceProfileProperties.oneofGroups_);
};
goog.inherits(proto.api.AccessDeviceProfileProperties, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AccessDeviceProfileProperties.displayName = 'proto.api.AccessDeviceProfileProperties';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AccessDeviceProfile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.AccessDeviceProfile.repeatedFields_, null);
};
goog.inherits(proto.api.AccessDeviceProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AccessDeviceProfile.displayName = 'proto.api.AccessDeviceProfile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListAccessDeviceProfileByCategoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListAccessDeviceProfileByCategoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListAccessDeviceProfileByCategoryRequest.displayName = 'proto.api.ListAccessDeviceProfileByCategoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListAccessDeviceProfileByCategoryReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ListAccessDeviceProfileByCategoryReply.repeatedFields_, null);
};
goog.inherits(proto.api.ListAccessDeviceProfileByCategoryReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListAccessDeviceProfileByCategoryReply.displayName = 'proto.api.ListAccessDeviceProfileByCategoryReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListAccessDeviceProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListAccessDeviceProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListAccessDeviceProfileRequest.displayName = 'proto.api.ListAccessDeviceProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListAccessDeviceProfileReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ListAccessDeviceProfileReply.repeatedFields_, null);
};
goog.inherits(proto.api.ListAccessDeviceProfileReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListAccessDeviceProfileReply.displayName = 'proto.api.ListAccessDeviceProfileReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CreateAccessDeviceProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CreateAccessDeviceProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CreateAccessDeviceProfileRequest.displayName = 'proto.api.CreateAccessDeviceProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CreateAccessDeviceProfileReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CreateAccessDeviceProfileReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CreateAccessDeviceProfileReply.displayName = 'proto.api.CreateAccessDeviceProfileReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateAccessDeviceProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateAccessDeviceProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateAccessDeviceProfileRequest.displayName = 'proto.api.UpdateAccessDeviceProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateAccessDeviceProfileReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateAccessDeviceProfileReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateAccessDeviceProfileReply.displayName = 'proto.api.UpdateAccessDeviceProfileReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DeleteAccessDeviceProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DeleteAccessDeviceProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DeleteAccessDeviceProfileRequest.displayName = 'proto.api.DeleteAccessDeviceProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DeleteAccessDeviceProfileReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DeleteAccessDeviceProfileReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DeleteAccessDeviceProfileReply.displayName = 'proto.api.DeleteAccessDeviceProfileReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetAccessDeviceProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetAccessDeviceProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetAccessDeviceProfileRequest.displayName = 'proto.api.GetAccessDeviceProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetAccessDeviceProfileReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetAccessDeviceProfileReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetAccessDeviceProfileReply.displayName = 'proto.api.GetAccessDeviceProfileReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DataPointModbusProperties.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DataPointModbusProperties.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DataPointModbusProperties} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DataPointModbusProperties.toObject = function(includeInstance, msg) {
  var f, obj = {
    registerAddress: jspb.Message.getFieldWithDefault(msg, 1, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    valueType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    scale: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DataPointModbusProperties}
 */
proto.api.DataPointModbusProperties.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DataPointModbusProperties;
  return proto.api.DataPointModbusProperties.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DataPointModbusProperties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DataPointModbusProperties}
 */
proto.api.DataPointModbusProperties.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRegisterAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {!proto.common.DataValueType} */ (reader.readEnum());
      msg.setValueType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScale(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DataPointModbusProperties.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DataPointModbusProperties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DataPointModbusProperties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DataPointModbusProperties.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegisterAddress();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getValueType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getScale();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional uint32 register_address = 1;
 * @return {number}
 */
proto.api.DataPointModbusProperties.prototype.getRegisterAddress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.DataPointModbusProperties} returns this
 */
proto.api.DataPointModbusProperties.prototype.setRegisterAddress = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 quantity = 2;
 * @return {number}
 */
proto.api.DataPointModbusProperties.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.DataPointModbusProperties} returns this
 */
proto.api.DataPointModbusProperties.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional common.DataValueType value_type = 3;
 * @return {!proto.common.DataValueType}
 */
proto.api.DataPointModbusProperties.prototype.getValueType = function() {
  return /** @type {!proto.common.DataValueType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.DataValueType} value
 * @return {!proto.api.DataPointModbusProperties} returns this
 */
proto.api.DataPointModbusProperties.prototype.setValueType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 scale = 4;
 * @return {number}
 */
proto.api.DataPointModbusProperties.prototype.getScale = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.DataPointModbusProperties} returns this
 */
proto.api.DataPointModbusProperties.prototype.setScale = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DataPointLoRaWANProperties.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DataPointLoRaWANProperties.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DataPointLoRaWANProperties} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DataPointLoRaWANProperties.toObject = function(includeInstance, msg) {
  var f, obj = {
    downlinkFport: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DataPointLoRaWANProperties}
 */
proto.api.DataPointLoRaWANProperties.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DataPointLoRaWANProperties;
  return proto.api.DataPointLoRaWANProperties.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DataPointLoRaWANProperties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DataPointLoRaWANProperties}
 */
proto.api.DataPointLoRaWANProperties.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDownlinkFport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DataPointLoRaWANProperties.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DataPointLoRaWANProperties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DataPointLoRaWANProperties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DataPointLoRaWANProperties.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDownlinkFport();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 downlink_fport = 1;
 * @return {number}
 */
proto.api.DataPointLoRaWANProperties.prototype.getDownlinkFport = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.DataPointLoRaWANProperties} returns this
 */
proto.api.DataPointLoRaWANProperties.prototype.setDownlinkFport = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DataPointBACnetProperties.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DataPointBACnetProperties.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DataPointBACnetProperties} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DataPointBACnetProperties.toObject = function(includeInstance, msg) {
  var f, obj = {
    covIncrement: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DataPointBACnetProperties}
 */
proto.api.DataPointBACnetProperties.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DataPointBACnetProperties;
  return proto.api.DataPointBACnetProperties.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DataPointBACnetProperties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DataPointBACnetProperties}
 */
proto.api.DataPointBACnetProperties.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCovIncrement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DataPointBACnetProperties.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DataPointBACnetProperties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DataPointBACnetProperties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DataPointBACnetProperties.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCovIncrement();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
};


/**
 * optional float cov_increment = 1;
 * @return {number}
 */
proto.api.DataPointBACnetProperties.prototype.getCovIncrement = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.api.DataPointBACnetProperties} returns this
 */
proto.api.DataPointBACnetProperties.prototype.setCovIncrement = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.DataPointType.oneofGroups_ = [[7,8,9]];

/**
 * @enum {number}
 */
proto.api.DataPointType.PropertiesCase = {
  PROPERTIES_NOT_SET: 0,
  MODBUS: 7,
  LORAWAN: 8,
  BACNET: 9
};

/**
 * @return {proto.api.DataPointType.PropertiesCase}
 */
proto.api.DataPointType.prototype.getPropertiesCase = function() {
  return /** @type {proto.api.DataPointType.PropertiesCase} */(jspb.Message.computeOneofCase(this, proto.api.DataPointType.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DataPointType.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DataPointType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DataPointType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DataPointType.toObject = function(includeInstance, msg) {
  var f, obj = {
    channel: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    unit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    writable: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    updateInterval: jspb.Message.getFieldWithDefault(msg, 6, 0),
    modbus: (f = msg.getModbus()) && proto.api.DataPointModbusProperties.toObject(includeInstance, f),
    lorawan: (f = msg.getLorawan()) && proto.api.DataPointLoRaWANProperties.toObject(includeInstance, f),
    bacnet: (f = msg.getBacnet()) && proto.api.DataPointBACnetProperties.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DataPointType}
 */
proto.api.DataPointType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DataPointType;
  return proto.api.DataPointType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DataPointType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DataPointType}
 */
proto.api.DataPointType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChannel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.common.DataObjectTypeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {!proto.common.EngineeringUnits} */ (reader.readEnum());
      msg.setUnit(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWritable(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUpdateInterval(value);
      break;
    case 7:
      var value = new proto.api.DataPointModbusProperties;
      reader.readMessage(value,proto.api.DataPointModbusProperties.deserializeBinaryFromReader);
      msg.setModbus(value);
      break;
    case 8:
      var value = new proto.api.DataPointLoRaWANProperties;
      reader.readMessage(value,proto.api.DataPointLoRaWANProperties.deserializeBinaryFromReader);
      msg.setLorawan(value);
      break;
    case 9:
      var value = new proto.api.DataPointBACnetProperties;
      reader.readMessage(value,proto.api.DataPointBACnetProperties.deserializeBinaryFromReader);
      msg.setBacnet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DataPointType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DataPointType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DataPointType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DataPointType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannel();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getWritable();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getUpdateInterval();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getModbus();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.api.DataPointModbusProperties.serializeBinaryToWriter
    );
  }
  f = message.getLorawan();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.api.DataPointLoRaWANProperties.serializeBinaryToWriter
    );
  }
  f = message.getBacnet();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.api.DataPointBACnetProperties.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 channel = 1;
 * @return {number}
 */
proto.api.DataPointType.prototype.getChannel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.DataPointType} returns this
 */
proto.api.DataPointType.prototype.setChannel = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.DataPointType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.DataPointType} returns this
 */
proto.api.DataPointType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional common.DataObjectTypeEnum type = 3;
 * @return {!proto.common.DataObjectTypeEnum}
 */
proto.api.DataPointType.prototype.getType = function() {
  return /** @type {!proto.common.DataObjectTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.DataObjectTypeEnum} value
 * @return {!proto.api.DataPointType} returns this
 */
proto.api.DataPointType.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional common.EngineeringUnits unit = 4;
 * @return {!proto.common.EngineeringUnits}
 */
proto.api.DataPointType.prototype.getUnit = function() {
  return /** @type {!proto.common.EngineeringUnits} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.EngineeringUnits} value
 * @return {!proto.api.DataPointType} returns this
 */
proto.api.DataPointType.prototype.setUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bool writable = 5;
 * @return {boolean}
 */
proto.api.DataPointType.prototype.getWritable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.DataPointType} returns this
 */
proto.api.DataPointType.prototype.setWritable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional uint32 update_interval = 6;
 * @return {number}
 */
proto.api.DataPointType.prototype.getUpdateInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.DataPointType} returns this
 */
proto.api.DataPointType.prototype.setUpdateInterval = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional DataPointModbusProperties modbus = 7;
 * @return {?proto.api.DataPointModbusProperties}
 */
proto.api.DataPointType.prototype.getModbus = function() {
  return /** @type{?proto.api.DataPointModbusProperties} */ (
    jspb.Message.getWrapperField(this, proto.api.DataPointModbusProperties, 7));
};


/**
 * @param {?proto.api.DataPointModbusProperties|undefined} value
 * @return {!proto.api.DataPointType} returns this
*/
proto.api.DataPointType.prototype.setModbus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.api.DataPointType.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.DataPointType} returns this
 */
proto.api.DataPointType.prototype.clearModbus = function() {
  return this.setModbus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.DataPointType.prototype.hasModbus = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional DataPointLoRaWANProperties lorawan = 8;
 * @return {?proto.api.DataPointLoRaWANProperties}
 */
proto.api.DataPointType.prototype.getLorawan = function() {
  return /** @type{?proto.api.DataPointLoRaWANProperties} */ (
    jspb.Message.getWrapperField(this, proto.api.DataPointLoRaWANProperties, 8));
};


/**
 * @param {?proto.api.DataPointLoRaWANProperties|undefined} value
 * @return {!proto.api.DataPointType} returns this
*/
proto.api.DataPointType.prototype.setLorawan = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.api.DataPointType.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.DataPointType} returns this
 */
proto.api.DataPointType.prototype.clearLorawan = function() {
  return this.setLorawan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.DataPointType.prototype.hasLorawan = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional DataPointBACnetProperties bacnet = 9;
 * @return {?proto.api.DataPointBACnetProperties}
 */
proto.api.DataPointType.prototype.getBacnet = function() {
  return /** @type{?proto.api.DataPointBACnetProperties} */ (
    jspb.Message.getWrapperField(this, proto.api.DataPointBACnetProperties, 9));
};


/**
 * @param {?proto.api.DataPointBACnetProperties|undefined} value
 * @return {!proto.api.DataPointType} returns this
*/
proto.api.DataPointType.prototype.setBacnet = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.api.DataPointType.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.DataPointType} returns this
 */
proto.api.DataPointType.prototype.clearBacnet = function() {
  return this.setBacnet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.DataPointType.prototype.hasBacnet = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.LoRaWANDeviceProfileProperties.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.LoRaWANDeviceProfileProperties.prototype.toObject = function(opt_includeInstance) {
  return proto.api.LoRaWANDeviceProfileProperties.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.LoRaWANDeviceProfileProperties} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.LoRaWANDeviceProfileProperties.toObject = function(includeInstance, msg) {
  var f, obj = {
    regionList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    macVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    regionalParametersRevision: jspb.Message.getFieldWithDefault(msg, 3, 0),
    supportsOtaa: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    supportsClassB: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    supportsClassC: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    profileIdByRegionMap: (f = msg.getProfileIdByRegionMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.LoRaWANDeviceProfileProperties}
 */
proto.api.LoRaWANDeviceProfileProperties.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.LoRaWANDeviceProfileProperties;
  return proto.api.LoRaWANDeviceProfileProperties.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.LoRaWANDeviceProfileProperties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.LoRaWANDeviceProfileProperties}
 */
proto.api.LoRaWANDeviceProfileProperties.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.common.Region>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRegion(values[i]);
      }
      break;
    case 2:
      var value = /** @type {!proto.common.MacVersion} */ (reader.readEnum());
      msg.setMacVersion(value);
      break;
    case 3:
      var value = /** @type {!proto.common.RegParamsRevision} */ (reader.readEnum());
      msg.setRegionalParametersRevision(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSupportsOtaa(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSupportsClassB(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSupportsClassC(value);
      break;
    case 7:
      var value = msg.getProfileIdByRegionMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readString, null, 0, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.LoRaWANDeviceProfileProperties.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.LoRaWANDeviceProfileProperties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.LoRaWANDeviceProfileProperties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.LoRaWANDeviceProfileProperties.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegionList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getMacVersion();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getRegionalParametersRevision();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSupportsOtaa();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSupportsClassB();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getSupportsClassC();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getProfileIdByRegionMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * repeated common.Region region = 1;
 * @return {!Array<!proto.common.Region>}
 */
proto.api.LoRaWANDeviceProfileProperties.prototype.getRegionList = function() {
  return /** @type {!Array<!proto.common.Region>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.common.Region>} value
 * @return {!proto.api.LoRaWANDeviceProfileProperties} returns this
 */
proto.api.LoRaWANDeviceProfileProperties.prototype.setRegionList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.common.Region} value
 * @param {number=} opt_index
 * @return {!proto.api.LoRaWANDeviceProfileProperties} returns this
 */
proto.api.LoRaWANDeviceProfileProperties.prototype.addRegion = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.LoRaWANDeviceProfileProperties} returns this
 */
proto.api.LoRaWANDeviceProfileProperties.prototype.clearRegionList = function() {
  return this.setRegionList([]);
};


/**
 * optional common.MacVersion mac_version = 2;
 * @return {!proto.common.MacVersion}
 */
proto.api.LoRaWANDeviceProfileProperties.prototype.getMacVersion = function() {
  return /** @type {!proto.common.MacVersion} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.MacVersion} value
 * @return {!proto.api.LoRaWANDeviceProfileProperties} returns this
 */
proto.api.LoRaWANDeviceProfileProperties.prototype.setMacVersion = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional common.RegParamsRevision regional_parameters_revision = 3;
 * @return {!proto.common.RegParamsRevision}
 */
proto.api.LoRaWANDeviceProfileProperties.prototype.getRegionalParametersRevision = function() {
  return /** @type {!proto.common.RegParamsRevision} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.RegParamsRevision} value
 * @return {!proto.api.LoRaWANDeviceProfileProperties} returns this
 */
proto.api.LoRaWANDeviceProfileProperties.prototype.setRegionalParametersRevision = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool supports_otaa = 4;
 * @return {boolean}
 */
proto.api.LoRaWANDeviceProfileProperties.prototype.getSupportsOtaa = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.LoRaWANDeviceProfileProperties} returns this
 */
proto.api.LoRaWANDeviceProfileProperties.prototype.setSupportsOtaa = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool supports_class_b = 5;
 * @return {boolean}
 */
proto.api.LoRaWANDeviceProfileProperties.prototype.getSupportsClassB = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.LoRaWANDeviceProfileProperties} returns this
 */
proto.api.LoRaWANDeviceProfileProperties.prototype.setSupportsClassB = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool supports_class_c = 6;
 * @return {boolean}
 */
proto.api.LoRaWANDeviceProfileProperties.prototype.getSupportsClassC = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.LoRaWANDeviceProfileProperties} returns this
 */
proto.api.LoRaWANDeviceProfileProperties.prototype.setSupportsClassC = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * map<uint32, string> profile_id_by_region = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,string>}
 */
proto.api.LoRaWANDeviceProfileProperties.prototype.getProfileIdByRegionMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,string>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.api.LoRaWANDeviceProfileProperties} returns this
 */
proto.api.LoRaWANDeviceProfileProperties.prototype.clearProfileIdByRegionMap = function() {
  this.getProfileIdByRegionMap().clear();
  return this;};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.AccessDeviceProfileProperties.oneofGroups_ = [[2]];

/**
 * @enum {number}
 */
proto.api.AccessDeviceProfileProperties.DevicePropertiesCase = {
  DEVICE_PROPERTIES_NOT_SET: 0,
  LORAWAN: 2
};

/**
 * @return {proto.api.AccessDeviceProfileProperties.DevicePropertiesCase}
 */
proto.api.AccessDeviceProfileProperties.prototype.getDevicePropertiesCase = function() {
  return /** @type {proto.api.AccessDeviceProfileProperties.DevicePropertiesCase} */(jspb.Message.computeOneofCase(this, proto.api.AccessDeviceProfileProperties.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AccessDeviceProfileProperties.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AccessDeviceProfileProperties.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AccessDeviceProfileProperties} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AccessDeviceProfileProperties.toObject = function(includeInstance, msg) {
  var f, obj = {
    codec: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lorawan: (f = msg.getLorawan()) && proto.api.LoRaWANDeviceProfileProperties.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AccessDeviceProfileProperties}
 */
proto.api.AccessDeviceProfileProperties.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AccessDeviceProfileProperties;
  return proto.api.AccessDeviceProfileProperties.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AccessDeviceProfileProperties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AccessDeviceProfileProperties}
 */
proto.api.AccessDeviceProfileProperties.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCodec(value);
      break;
    case 2:
      var value = new proto.api.LoRaWANDeviceProfileProperties;
      reader.readMessage(value,proto.api.LoRaWANDeviceProfileProperties.deserializeBinaryFromReader);
      msg.setLorawan(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AccessDeviceProfileProperties.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AccessDeviceProfileProperties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AccessDeviceProfileProperties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AccessDeviceProfileProperties.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCodec();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLorawan();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.LoRaWANDeviceProfileProperties.serializeBinaryToWriter
    );
  }
};


/**
 * optional string codec = 1;
 * @return {string}
 */
proto.api.AccessDeviceProfileProperties.prototype.getCodec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AccessDeviceProfileProperties} returns this
 */
proto.api.AccessDeviceProfileProperties.prototype.setCodec = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional LoRaWANDeviceProfileProperties lorawan = 2;
 * @return {?proto.api.LoRaWANDeviceProfileProperties}
 */
proto.api.AccessDeviceProfileProperties.prototype.getLorawan = function() {
  return /** @type{?proto.api.LoRaWANDeviceProfileProperties} */ (
    jspb.Message.getWrapperField(this, proto.api.LoRaWANDeviceProfileProperties, 2));
};


/**
 * @param {?proto.api.LoRaWANDeviceProfileProperties|undefined} value
 * @return {!proto.api.AccessDeviceProfileProperties} returns this
*/
proto.api.AccessDeviceProfileProperties.prototype.setLorawan = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.api.AccessDeviceProfileProperties.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.AccessDeviceProfileProperties} returns this
 */
proto.api.AccessDeviceProfileProperties.prototype.clearLorawan = function() {
  return this.setLorawan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.AccessDeviceProfileProperties.prototype.hasLorawan = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.AccessDeviceProfile.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AccessDeviceProfile.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AccessDeviceProfile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AccessDeviceProfile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AccessDeviceProfile.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    vendor: jspb.Message.getFieldWithDefault(msg, 4, ""),
    model: jspb.Message.getFieldWithDefault(msg, 5, ""),
    category: jspb.Message.getFieldWithDefault(msg, 6, 0),
    properties: (f = msg.getProperties()) && proto.api.AccessDeviceProfileProperties.toObject(includeInstance, f),
    dataPointsList: jspb.Message.toObjectList(msg.getDataPointsList(),
    proto.api.DataPointType.toObject, includeInstance),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    note: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AccessDeviceProfile}
 */
proto.api.AccessDeviceProfile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AccessDeviceProfile;
  return proto.api.AccessDeviceProfile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AccessDeviceProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AccessDeviceProfile}
 */
proto.api.AccessDeviceProfile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVendor(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 6:
      var value = /** @type {!proto.common.CategoryMap} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 7:
      var value = new proto.api.AccessDeviceProfileProperties;
      reader.readMessage(value,proto.api.AccessDeviceProfileProperties.deserializeBinaryFromReader);
      msg.setProperties(value);
      break;
    case 8:
      var value = new proto.api.DataPointType;
      reader.readMessage(value,proto.api.DataPointType.deserializeBinaryFromReader);
      msg.addDataPoints(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AccessDeviceProfile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AccessDeviceProfile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AccessDeviceProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AccessDeviceProfile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVendor();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getProperties();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.api.AccessDeviceProfileProperties.serializeBinaryToWriter
    );
  }
  f = message.getDataPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.api.DataPointType.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.AccessDeviceProfile.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AccessDeviceProfile} returns this
 */
proto.api.AccessDeviceProfile.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.AccessDeviceProfile.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AccessDeviceProfile} returns this
 */
proto.api.AccessDeviceProfile.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.api.AccessDeviceProfile.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AccessDeviceProfile} returns this
 */
proto.api.AccessDeviceProfile.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string vendor = 4;
 * @return {string}
 */
proto.api.AccessDeviceProfile.prototype.getVendor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AccessDeviceProfile} returns this
 */
proto.api.AccessDeviceProfile.prototype.setVendor = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string model = 5;
 * @return {string}
 */
proto.api.AccessDeviceProfile.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AccessDeviceProfile} returns this
 */
proto.api.AccessDeviceProfile.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional common.CategoryMap category = 6;
 * @return {!proto.common.CategoryMap}
 */
proto.api.AccessDeviceProfile.prototype.getCategory = function() {
  return /** @type {!proto.common.CategoryMap} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.common.CategoryMap} value
 * @return {!proto.api.AccessDeviceProfile} returns this
 */
proto.api.AccessDeviceProfile.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional AccessDeviceProfileProperties properties = 7;
 * @return {?proto.api.AccessDeviceProfileProperties}
 */
proto.api.AccessDeviceProfile.prototype.getProperties = function() {
  return /** @type{?proto.api.AccessDeviceProfileProperties} */ (
    jspb.Message.getWrapperField(this, proto.api.AccessDeviceProfileProperties, 7));
};


/**
 * @param {?proto.api.AccessDeviceProfileProperties|undefined} value
 * @return {!proto.api.AccessDeviceProfile} returns this
*/
proto.api.AccessDeviceProfile.prototype.setProperties = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.AccessDeviceProfile} returns this
 */
proto.api.AccessDeviceProfile.prototype.clearProperties = function() {
  return this.setProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.AccessDeviceProfile.prototype.hasProperties = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated DataPointType data_points = 8;
 * @return {!Array<!proto.api.DataPointType>}
 */
proto.api.AccessDeviceProfile.prototype.getDataPointsList = function() {
  return /** @type{!Array<!proto.api.DataPointType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.DataPointType, 8));
};


/**
 * @param {!Array<!proto.api.DataPointType>} value
 * @return {!proto.api.AccessDeviceProfile} returns this
*/
proto.api.AccessDeviceProfile.prototype.setDataPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.api.DataPointType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.DataPointType}
 */
proto.api.AccessDeviceProfile.prototype.addDataPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.api.DataPointType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.AccessDeviceProfile} returns this
 */
proto.api.AccessDeviceProfile.prototype.clearDataPointsList = function() {
  return this.setDataPointsList([]);
};


/**
 * optional google.protobuf.Timestamp created_at = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.AccessDeviceProfile.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.AccessDeviceProfile} returns this
*/
proto.api.AccessDeviceProfile.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.AccessDeviceProfile} returns this
 */
proto.api.AccessDeviceProfile.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.AccessDeviceProfile.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.AccessDeviceProfile.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.AccessDeviceProfile} returns this
*/
proto.api.AccessDeviceProfile.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.AccessDeviceProfile} returns this
 */
proto.api.AccessDeviceProfile.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.AccessDeviceProfile.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string note = 11;
 * @return {string}
 */
proto.api.AccessDeviceProfile.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AccessDeviceProfile} returns this
 */
proto.api.AccessDeviceProfile.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListAccessDeviceProfileByCategoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListAccessDeviceProfileByCategoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListAccessDeviceProfileByCategoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListAccessDeviceProfileByCategoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListAccessDeviceProfileByCategoryRequest}
 */
proto.api.ListAccessDeviceProfileByCategoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListAccessDeviceProfileByCategoryRequest;
  return proto.api.ListAccessDeviceProfileByCategoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListAccessDeviceProfileByCategoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListAccessDeviceProfileByCategoryRequest}
 */
proto.api.ListAccessDeviceProfileByCategoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.CategoryMap} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListAccessDeviceProfileByCategoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListAccessDeviceProfileByCategoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListAccessDeviceProfileByCategoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListAccessDeviceProfileByCategoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional common.CategoryMap category = 1;
 * @return {!proto.common.CategoryMap}
 */
proto.api.ListAccessDeviceProfileByCategoryRequest.prototype.getCategory = function() {
  return /** @type {!proto.common.CategoryMap} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.CategoryMap} value
 * @return {!proto.api.ListAccessDeviceProfileByCategoryRequest} returns this
 */
proto.api.ListAccessDeviceProfileByCategoryRequest.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ListAccessDeviceProfileByCategoryReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListAccessDeviceProfileByCategoryReply.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListAccessDeviceProfileByCategoryReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListAccessDeviceProfileByCategoryReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListAccessDeviceProfileByCategoryReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultList: jspb.Message.toObjectList(msg.getResultList(),
    proto.api.AccessDeviceProfile.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListAccessDeviceProfileByCategoryReply}
 */
proto.api.ListAccessDeviceProfileByCategoryReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListAccessDeviceProfileByCategoryReply;
  return proto.api.ListAccessDeviceProfileByCategoryReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListAccessDeviceProfileByCategoryReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListAccessDeviceProfileByCategoryReply}
 */
proto.api.ListAccessDeviceProfileByCategoryReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.AccessDeviceProfile;
      reader.readMessage(value,proto.api.AccessDeviceProfile.deserializeBinaryFromReader);
      msg.addResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListAccessDeviceProfileByCategoryReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListAccessDeviceProfileByCategoryReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListAccessDeviceProfileByCategoryReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListAccessDeviceProfileByCategoryReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.AccessDeviceProfile.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AccessDeviceProfile result = 1;
 * @return {!Array<!proto.api.AccessDeviceProfile>}
 */
proto.api.ListAccessDeviceProfileByCategoryReply.prototype.getResultList = function() {
  return /** @type{!Array<!proto.api.AccessDeviceProfile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.AccessDeviceProfile, 1));
};


/**
 * @param {!Array<!proto.api.AccessDeviceProfile>} value
 * @return {!proto.api.ListAccessDeviceProfileByCategoryReply} returns this
*/
proto.api.ListAccessDeviceProfileByCategoryReply.prototype.setResultList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.AccessDeviceProfile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.AccessDeviceProfile}
 */
proto.api.ListAccessDeviceProfileByCategoryReply.prototype.addResult = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.AccessDeviceProfile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ListAccessDeviceProfileByCategoryReply} returns this
 */
proto.api.ListAccessDeviceProfileByCategoryReply.prototype.clearResultList = function() {
  return this.setResultList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListAccessDeviceProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListAccessDeviceProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListAccessDeviceProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListAccessDeviceProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListAccessDeviceProfileRequest}
 */
proto.api.ListAccessDeviceProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListAccessDeviceProfileRequest;
  return proto.api.ListAccessDeviceProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListAccessDeviceProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListAccessDeviceProfileRequest}
 */
proto.api.ListAccessDeviceProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListAccessDeviceProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListAccessDeviceProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListAccessDeviceProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListAccessDeviceProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ListAccessDeviceProfileReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListAccessDeviceProfileReply.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListAccessDeviceProfileReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListAccessDeviceProfileReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListAccessDeviceProfileReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultList: jspb.Message.toObjectList(msg.getResultList(),
    proto.api.AccessDeviceProfile.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListAccessDeviceProfileReply}
 */
proto.api.ListAccessDeviceProfileReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListAccessDeviceProfileReply;
  return proto.api.ListAccessDeviceProfileReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListAccessDeviceProfileReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListAccessDeviceProfileReply}
 */
proto.api.ListAccessDeviceProfileReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.AccessDeviceProfile;
      reader.readMessage(value,proto.api.AccessDeviceProfile.deserializeBinaryFromReader);
      msg.addResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListAccessDeviceProfileReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListAccessDeviceProfileReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListAccessDeviceProfileReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListAccessDeviceProfileReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.AccessDeviceProfile.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AccessDeviceProfile result = 1;
 * @return {!Array<!proto.api.AccessDeviceProfile>}
 */
proto.api.ListAccessDeviceProfileReply.prototype.getResultList = function() {
  return /** @type{!Array<!proto.api.AccessDeviceProfile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.AccessDeviceProfile, 1));
};


/**
 * @param {!Array<!proto.api.AccessDeviceProfile>} value
 * @return {!proto.api.ListAccessDeviceProfileReply} returns this
*/
proto.api.ListAccessDeviceProfileReply.prototype.setResultList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.AccessDeviceProfile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.AccessDeviceProfile}
 */
proto.api.ListAccessDeviceProfileReply.prototype.addResult = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.AccessDeviceProfile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ListAccessDeviceProfileReply} returns this
 */
proto.api.ListAccessDeviceProfileReply.prototype.clearResultList = function() {
  return this.setResultList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CreateAccessDeviceProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CreateAccessDeviceProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CreateAccessDeviceProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateAccessDeviceProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    profile: (f = msg.getProfile()) && proto.api.AccessDeviceProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CreateAccessDeviceProfileRequest}
 */
proto.api.CreateAccessDeviceProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CreateAccessDeviceProfileRequest;
  return proto.api.CreateAccessDeviceProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CreateAccessDeviceProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CreateAccessDeviceProfileRequest}
 */
proto.api.CreateAccessDeviceProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.AccessDeviceProfile;
      reader.readMessage(value,proto.api.AccessDeviceProfile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CreateAccessDeviceProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CreateAccessDeviceProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CreateAccessDeviceProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateAccessDeviceProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.AccessDeviceProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional AccessDeviceProfile profile = 1;
 * @return {?proto.api.AccessDeviceProfile}
 */
proto.api.CreateAccessDeviceProfileRequest.prototype.getProfile = function() {
  return /** @type{?proto.api.AccessDeviceProfile} */ (
    jspb.Message.getWrapperField(this, proto.api.AccessDeviceProfile, 1));
};


/**
 * @param {?proto.api.AccessDeviceProfile|undefined} value
 * @return {!proto.api.CreateAccessDeviceProfileRequest} returns this
*/
proto.api.CreateAccessDeviceProfileRequest.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CreateAccessDeviceProfileRequest} returns this
 */
proto.api.CreateAccessDeviceProfileRequest.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CreateAccessDeviceProfileRequest.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CreateAccessDeviceProfileReply.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CreateAccessDeviceProfileReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CreateAccessDeviceProfileReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateAccessDeviceProfileReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CreateAccessDeviceProfileReply}
 */
proto.api.CreateAccessDeviceProfileReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CreateAccessDeviceProfileReply;
  return proto.api.CreateAccessDeviceProfileReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CreateAccessDeviceProfileReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CreateAccessDeviceProfileReply}
 */
proto.api.CreateAccessDeviceProfileReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CreateAccessDeviceProfileReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CreateAccessDeviceProfileReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CreateAccessDeviceProfileReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateAccessDeviceProfileReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.CreateAccessDeviceProfileReply.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreateAccessDeviceProfileReply} returns this
 */
proto.api.CreateAccessDeviceProfileReply.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateAccessDeviceProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateAccessDeviceProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateAccessDeviceProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateAccessDeviceProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    profile: (f = msg.getProfile()) && proto.api.AccessDeviceProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateAccessDeviceProfileRequest}
 */
proto.api.UpdateAccessDeviceProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateAccessDeviceProfileRequest;
  return proto.api.UpdateAccessDeviceProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateAccessDeviceProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateAccessDeviceProfileRequest}
 */
proto.api.UpdateAccessDeviceProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.AccessDeviceProfile;
      reader.readMessage(value,proto.api.AccessDeviceProfile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateAccessDeviceProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateAccessDeviceProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateAccessDeviceProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateAccessDeviceProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.AccessDeviceProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional AccessDeviceProfile profile = 1;
 * @return {?proto.api.AccessDeviceProfile}
 */
proto.api.UpdateAccessDeviceProfileRequest.prototype.getProfile = function() {
  return /** @type{?proto.api.AccessDeviceProfile} */ (
    jspb.Message.getWrapperField(this, proto.api.AccessDeviceProfile, 1));
};


/**
 * @param {?proto.api.AccessDeviceProfile|undefined} value
 * @return {!proto.api.UpdateAccessDeviceProfileRequest} returns this
*/
proto.api.UpdateAccessDeviceProfileRequest.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateAccessDeviceProfileRequest} returns this
 */
proto.api.UpdateAccessDeviceProfileRequest.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateAccessDeviceProfileRequest.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateAccessDeviceProfileReply.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateAccessDeviceProfileReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateAccessDeviceProfileReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateAccessDeviceProfileReply.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateAccessDeviceProfileReply}
 */
proto.api.UpdateAccessDeviceProfileReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateAccessDeviceProfileReply;
  return proto.api.UpdateAccessDeviceProfileReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateAccessDeviceProfileReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateAccessDeviceProfileReply}
 */
proto.api.UpdateAccessDeviceProfileReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateAccessDeviceProfileReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateAccessDeviceProfileReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateAccessDeviceProfileReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateAccessDeviceProfileReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DeleteAccessDeviceProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DeleteAccessDeviceProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DeleteAccessDeviceProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteAccessDeviceProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DeleteAccessDeviceProfileRequest}
 */
proto.api.DeleteAccessDeviceProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DeleteAccessDeviceProfileRequest;
  return proto.api.DeleteAccessDeviceProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DeleteAccessDeviceProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DeleteAccessDeviceProfileRequest}
 */
proto.api.DeleteAccessDeviceProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DeleteAccessDeviceProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DeleteAccessDeviceProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DeleteAccessDeviceProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteAccessDeviceProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.DeleteAccessDeviceProfileRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.DeleteAccessDeviceProfileRequest} returns this
 */
proto.api.DeleteAccessDeviceProfileRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DeleteAccessDeviceProfileReply.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DeleteAccessDeviceProfileReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DeleteAccessDeviceProfileReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteAccessDeviceProfileReply.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DeleteAccessDeviceProfileReply}
 */
proto.api.DeleteAccessDeviceProfileReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DeleteAccessDeviceProfileReply;
  return proto.api.DeleteAccessDeviceProfileReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DeleteAccessDeviceProfileReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DeleteAccessDeviceProfileReply}
 */
proto.api.DeleteAccessDeviceProfileReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DeleteAccessDeviceProfileReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DeleteAccessDeviceProfileReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DeleteAccessDeviceProfileReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteAccessDeviceProfileReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetAccessDeviceProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetAccessDeviceProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetAccessDeviceProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetAccessDeviceProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetAccessDeviceProfileRequest}
 */
proto.api.GetAccessDeviceProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetAccessDeviceProfileRequest;
  return proto.api.GetAccessDeviceProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetAccessDeviceProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetAccessDeviceProfileRequest}
 */
proto.api.GetAccessDeviceProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetAccessDeviceProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetAccessDeviceProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetAccessDeviceProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetAccessDeviceProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.GetAccessDeviceProfileRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetAccessDeviceProfileRequest} returns this
 */
proto.api.GetAccessDeviceProfileRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetAccessDeviceProfileReply.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetAccessDeviceProfileReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetAccessDeviceProfileReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetAccessDeviceProfileReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    profile: (f = msg.getProfile()) && proto.api.AccessDeviceProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetAccessDeviceProfileReply}
 */
proto.api.GetAccessDeviceProfileReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetAccessDeviceProfileReply;
  return proto.api.GetAccessDeviceProfileReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetAccessDeviceProfileReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetAccessDeviceProfileReply}
 */
proto.api.GetAccessDeviceProfileReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.AccessDeviceProfile;
      reader.readMessage(value,proto.api.AccessDeviceProfile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetAccessDeviceProfileReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetAccessDeviceProfileReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetAccessDeviceProfileReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetAccessDeviceProfileReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.AccessDeviceProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional AccessDeviceProfile profile = 1;
 * @return {?proto.api.AccessDeviceProfile}
 */
proto.api.GetAccessDeviceProfileReply.prototype.getProfile = function() {
  return /** @type{?proto.api.AccessDeviceProfile} */ (
    jspb.Message.getWrapperField(this, proto.api.AccessDeviceProfile, 1));
};


/**
 * @param {?proto.api.AccessDeviceProfile|undefined} value
 * @return {!proto.api.GetAccessDeviceProfileReply} returns this
*/
proto.api.GetAccessDeviceProfileReply.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetAccessDeviceProfileReply} returns this
 */
proto.api.GetAccessDeviceProfileReply.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetAccessDeviceProfileReply.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.api);
