import mqtt from 'mqtt'

const topicMatch = (topic:string, wildcard:string) => {
  if (topic === wildcard) {
    return []
  } else if (wildcard === '#') {
    return [topic]
  }

  const res = []

  const t = String(topic).split('/')
  const w = String(wildcard).split('/')

  let i = 0
  for (let lt = t.length; i < lt; i++) {
    if (w[i] === '+') {
      res.push(t[i])
    } else if (w[i] === '#') {
      res.push(t.slice(i).join('/'))
      return res
    } else if (w[i] !== t[i]) {
      return null
    }
  }

  if (w[i] === '#') {
    i += 1
  }

  return (i === w.length) ? res : null
}

let MqttClient: mqtt.MqttClient

const subscribeTopics:Map<string, (topic:string, message:string) => void> = new Map()

export const InitMqttClient = () => {
  MqttClient = mqtt.connect('ws://127.0.0.1:9001/')

  MqttClient.on('connect', () => {
    console.log('MQTT Client connected')
  })

  MqttClient.on('message', (topic: any, message: any) => {
    subscribeTopics.forEach((cb, key) => {
      const match = topicMatch(topic, key)
      if (match) {
        cb(topic, message)
      }
    })
  })
}

export const AddSubscribe = (topic: string, callback: (topic:string, message:string) => void) => {
  return new Promise<void>((resolve, reject) => {
    const client = MqttClient as mqtt.MqttClient
    client.subscribeAsync([topic]).then(() => {
      subscribeTopics.set(topic, callback)
      resolve()
    }).catch((err) => {
      reject(err)
    })
  })
}

export const RemoveSubscribe = (topic: string) => {
  return new Promise<void>((resolve, reject) => {
    MqttClient.unsubscribeAsync(topic).then(() => {
      console.log(`dissubscribe: ${topic}`)
      subscribeTopics.delete(topic)
      resolve()
    }).catch(err => {
      reject(err)
    })
  })
}

export const Publish = (topic: string, message: string) => {
  return new Promise<void>((resolve, reject) => {
    MqttClient.publishAsync(topic, message).then(() => {
      console.log(`publush ${topic} ${message} Done`)
      resolve()
    }).catch(err => {
      reject(err)
    })
  })
}
