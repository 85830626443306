<template>
  <router-view @contextmenu.prevent="" />
</template>
<style lang="scss">
body {
  margin: 0;
  padding: 0;
  font-family: var(--el-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  font-family: var(--el-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--el-bg-color);
  color: #2c3e50;
  display: block;
  height: 100vh;
}

</style>
<script lang="ts" setup >
import { useDark, useToggle } from '@vueuse/core'
useDark()
</script>
