import { createStore } from 'vuex'
import session from './session'
import accessProtocol from './access.protocol'
import deviceProfile from './device.profile'
import accessDevice from './access.device'
import datapoint from './datapoint'
import mqttExplore from './mqtt.explore'
import integration from './integration'
import system from './system'
import gateway from './gateway'
import organization from './organization'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    session,
    accessProtocol,
    deviceProfile,
    accessDevice,
    datapoint,
    mqttExplore,
    integration,
    system,
    gateway,
    organization
  }
})
