import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import './styles/style.css'
import './styles/iot-box-icon.css'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { InitMqttClient } from '@/mqtt_client'

const app = createApp(App)

app.use(store).use(router).use(ElementPlus).mount('#app')

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

InitMqttClient()
