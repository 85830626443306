/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.19.1
// source: api/project.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.api = require('./project_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.ProjectServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.ProjectServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.CreateProjectRequest,
 *   !proto.api.CreateProjectReply>}
 */
const methodDescriptor_ProjectService_Create = new grpc.web.MethodDescriptor(
  '/api.ProjectService/Create',
  grpc.web.MethodType.UNARY,
  proto.api.CreateProjectRequest,
  proto.api.CreateProjectReply,
  /**
   * @param {!proto.api.CreateProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.CreateProjectReply.deserializeBinary
);


/**
 * @param {!proto.api.CreateProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.CreateProjectReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.CreateProjectReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.ProjectServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.ProjectService/Create',
      request,
      metadata || {},
      methodDescriptor_ProjectService_Create,
      callback);
};


/**
 * @param {!proto.api.CreateProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.CreateProjectReply>}
 *     Promise that resolves to the response
 */
proto.api.ProjectServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.ProjectService/Create',
      request,
      metadata || {},
      methodDescriptor_ProjectService_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.ListProjectRequest,
 *   !proto.api.ListProjectReply>}
 */
const methodDescriptor_ProjectService_List = new grpc.web.MethodDescriptor(
  '/api.ProjectService/List',
  grpc.web.MethodType.UNARY,
  proto.api.ListProjectRequest,
  proto.api.ListProjectReply,
  /**
   * @param {!proto.api.ListProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.ListProjectReply.deserializeBinary
);


/**
 * @param {!proto.api.ListProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.ListProjectReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.ListProjectReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.ProjectServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.ProjectService/List',
      request,
      metadata || {},
      methodDescriptor_ProjectService_List,
      callback);
};


/**
 * @param {!proto.api.ListProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.ListProjectReply>}
 *     Promise that resolves to the response
 */
proto.api.ProjectServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.ProjectService/List',
      request,
      metadata || {},
      methodDescriptor_ProjectService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.DeleteProjectRequest,
 *   !proto.api.DeleteProjectReply>}
 */
const methodDescriptor_ProjectService_Remove = new grpc.web.MethodDescriptor(
  '/api.ProjectService/Remove',
  grpc.web.MethodType.UNARY,
  proto.api.DeleteProjectRequest,
  proto.api.DeleteProjectReply,
  /**
   * @param {!proto.api.DeleteProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.DeleteProjectReply.deserializeBinary
);


/**
 * @param {!proto.api.DeleteProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.DeleteProjectReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.DeleteProjectReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.ProjectServiceClient.prototype.remove =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.ProjectService/Remove',
      request,
      metadata || {},
      methodDescriptor_ProjectService_Remove,
      callback);
};


/**
 * @param {!proto.api.DeleteProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.DeleteProjectReply>}
 *     Promise that resolves to the response
 */
proto.api.ProjectServicePromiseClient.prototype.remove =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.ProjectService/Remove',
      request,
      metadata || {},
      methodDescriptor_ProjectService_Remove);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.GetProjectRequest,
 *   !proto.api.GetProjectReply>}
 */
const methodDescriptor_ProjectService_Get = new grpc.web.MethodDescriptor(
  '/api.ProjectService/Get',
  grpc.web.MethodType.UNARY,
  proto.api.GetProjectRequest,
  proto.api.GetProjectReply,
  /**
   * @param {!proto.api.GetProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.GetProjectReply.deserializeBinary
);


/**
 * @param {!proto.api.GetProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.GetProjectReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.GetProjectReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.ProjectServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.ProjectService/Get',
      request,
      metadata || {},
      methodDescriptor_ProjectService_Get,
      callback);
};


/**
 * @param {!proto.api.GetProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.GetProjectReply>}
 *     Promise that resolves to the response
 */
proto.api.ProjectServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.ProjectService/Get',
      request,
      metadata || {},
      methodDescriptor_ProjectService_Get);
};


module.exports = proto.api;

