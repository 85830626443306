// source: api/system.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.api.IpIface', null, global);
goog.exportSymbol('proto.api.ListIpIfaceReply', null, global);
goog.exportSymbol('proto.api.ListIpIfaceRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.IpIface = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.IpIface, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.IpIface.displayName = 'proto.api.IpIface';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListIpIfaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListIpIfaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListIpIfaceRequest.displayName = 'proto.api.ListIpIfaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListIpIfaceReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ListIpIfaceReply.repeatedFields_, null);
};
goog.inherits(proto.api.ListIpIfaceReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListIpIfaceReply.displayName = 'proto.api.ListIpIfaceReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.IpIface.prototype.toObject = function(opt_includeInstance) {
  return proto.api.IpIface.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.IpIface} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IpIface.toObject = function(includeInstance, msg) {
  var f, obj = {
    ifname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ipaddr: jspb.Message.getFieldWithDefault(msg, 2, ""),
    netmask: jspb.Message.getFieldWithDefault(msg, 3, ""),
    defaultRoute: jspb.Message.getFieldWithDefault(msg, 4, ""),
    broadcastAddr: jspb.Message.getFieldWithDefault(msg, 5, ""),
    macaddr: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.IpIface}
 */
proto.api.IpIface.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.IpIface;
  return proto.api.IpIface.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.IpIface} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.IpIface}
 */
proto.api.IpIface.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIfname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpaddr(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetmask(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultRoute(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroadcastAddr(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMacaddr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.IpIface.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.IpIface.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.IpIface} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IpIface.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIfname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIpaddr();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNetmask();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDefaultRoute();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBroadcastAddr();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMacaddr();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string ifname = 1;
 * @return {string}
 */
proto.api.IpIface.prototype.getIfname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IpIface} returns this
 */
proto.api.IpIface.prototype.setIfname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ipaddr = 2;
 * @return {string}
 */
proto.api.IpIface.prototype.getIpaddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IpIface} returns this
 */
proto.api.IpIface.prototype.setIpaddr = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string netmask = 3;
 * @return {string}
 */
proto.api.IpIface.prototype.getNetmask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IpIface} returns this
 */
proto.api.IpIface.prototype.setNetmask = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string default_route = 4;
 * @return {string}
 */
proto.api.IpIface.prototype.getDefaultRoute = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IpIface} returns this
 */
proto.api.IpIface.prototype.setDefaultRoute = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string broadcast_addr = 5;
 * @return {string}
 */
proto.api.IpIface.prototype.getBroadcastAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IpIface} returns this
 */
proto.api.IpIface.prototype.setBroadcastAddr = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string macaddr = 6;
 * @return {string}
 */
proto.api.IpIface.prototype.getMacaddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IpIface} returns this
 */
proto.api.IpIface.prototype.setMacaddr = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListIpIfaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListIpIfaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListIpIfaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListIpIfaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListIpIfaceRequest}
 */
proto.api.ListIpIfaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListIpIfaceRequest;
  return proto.api.ListIpIfaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListIpIfaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListIpIfaceRequest}
 */
proto.api.ListIpIfaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListIpIfaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListIpIfaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListIpIfaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListIpIfaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ListIpIfaceReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListIpIfaceReply.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListIpIfaceReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListIpIfaceReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListIpIfaceReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultList: jspb.Message.toObjectList(msg.getResultList(),
    proto.api.IpIface.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListIpIfaceReply}
 */
proto.api.ListIpIfaceReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListIpIfaceReply;
  return proto.api.ListIpIfaceReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListIpIfaceReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListIpIfaceReply}
 */
proto.api.ListIpIfaceReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.IpIface;
      reader.readMessage(value,proto.api.IpIface.deserializeBinaryFromReader);
      msg.addResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListIpIfaceReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListIpIfaceReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListIpIfaceReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListIpIfaceReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.IpIface.serializeBinaryToWriter
    );
  }
};


/**
 * repeated IpIface result = 1;
 * @return {!Array<!proto.api.IpIface>}
 */
proto.api.ListIpIfaceReply.prototype.getResultList = function() {
  return /** @type{!Array<!proto.api.IpIface>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.IpIface, 1));
};


/**
 * @param {!Array<!proto.api.IpIface>} value
 * @return {!proto.api.ListIpIfaceReply} returns this
*/
proto.api.ListIpIfaceReply.prototype.setResultList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.IpIface=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.IpIface}
 */
proto.api.ListIpIfaceReply.prototype.addResult = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.IpIface, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ListIpIfaceReply} returns this
 */
proto.api.ListIpIfaceReply.prototype.clearResultList = function() {
  return this.setResultList([]);
};


goog.object.extend(exports, proto.api);
