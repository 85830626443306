/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.19.1
// source: api/gateway.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var common_common_pb = require('../common/common_pb.js')
const proto = {};
proto.api = require('./gateway_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.GatewayServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.GatewayServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.ListGatewayRequest,
 *   !proto.api.ListGatewayReply>}
 */
const methodDescriptor_GatewayService_List = new grpc.web.MethodDescriptor(
  '/api.GatewayService/List',
  grpc.web.MethodType.UNARY,
  proto.api.ListGatewayRequest,
  proto.api.ListGatewayReply,
  /**
   * @param {!proto.api.ListGatewayRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.ListGatewayReply.deserializeBinary
);


/**
 * @param {!proto.api.ListGatewayRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.ListGatewayReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.ListGatewayReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.GatewayServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.GatewayService/List',
      request,
      metadata || {},
      methodDescriptor_GatewayService_List,
      callback);
};


/**
 * @param {!proto.api.ListGatewayRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.ListGatewayReply>}
 *     Promise that resolves to the response
 */
proto.api.GatewayServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.GatewayService/List',
      request,
      metadata || {},
      methodDescriptor_GatewayService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.CreateGatewayRequest,
 *   !proto.api.CreateGatewayReply>}
 */
const methodDescriptor_GatewayService_Create = new grpc.web.MethodDescriptor(
  '/api.GatewayService/Create',
  grpc.web.MethodType.UNARY,
  proto.api.CreateGatewayRequest,
  proto.api.CreateGatewayReply,
  /**
   * @param {!proto.api.CreateGatewayRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.CreateGatewayReply.deserializeBinary
);


/**
 * @param {!proto.api.CreateGatewayRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.CreateGatewayReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.CreateGatewayReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.GatewayServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.GatewayService/Create',
      request,
      metadata || {},
      methodDescriptor_GatewayService_Create,
      callback);
};


/**
 * @param {!proto.api.CreateGatewayRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.CreateGatewayReply>}
 *     Promise that resolves to the response
 */
proto.api.GatewayServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.GatewayService/Create',
      request,
      metadata || {},
      methodDescriptor_GatewayService_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.DeleteGatewayRequest,
 *   !proto.api.DeleteGatewayReply>}
 */
const methodDescriptor_GatewayService_Delete = new grpc.web.MethodDescriptor(
  '/api.GatewayService/Delete',
  grpc.web.MethodType.UNARY,
  proto.api.DeleteGatewayRequest,
  proto.api.DeleteGatewayReply,
  /**
   * @param {!proto.api.DeleteGatewayRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.DeleteGatewayReply.deserializeBinary
);


/**
 * @param {!proto.api.DeleteGatewayRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.DeleteGatewayReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.DeleteGatewayReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.GatewayServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.GatewayService/Delete',
      request,
      metadata || {},
      methodDescriptor_GatewayService_Delete,
      callback);
};


/**
 * @param {!proto.api.DeleteGatewayRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.DeleteGatewayReply>}
 *     Promise that resolves to the response
 */
proto.api.GatewayServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.GatewayService/Delete',
      request,
      metadata || {},
      methodDescriptor_GatewayService_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.CheckInRequest,
 *   !proto.api.CheckInReply>}
 */
const methodDescriptor_GatewayService_checkIn = new grpc.web.MethodDescriptor(
  '/api.GatewayService/checkIn',
  grpc.web.MethodType.UNARY,
  proto.api.CheckInRequest,
  proto.api.CheckInReply,
  /**
   * @param {!proto.api.CheckInRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.CheckInReply.deserializeBinary
);


/**
 * @param {!proto.api.CheckInRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.CheckInReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.CheckInReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.GatewayServiceClient.prototype.checkIn =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.GatewayService/checkIn',
      request,
      metadata || {},
      methodDescriptor_GatewayService_checkIn,
      callback);
};


/**
 * @param {!proto.api.CheckInRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.CheckInReply>}
 *     Promise that resolves to the response
 */
proto.api.GatewayServicePromiseClient.prototype.checkIn =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.GatewayService/checkIn',
      request,
      metadata || {},
      methodDescriptor_GatewayService_checkIn);
};


module.exports = proto.api;

