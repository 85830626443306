import { OrganizationServicePromiseClient } from '@/api/organization_grpc_web_pb'
import { ListMembersReply, ListMembersRequest, ListOrganizationRequest, Organization as OrganizationGRPC, OrganizationMember } from '@/api/organization_pb'
import { GRPC_ENDPOINT } from '@/const'
import { Metadata } from './session'
import { Store } from 'vuex'
import { CreateProjectRequest, ListProjectRequest, Project } from '@/api/project_pb'
import { ProjectServicePromiseClient } from '@/api/project_grpc_web_pb'
import { ElMessage } from 'element-plus'

export type IOrganization = OrganizationGRPC.AsObject
export type IOrganizationMember = OrganizationMember.AsObject
export type IProject = Project.AsObject

export interface Organization extends IOrganization {
  members: IOrganizationMember[]
  projects: IProject[]
}

interface IState {
  list: Array<Organization>,
  projectId: string,
  organizationId: string
}

const state:IState = {
  list: new Array<Organization>(),
  projectId: localStorage.getItem('projectId') || '',
  organizationId: localStorage.getItem('organizationId') || ''
}

const actions = {}

const getters = {
  list: (state: IState) => () => {
    return state.list
  },
  listProject: (state: IState) => (id:string) => {
    return state.list.find(p => p.id === id)?.projects || []
  },
  get: (state: IState) => (id: string) => {
    return state.list.find(p => p.id === id)
  },

  getProjectId: (state: IState) => () => {
    return state.projectId
  },

  getOrganizationId: (state: IState) => () => {
    return state.organizationId
  }
}

const mutations = {
  fetch  (state: IState, organization: Organization[]) {
    state.list = organization
  },

  fetchProjects  (state: IState, { id, data }:{ id: string, data: IProject[]}) {
    const org = state.list.find(o => o.id === id)
    if (org) {
      org.projects = data
    }
  },

  fetchMembers  (state: IState, { id, data }:{ id: string, data: IOrganizationMember[]}) {
    const org = state.list.find(o => o.id === id)
    if (org) {
      org.members = data
    }
  },

  setProjectId (state: IState, id :string) {
    state.projectId = id
    localStorage.setItem('projectId', id)
  },

  setOrganizationId (state: IState, id: string) {
    state.organizationId = id
    localStorage.setItem('organizationId', id)
  },

  remove (state: IState, id: string) {
    console.log(`try to remove integration protocol: ${id}`)
    for (let i = 0; i < state.list.length; i++) {
      if (state.list[i].id === id) {
        state.list.splice(i, 1)
        break
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export class OrganizationStore {
  private store:Store<any>;
  private client;
  constructor (store:Store<any>) {
    this.store = store
    this.client = new OrganizationServicePromiseClient(GRPC_ENDPOINT)
  }

  Fetch = () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      const requst = new ListOrganizationRequest()
      this.client.list(requst, Metadata(this.store)).then(reply => {
        const organizations = reply.toObject().resultList
        this.store.commit('organization/fetch', organizations)
        for (const o of organizations) {
          this.LoadMembers(o.id).catch(err => {
            ElMessage.error(err.message)
          })
          this.LoadProjects(o.id).catch(err => {
            ElMessage.error(err.message)
          })
        }
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  }

  List = ():Organization[] => {
    return this.store.getters['organization/list']()
  }

  Get = (id: string) => {
    return this.store.getters['organization/get'](id)
  }

  ListProject = (id: string) => {
    return this.store.getters['organization/listProject'](id)
  }

  LoadProjects = async (id: string) : Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      const client = new ProjectServicePromiseClient(GRPC_ENDPOINT)
      const request = new ListProjectRequest()
      request.setOrganizationId(id)

      client.list(request, Metadata(this.store)).then(reply => {
        const result = reply.toObject().resultList
        this.store.commit('organization/fetchProjects', { id: id, data: result })

        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  }

  LoadMembers = async (oranizationId:string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      const client = new OrganizationServicePromiseClient(GRPC_ENDPOINT)
      const request = new ListMembersRequest()
      request.setOrganizationId(oranizationId)

      client.listMembers(request, Metadata(this.store)).then((reply:ListMembersReply) => {
        this.store.commit('organization/fetchMembers', { id: oranizationId, data: reply.toObject().resultsList })
        resolve()
      }).catch((err:any) => {
        reject(err)
      })
    })
  }

  CreateProject = async (organizationId: string, params: IProject) : Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const client = new ProjectServicePromiseClient(GRPC_ENDPOINT)
      const request = new CreateProjectRequest()
      const obj = new Project()
      obj.setName(params.name)
      obj.setContact(params.contact)
      obj.setEmail(params.email)
      obj.setPhone(params.phone)
      obj.setLocation(params.location)
      obj.setNote(params.note)
      obj.setOrganizationId(organizationId)

      request.setParams(obj)

      client.create(request, Metadata(this.store)).then(reply => {
        resolve(reply.getId())
      }).catch(err => {
        reject(err)
      })
    })
  }

  LaunchProject = (projectId: string, organizationId: string) => {
    this.store.commit('organization/setProjectId', projectId)
    this.store.commit('organization/setOrganizationId', organizationId)
  }
}

export const GetProjectId = (store: Store<any>) => {
  return store.getters['organization/getProjectId']()
}

export const getOrganizationId = (store: Store<any>) => {
  return store.getters['organization/getOrganizationId']()
}
