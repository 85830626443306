/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.19.1
// source: api/integration.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var common_common_pb = require('../common/common_pb.js')

var api_access_device_pb = require('../api/access_device_pb.js')
const proto = {};
proto.api = require('./integration_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.IntegrationServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.IntegrationServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.ListIntegrationProtocolsRequest,
 *   !proto.api.ListIntegrationProtocolsReply>}
 */
const methodDescriptor_IntegrationService_List = new grpc.web.MethodDescriptor(
  '/api.IntegrationService/List',
  grpc.web.MethodType.UNARY,
  proto.api.ListIntegrationProtocolsRequest,
  proto.api.ListIntegrationProtocolsReply,
  /**
   * @param {!proto.api.ListIntegrationProtocolsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.ListIntegrationProtocolsReply.deserializeBinary
);


/**
 * @param {!proto.api.ListIntegrationProtocolsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.ListIntegrationProtocolsReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.ListIntegrationProtocolsReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.IntegrationServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.IntegrationService/List',
      request,
      metadata || {},
      methodDescriptor_IntegrationService_List,
      callback);
};


/**
 * @param {!proto.api.ListIntegrationProtocolsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.ListIntegrationProtocolsReply>}
 *     Promise that resolves to the response
 */
proto.api.IntegrationServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.IntegrationService/List',
      request,
      metadata || {},
      methodDescriptor_IntegrationService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.CreateIntegrationRequest,
 *   !proto.api.CreateIntegrationReply>}
 */
const methodDescriptor_IntegrationService_Create = new grpc.web.MethodDescriptor(
  '/api.IntegrationService/Create',
  grpc.web.MethodType.UNARY,
  proto.api.CreateIntegrationRequest,
  proto.api.CreateIntegrationReply,
  /**
   * @param {!proto.api.CreateIntegrationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.CreateIntegrationReply.deserializeBinary
);


/**
 * @param {!proto.api.CreateIntegrationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.CreateIntegrationReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.CreateIntegrationReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.IntegrationServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.IntegrationService/Create',
      request,
      metadata || {},
      methodDescriptor_IntegrationService_Create,
      callback);
};


/**
 * @param {!proto.api.CreateIntegrationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.CreateIntegrationReply>}
 *     Promise that resolves to the response
 */
proto.api.IntegrationServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.IntegrationService/Create',
      request,
      metadata || {},
      methodDescriptor_IntegrationService_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.AssignDeviceToBacnetIpRequest,
 *   !proto.api.AssignDeviceToBacnetIpReply>}
 */
const methodDescriptor_IntegrationService_AssignDeviceToBacnetIp = new grpc.web.MethodDescriptor(
  '/api.IntegrationService/AssignDeviceToBacnetIp',
  grpc.web.MethodType.UNARY,
  proto.api.AssignDeviceToBacnetIpRequest,
  proto.api.AssignDeviceToBacnetIpReply,
  /**
   * @param {!proto.api.AssignDeviceToBacnetIpRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.AssignDeviceToBacnetIpReply.deserializeBinary
);


/**
 * @param {!proto.api.AssignDeviceToBacnetIpRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.AssignDeviceToBacnetIpReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.AssignDeviceToBacnetIpReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.IntegrationServiceClient.prototype.assignDeviceToBacnetIp =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.IntegrationService/AssignDeviceToBacnetIp',
      request,
      metadata || {},
      methodDescriptor_IntegrationService_AssignDeviceToBacnetIp,
      callback);
};


/**
 * @param {!proto.api.AssignDeviceToBacnetIpRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.AssignDeviceToBacnetIpReply>}
 *     Promise that resolves to the response
 */
proto.api.IntegrationServicePromiseClient.prototype.assignDeviceToBacnetIp =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.IntegrationService/AssignDeviceToBacnetIp',
      request,
      metadata || {},
      methodDescriptor_IntegrationService_AssignDeviceToBacnetIp);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.ListBacnetIpAssignedDevicesRequest,
 *   !proto.api.ListBacnetIpAssignedDevicesReply>}
 */
const methodDescriptor_IntegrationService_ListBacnetIpAssignedDevices = new grpc.web.MethodDescriptor(
  '/api.IntegrationService/ListBacnetIpAssignedDevices',
  grpc.web.MethodType.UNARY,
  proto.api.ListBacnetIpAssignedDevicesRequest,
  proto.api.ListBacnetIpAssignedDevicesReply,
  /**
   * @param {!proto.api.ListBacnetIpAssignedDevicesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.ListBacnetIpAssignedDevicesReply.deserializeBinary
);


/**
 * @param {!proto.api.ListBacnetIpAssignedDevicesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.ListBacnetIpAssignedDevicesReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.ListBacnetIpAssignedDevicesReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.IntegrationServiceClient.prototype.listBacnetIpAssignedDevices =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.IntegrationService/ListBacnetIpAssignedDevices',
      request,
      metadata || {},
      methodDescriptor_IntegrationService_ListBacnetIpAssignedDevices,
      callback);
};


/**
 * @param {!proto.api.ListBacnetIpAssignedDevicesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.ListBacnetIpAssignedDevicesReply>}
 *     Promise that resolves to the response
 */
proto.api.IntegrationServicePromiseClient.prototype.listBacnetIpAssignedDevices =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.IntegrationService/ListBacnetIpAssignedDevices',
      request,
      metadata || {},
      methodDescriptor_IntegrationService_ListBacnetIpAssignedDevices);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.RemoveIntegraionRequest,
 *   !proto.api.RemoveIntegrationReply>}
 */
const methodDescriptor_IntegrationService_Remove = new grpc.web.MethodDescriptor(
  '/api.IntegrationService/Remove',
  grpc.web.MethodType.UNARY,
  proto.api.RemoveIntegraionRequest,
  proto.api.RemoveIntegrationReply,
  /**
   * @param {!proto.api.RemoveIntegraionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.RemoveIntegrationReply.deserializeBinary
);


/**
 * @param {!proto.api.RemoveIntegraionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.RemoveIntegrationReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.RemoveIntegrationReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.IntegrationServiceClient.prototype.remove =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.IntegrationService/Remove',
      request,
      metadata || {},
      methodDescriptor_IntegrationService_Remove,
      callback);
};


/**
 * @param {!proto.api.RemoveIntegraionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.RemoveIntegrationReply>}
 *     Promise that resolves to the response
 */
proto.api.IntegrationServicePromiseClient.prototype.remove =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.IntegrationService/Remove',
      request,
      metadata || {},
      methodDescriptor_IntegrationService_Remove);
};


module.exports = proto.api;

