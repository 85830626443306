import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import LoginView from '../views/LoginView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  }, {
    path: '/app',
    name: 'Main',
    component: () => import('../views/MainView.vue'),
    children: [{
      path: 'account',
      name: 'Account',
      component: () => import('../views/Account/Main.vue'),
      children: [{
        path: 'organization',
        name: 'Organization',
        component: () => import('../views/Account/Organization/List.vue')
      }, {
        path: 'organization/:id',
        name: 'OrganizationOverview',
        component: () => import('../views/Account/Organization/Overview.vue'),
        children: [{
          path: 'projects',
          name: 'OrganizationProjects',
          component: () => import('../views/Account/Organization/Projects.vue')
        }, {
          path: 'members',
          name: 'OrganizationMembers',
          component: () => import('../views/Account/Organization/Members.vue')
        }, {
          path: 'setting',
          name: 'OrganizationSetting',
          component: () => import('../views/Account/Organization/Setting.vue')
        }, {
          path: 'subscription',
          name: 'OrganizationSubscription',
          component: () => import('../views/Account/Organization/Subscription.vue')
        }]
      }]
    },
    {
      path: 'project',
      name: 'ProjectMain',
      component: () => import('../views/Project/Main.vue'),
      children: [{
        path: 'access_protocol',
        name: 'AccessProtocolList',
        component: () => import('../views/Project/AccessProtocol/List.vue')
      }, {
        path: 'access_protocol/create',
        name: 'CreateAccessProtocol',
        component: () => import('../views/Project/AccessProtocol/Create.vue')
      }, {
        path: 'access_protocol/:id',
        name: 'AccesProtocolOverview',
        component: () => import('../views/Project/AccessProtocol/Overview.vue'),
        children: [{
          path: 'device',
          name: 'AccessProtocolDeviceList',
          component: () => import('../views/Project/AccessProtocol/DeviceList.vue')
        }, {
          path: 'configuration',
          name: 'AccessProtocolConfiguration',
          component: () => import('../views/Project/AccessProtocol/Configuration.vue')
        }, {
          path: 'explorer',
          name: 'AccessProtocolExplorer',
          component: () => import('../views/Project/AccessProtocol/Explore.vue')
        }]
      }, {
        path: 'access_device',
        name: 'AccessDeviceList',
        component: () => import('../views/Project/AccessDevice/List.vue')
      }, {
        path: 'access_device/:id',
        name: 'AccessDeviceDetails',
        component: () => import('../views/Project/AccessDevice/Overview.vue'),
        children: [{
          path: 'dashboard',
          name: 'AccessDeviceDashboard',
          component: () => import('../views/Project/AccessDevice/Dashboard.vue')
        }, {
          path: 'history',
          name: 'AccessDeviceHistory',
          component: () => import('../views/Project/AccessDevice/History.vue')
        }, {
          path: 'downlinks',
          name: 'AccessDeviceDownlinks',
          component: () => import('../views/Project/AccessDevice/Downlink.vue')
        }, {
          path: 'configuration',
          name: 'AccessDeviceConfiguration',
          component: () => import('../views/Project/AccessDevice/Configuration.vue')
        }, {
          path: 'live_data',
          name: 'AccessDeviceLivedata',
          component: () => import('../views/Project/AccessDevice/Dashboard.vue')
        }]
      }, {
        path: 'access_device_profile',
        name: 'AccessDeviceProfileList',
        component: () => import('../views/Project/AccessDeviceProfile/List.vue')
      }, {
        path: 'access_device_profile/create',
        name: 'CreateAccessDeviceProfile',
        component: () => import('../views/Project/AccessDeviceProfile/Create.vue')
      }, {
        path: 'data_point',
        name: 'AccessDataPointList',
        component: () => import('../views/Project/DataPoints/List.vue')
      }, {
        path: 'data_point/:id',
        name: 'DataPoint',
        component: () => import('../views/Project/DataPoints/Overview.vue'),
        children: [{
          path: 'history',
          name: 'DataPointHistory',
          component: () => import('../views/Project/DataPoints/History.vue')
        }, {
          path: 'configuration',
          name: 'DataPointConfiguration',
          component: () => import('../views/Project/DataPoints/Configuration.vue')
        }, {
          path: 'downlink',
          name: 'DataPointDownlink',
          component: () => import('../views/Project/DataPoints/Downlink.vue')
        }]
      }, {
        path: 'gateway',
        name: 'GatewayList',
        component: () => import('../views/Project/Gateway/List.vue')

      }, {
        path: 'gateway/:id',
        name: 'GatewayOverview',
        component: () => import('../views/Project/Gateway/Overview.vue'),
        children: [{
          path: 'configuration',
          name: 'GatewayConfiguration',
          component: () => import('../views/Project/Gateway/Configuration.vue')
        }, {
          path: 'system',
          name: 'GatewaySystem',
          component: () => import('../views/Project/Gateway/System.vue')
        }]
      },
      {
        path: 'automation_view',
        name: 'AutomationView',
        component: () => import('../views/Project/Automation/AutomationViewListView.vue')
      }, {
        path: 'automation_components',
        name: 'AutomationComponents',
        component: () => import('../views/Project/Automation/AutomationComponentsView.vue')
      }, {
        path: 'visualization_view',
        name: 'VisualizationView',
        component: () => import('../views/Project/Visual/VisualView.vue')
      }, {
        path: 'visualization_components',
        name: 'VisualizationComponents',
        component: () => import('../views/Project/Visual/VisualComponents.vue')
      }, {
        path: 'integration_protocol',
        name: 'IntegrationProtocolsList',
        component: () => import('../views/Project/Integration/List.vue')
      }, {
        path: 'integration_protocol/create',
        name: 'CreateIntegrationProtocol',
        component: () => import('../views/Project/Integration/Create.vue')
      }, {
        path: 'integration_protocol/:id/assign_device',
        name: 'AssignDeviceToIntegrationProtocol',
        component: () => import('../views/Project/Integration/Assign.vue')
      }, {
        path: 'integration_protocol/:id',
        name: 'IntegrationProtocolOverview',
        component: () => import('../views/Project/Integration/Overview.vue'),
        children: [{
          path: 'explorer',
          name: 'IntegrationProtocolExplorer',
          component: () => import('../views/Project/Integration/Browser.vue')
        }, {
          path: 'configuration',
          name: 'IntegrationProtocolConfiguration',
          component: () => import('../views/Project/Integration/Configuration.vue')
        }]
      }]
    }, {
      path: 'create',
      name: 'CreateProject',
      component: () => import('../views/Project/Create.vue')
    }]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
