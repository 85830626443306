// source: api/integration.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var common_common_pb = require('../common/common_pb.js');
goog.object.extend(proto, common_common_pb);
var api_access_device_pb = require('../api/access_device_pb.js');
goog.object.extend(proto, api_access_device_pb);
goog.exportSymbol('proto.api.AssignDeviceToBacnetEntry', null, global);
goog.exportSymbol('proto.api.AssignDeviceToBacnetIpReply', null, global);
goog.exportSymbol('proto.api.AssignDeviceToBacnetIpRequest', null, global);
goog.exportSymbol('proto.api.BacnetIpAssignedDevice', null, global);
goog.exportSymbol('proto.api.BacnetIpDataPoint', null, global);
goog.exportSymbol('proto.api.CreateIntegrationReply', null, global);
goog.exportSymbol('proto.api.CreateIntegrationRequest', null, global);
goog.exportSymbol('proto.api.IntegrationBACnetIPProperties', null, global);
goog.exportSymbol('proto.api.IntegrationProtocol', null, global);
goog.exportSymbol('proto.api.IntegrationProtocol.PropertiesCase', null, global);
goog.exportSymbol('proto.api.ListBacnetIpAssignedDevicesReply', null, global);
goog.exportSymbol('proto.api.ListBacnetIpAssignedDevicesRequest', null, global);
goog.exportSymbol('proto.api.ListIntegrationProtocolsReply', null, global);
goog.exportSymbol('proto.api.ListIntegrationProtocolsRequest', null, global);
goog.exportSymbol('proto.api.RemoveIntegraionRequest', null, global);
goog.exportSymbol('proto.api.RemoveIntegrationReply', null, global);
goog.exportSymbol('proto.api.Value', null, global);
goog.exportSymbol('proto.api.Value.ValueCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AssignDeviceToBacnetEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AssignDeviceToBacnetEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AssignDeviceToBacnetEntry.displayName = 'proto.api.AssignDeviceToBacnetEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AssignDeviceToBacnetIpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.AssignDeviceToBacnetIpRequest.repeatedFields_, null);
};
goog.inherits(proto.api.AssignDeviceToBacnetIpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AssignDeviceToBacnetIpRequest.displayName = 'proto.api.AssignDeviceToBacnetIpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AssignDeviceToBacnetIpReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.AssignDeviceToBacnetIpReply.repeatedFields_, null);
};
goog.inherits(proto.api.AssignDeviceToBacnetIpReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AssignDeviceToBacnetIpReply.displayName = 'proto.api.AssignDeviceToBacnetIpReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.IntegrationBACnetIPProperties = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.IntegrationBACnetIPProperties, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.IntegrationBACnetIPProperties.displayName = 'proto.api.IntegrationBACnetIPProperties';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.IntegrationProtocol = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.IntegrationProtocol.oneofGroups_);
};
goog.inherits(proto.api.IntegrationProtocol, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.IntegrationProtocol.displayName = 'proto.api.IntegrationProtocol';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CreateIntegrationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CreateIntegrationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CreateIntegrationRequest.displayName = 'proto.api.CreateIntegrationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CreateIntegrationReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CreateIntegrationReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CreateIntegrationReply.displayName = 'proto.api.CreateIntegrationReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListIntegrationProtocolsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListIntegrationProtocolsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListIntegrationProtocolsRequest.displayName = 'proto.api.ListIntegrationProtocolsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListIntegrationProtocolsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ListIntegrationProtocolsReply.repeatedFields_, null);
};
goog.inherits(proto.api.ListIntegrationProtocolsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListIntegrationProtocolsReply.displayName = 'proto.api.ListIntegrationProtocolsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListBacnetIpAssignedDevicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListBacnetIpAssignedDevicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListBacnetIpAssignedDevicesRequest.displayName = 'proto.api.ListBacnetIpAssignedDevicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Value = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.Value.oneofGroups_);
};
goog.inherits(proto.api.Value, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Value.displayName = 'proto.api.Value';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.BacnetIpDataPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.BacnetIpDataPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.BacnetIpDataPoint.displayName = 'proto.api.BacnetIpDataPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.BacnetIpAssignedDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.BacnetIpAssignedDevice.repeatedFields_, null);
};
goog.inherits(proto.api.BacnetIpAssignedDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.BacnetIpAssignedDevice.displayName = 'proto.api.BacnetIpAssignedDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListBacnetIpAssignedDevicesReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ListBacnetIpAssignedDevicesReply.repeatedFields_, null);
};
goog.inherits(proto.api.ListBacnetIpAssignedDevicesReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListBacnetIpAssignedDevicesReply.displayName = 'proto.api.ListBacnetIpAssignedDevicesReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.RemoveIntegraionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.RemoveIntegraionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.RemoveIntegraionRequest.displayName = 'proto.api.RemoveIntegraionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.RemoveIntegrationReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.RemoveIntegrationReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.RemoveIntegrationReply.displayName = 'proto.api.RemoveIntegrationReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AssignDeviceToBacnetEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AssignDeviceToBacnetEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AssignDeviceToBacnetEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AssignDeviceToBacnetEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessDeviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bacnetDeviceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bacnetDeviceName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AssignDeviceToBacnetEntry}
 */
proto.api.AssignDeviceToBacnetEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AssignDeviceToBacnetEntry;
  return proto.api.AssignDeviceToBacnetEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AssignDeviceToBacnetEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AssignDeviceToBacnetEntry}
 */
proto.api.AssignDeviceToBacnetEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessDeviceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBacnetDeviceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBacnetDeviceName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AssignDeviceToBacnetEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AssignDeviceToBacnetEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AssignDeviceToBacnetEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AssignDeviceToBacnetEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBacnetDeviceId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBacnetDeviceName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string access_device_id = 1;
 * @return {string}
 */
proto.api.AssignDeviceToBacnetEntry.prototype.getAccessDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AssignDeviceToBacnetEntry} returns this
 */
proto.api.AssignDeviceToBacnetEntry.prototype.setAccessDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 bacnet_device_id = 2;
 * @return {number}
 */
proto.api.AssignDeviceToBacnetEntry.prototype.getBacnetDeviceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.AssignDeviceToBacnetEntry} returns this
 */
proto.api.AssignDeviceToBacnetEntry.prototype.setBacnetDeviceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string bacnet_device_name = 3;
 * @return {string}
 */
proto.api.AssignDeviceToBacnetEntry.prototype.getBacnetDeviceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AssignDeviceToBacnetEntry} returns this
 */
proto.api.AssignDeviceToBacnetEntry.prototype.setBacnetDeviceName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.AssignDeviceToBacnetIpRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AssignDeviceToBacnetIpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AssignDeviceToBacnetIpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AssignDeviceToBacnetIpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AssignDeviceToBacnetIpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    integrationProtocolId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto.api.AssignDeviceToBacnetEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AssignDeviceToBacnetIpRequest}
 */
proto.api.AssignDeviceToBacnetIpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AssignDeviceToBacnetIpRequest;
  return proto.api.AssignDeviceToBacnetIpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AssignDeviceToBacnetIpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AssignDeviceToBacnetIpRequest}
 */
proto.api.AssignDeviceToBacnetIpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntegrationProtocolId(value);
      break;
    case 2:
      var value = new proto.api.AssignDeviceToBacnetEntry;
      reader.readMessage(value,proto.api.AssignDeviceToBacnetEntry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AssignDeviceToBacnetIpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AssignDeviceToBacnetIpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AssignDeviceToBacnetIpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AssignDeviceToBacnetIpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIntegrationProtocolId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.api.AssignDeviceToBacnetEntry.serializeBinaryToWriter
    );
  }
};


/**
 * optional string integration_protocol_id = 1;
 * @return {string}
 */
proto.api.AssignDeviceToBacnetIpRequest.prototype.getIntegrationProtocolId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AssignDeviceToBacnetIpRequest} returns this
 */
proto.api.AssignDeviceToBacnetIpRequest.prototype.setIntegrationProtocolId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated AssignDeviceToBacnetEntry entries = 2;
 * @return {!Array<!proto.api.AssignDeviceToBacnetEntry>}
 */
proto.api.AssignDeviceToBacnetIpRequest.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.api.AssignDeviceToBacnetEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.AssignDeviceToBacnetEntry, 2));
};


/**
 * @param {!Array<!proto.api.AssignDeviceToBacnetEntry>} value
 * @return {!proto.api.AssignDeviceToBacnetIpRequest} returns this
*/
proto.api.AssignDeviceToBacnetIpRequest.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.api.AssignDeviceToBacnetEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.AssignDeviceToBacnetEntry}
 */
proto.api.AssignDeviceToBacnetIpRequest.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.api.AssignDeviceToBacnetEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.AssignDeviceToBacnetIpRequest} returns this
 */
proto.api.AssignDeviceToBacnetIpRequest.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.AssignDeviceToBacnetIpReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AssignDeviceToBacnetIpReply.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AssignDeviceToBacnetIpReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AssignDeviceToBacnetIpReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AssignDeviceToBacnetIpReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AssignDeviceToBacnetIpReply}
 */
proto.api.AssignDeviceToBacnetIpReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AssignDeviceToBacnetIpReply;
  return proto.api.AssignDeviceToBacnetIpReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AssignDeviceToBacnetIpReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AssignDeviceToBacnetIpReply}
 */
proto.api.AssignDeviceToBacnetIpReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AssignDeviceToBacnetIpReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AssignDeviceToBacnetIpReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AssignDeviceToBacnetIpReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AssignDeviceToBacnetIpReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string result = 1;
 * @return {!Array<string>}
 */
proto.api.AssignDeviceToBacnetIpReply.prototype.getResultList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.api.AssignDeviceToBacnetIpReply} returns this
 */
proto.api.AssignDeviceToBacnetIpReply.prototype.setResultList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.api.AssignDeviceToBacnetIpReply} returns this
 */
proto.api.AssignDeviceToBacnetIpReply.prototype.addResult = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.AssignDeviceToBacnetIpReply} returns this
 */
proto.api.AssignDeviceToBacnetIpReply.prototype.clearResultList = function() {
  return this.setResultList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.IntegrationBACnetIPProperties.prototype.toObject = function(opt_includeInstance) {
  return proto.api.IntegrationBACnetIPProperties.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.IntegrationBACnetIPProperties} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IntegrationBACnetIPProperties.toObject = function(includeInstance, msg) {
  var f, obj = {
    iface: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ipPort: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bbmdAddress: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bbmdPort: jspb.Message.getFieldWithDefault(msg, 4, 0),
    apduTimeout: jspb.Message.getFieldWithDefault(msg, 6, 0),
    apduRetry: jspb.Message.getFieldWithDefault(msg, 7, 0),
    natAddress: jspb.Message.getFieldWithDefault(msg, 8, ""),
    broadcastBindAddr: jspb.Message.getFieldWithDefault(msg, 9, ""),
    baseBacnetDeviceId: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.IntegrationBACnetIPProperties}
 */
proto.api.IntegrationBACnetIPProperties.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.IntegrationBACnetIPProperties;
  return proto.api.IntegrationBACnetIPProperties.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.IntegrationBACnetIPProperties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.IntegrationBACnetIPProperties}
 */
proto.api.IntegrationBACnetIPProperties.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIface(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIpPort(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBbmdAddress(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBbmdPort(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setApduTimeout(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setApduRetry(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNatAddress(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroadcastBindAddr(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBaseBacnetDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.IntegrationBACnetIPProperties.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.IntegrationBACnetIPProperties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.IntegrationBACnetIPProperties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IntegrationBACnetIPProperties.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIface();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIpPort();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBbmdAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBbmdPort();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getApduTimeout();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getApduRetry();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getNatAddress();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBroadcastBindAddr();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBaseBacnetDeviceId();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
};


/**
 * optional string iface = 1;
 * @return {string}
 */
proto.api.IntegrationBACnetIPProperties.prototype.getIface = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IntegrationBACnetIPProperties} returns this
 */
proto.api.IntegrationBACnetIPProperties.prototype.setIface = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 ip_port = 2;
 * @return {number}
 */
proto.api.IntegrationBACnetIPProperties.prototype.getIpPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.IntegrationBACnetIPProperties} returns this
 */
proto.api.IntegrationBACnetIPProperties.prototype.setIpPort = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string bbmd_address = 3;
 * @return {string}
 */
proto.api.IntegrationBACnetIPProperties.prototype.getBbmdAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IntegrationBACnetIPProperties} returns this
 */
proto.api.IntegrationBACnetIPProperties.prototype.setBbmdAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 bbmd_port = 4;
 * @return {number}
 */
proto.api.IntegrationBACnetIPProperties.prototype.getBbmdPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.IntegrationBACnetIPProperties} returns this
 */
proto.api.IntegrationBACnetIPProperties.prototype.setBbmdPort = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 apdu_timeout = 6;
 * @return {number}
 */
proto.api.IntegrationBACnetIPProperties.prototype.getApduTimeout = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.IntegrationBACnetIPProperties} returns this
 */
proto.api.IntegrationBACnetIPProperties.prototype.setApduTimeout = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 apdu_retry = 7;
 * @return {number}
 */
proto.api.IntegrationBACnetIPProperties.prototype.getApduRetry = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.IntegrationBACnetIPProperties} returns this
 */
proto.api.IntegrationBACnetIPProperties.prototype.setApduRetry = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string nat_address = 8;
 * @return {string}
 */
proto.api.IntegrationBACnetIPProperties.prototype.getNatAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IntegrationBACnetIPProperties} returns this
 */
proto.api.IntegrationBACnetIPProperties.prototype.setNatAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string broadcast_bind_addr = 9;
 * @return {string}
 */
proto.api.IntegrationBACnetIPProperties.prototype.getBroadcastBindAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IntegrationBACnetIPProperties} returns this
 */
proto.api.IntegrationBACnetIPProperties.prototype.setBroadcastBindAddr = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional uint32 base_bacnet_device_id = 10;
 * @return {number}
 */
proto.api.IntegrationBACnetIPProperties.prototype.getBaseBacnetDeviceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.IntegrationBACnetIPProperties} returns this
 */
proto.api.IntegrationBACnetIPProperties.prototype.setBaseBacnetDeviceId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.IntegrationProtocol.oneofGroups_ = [[4]];

/**
 * @enum {number}
 */
proto.api.IntegrationProtocol.PropertiesCase = {
  PROPERTIES_NOT_SET: 0,
  BACNET_IP: 4
};

/**
 * @return {proto.api.IntegrationProtocol.PropertiesCase}
 */
proto.api.IntegrationProtocol.prototype.getPropertiesCase = function() {
  return /** @type {proto.api.IntegrationProtocol.PropertiesCase} */(jspb.Message.computeOneofCase(this, proto.api.IntegrationProtocol.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.IntegrationProtocol.prototype.toObject = function(opt_includeInstance) {
  return proto.api.IntegrationProtocol.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.IntegrationProtocol} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IntegrationProtocol.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    category: jspb.Message.getFieldWithDefault(msg, 3, 0),
    bacnetIp: (f = msg.getBacnetIp()) && proto.api.IntegrationBACnetIPProperties.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    note: jspb.Message.getFieldWithDefault(msg, 6, ""),
    location: jspb.Message.getFieldWithDefault(msg, 7, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    gatewayId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.IntegrationProtocol}
 */
proto.api.IntegrationProtocol.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.IntegrationProtocol;
  return proto.api.IntegrationProtocol.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.IntegrationProtocol} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.IntegrationProtocol}
 */
proto.api.IntegrationProtocol.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.common.CategoryMap} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 4:
      var value = new proto.api.IntegrationBACnetIPProperties;
      reader.readMessage(value,proto.api.IntegrationBACnetIPProperties.deserializeBinaryFromReader);
      msg.setBacnetIp(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setGatewayId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.IntegrationProtocol.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.IntegrationProtocol.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.IntegrationProtocol} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IntegrationProtocol.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getBacnetIp();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.api.IntegrationBACnetIPProperties.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getGatewayId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.IntegrationProtocol.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IntegrationProtocol} returns this
 */
proto.api.IntegrationProtocol.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.IntegrationProtocol.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IntegrationProtocol} returns this
 */
proto.api.IntegrationProtocol.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional common.CategoryMap category = 3;
 * @return {!proto.common.CategoryMap}
 */
proto.api.IntegrationProtocol.prototype.getCategory = function() {
  return /** @type {!proto.common.CategoryMap} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.CategoryMap} value
 * @return {!proto.api.IntegrationProtocol} returns this
 */
proto.api.IntegrationProtocol.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional IntegrationBACnetIPProperties bacnet_ip = 4;
 * @return {?proto.api.IntegrationBACnetIPProperties}
 */
proto.api.IntegrationProtocol.prototype.getBacnetIp = function() {
  return /** @type{?proto.api.IntegrationBACnetIPProperties} */ (
    jspb.Message.getWrapperField(this, proto.api.IntegrationBACnetIPProperties, 4));
};


/**
 * @param {?proto.api.IntegrationBACnetIPProperties|undefined} value
 * @return {!proto.api.IntegrationProtocol} returns this
*/
proto.api.IntegrationProtocol.prototype.setBacnetIp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.api.IntegrationProtocol.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.IntegrationProtocol} returns this
 */
proto.api.IntegrationProtocol.prototype.clearBacnetIp = function() {
  return this.setBacnetIp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.IntegrationProtocol.prototype.hasBacnetIp = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.IntegrationProtocol.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.IntegrationProtocol} returns this
*/
proto.api.IntegrationProtocol.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.IntegrationProtocol} returns this
 */
proto.api.IntegrationProtocol.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.IntegrationProtocol.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string note = 6;
 * @return {string}
 */
proto.api.IntegrationProtocol.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IntegrationProtocol} returns this
 */
proto.api.IntegrationProtocol.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string location = 7;
 * @return {string}
 */
proto.api.IntegrationProtocol.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IntegrationProtocol} returns this
 */
proto.api.IntegrationProtocol.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string project_id = 8;
 * @return {string}
 */
proto.api.IntegrationProtocol.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IntegrationProtocol} returns this
 */
proto.api.IntegrationProtocol.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string gateway_id = 9;
 * @return {string}
 */
proto.api.IntegrationProtocol.prototype.getGatewayId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IntegrationProtocol} returns this
 */
proto.api.IntegrationProtocol.prototype.setGatewayId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CreateIntegrationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CreateIntegrationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CreateIntegrationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateIntegrationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    params: (f = msg.getParams()) && proto.api.IntegrationProtocol.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CreateIntegrationRequest}
 */
proto.api.CreateIntegrationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CreateIntegrationRequest;
  return proto.api.CreateIntegrationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CreateIntegrationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CreateIntegrationRequest}
 */
proto.api.CreateIntegrationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.IntegrationProtocol;
      reader.readMessage(value,proto.api.IntegrationProtocol.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CreateIntegrationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CreateIntegrationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CreateIntegrationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateIntegrationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.IntegrationProtocol.serializeBinaryToWriter
    );
  }
};


/**
 * optional IntegrationProtocol params = 1;
 * @return {?proto.api.IntegrationProtocol}
 */
proto.api.CreateIntegrationRequest.prototype.getParams = function() {
  return /** @type{?proto.api.IntegrationProtocol} */ (
    jspb.Message.getWrapperField(this, proto.api.IntegrationProtocol, 1));
};


/**
 * @param {?proto.api.IntegrationProtocol|undefined} value
 * @return {!proto.api.CreateIntegrationRequest} returns this
*/
proto.api.CreateIntegrationRequest.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CreateIntegrationRequest} returns this
 */
proto.api.CreateIntegrationRequest.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CreateIntegrationRequest.prototype.hasParams = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CreateIntegrationReply.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CreateIntegrationReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CreateIntegrationReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateIntegrationReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CreateIntegrationReply}
 */
proto.api.CreateIntegrationReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CreateIntegrationReply;
  return proto.api.CreateIntegrationReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CreateIntegrationReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CreateIntegrationReply}
 */
proto.api.CreateIntegrationReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CreateIntegrationReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CreateIntegrationReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CreateIntegrationReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateIntegrationReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.CreateIntegrationReply.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreateIntegrationReply} returns this
 */
proto.api.CreateIntegrationReply.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListIntegrationProtocolsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListIntegrationProtocolsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListIntegrationProtocolsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListIntegrationProtocolsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    category: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListIntegrationProtocolsRequest}
 */
proto.api.ListIntegrationProtocolsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListIntegrationProtocolsRequest;
  return proto.api.ListIntegrationProtocolsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListIntegrationProtocolsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListIntegrationProtocolsRequest}
 */
proto.api.ListIntegrationProtocolsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.CategoryMap} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListIntegrationProtocolsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListIntegrationProtocolsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListIntegrationProtocolsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListIntegrationProtocolsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.api.ListIntegrationProtocolsRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListIntegrationProtocolsRequest} returns this
 */
proto.api.ListIntegrationProtocolsRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.CategoryMap category = 2;
 * @return {!proto.common.CategoryMap}
 */
proto.api.ListIntegrationProtocolsRequest.prototype.getCategory = function() {
  return /** @type {!proto.common.CategoryMap} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.CategoryMap} value
 * @return {!proto.api.ListIntegrationProtocolsRequest} returns this
 */
proto.api.ListIntegrationProtocolsRequest.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ListIntegrationProtocolsReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListIntegrationProtocolsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListIntegrationProtocolsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListIntegrationProtocolsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListIntegrationProtocolsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultList: jspb.Message.toObjectList(msg.getResultList(),
    proto.api.IntegrationProtocol.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListIntegrationProtocolsReply}
 */
proto.api.ListIntegrationProtocolsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListIntegrationProtocolsReply;
  return proto.api.ListIntegrationProtocolsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListIntegrationProtocolsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListIntegrationProtocolsReply}
 */
proto.api.ListIntegrationProtocolsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.IntegrationProtocol;
      reader.readMessage(value,proto.api.IntegrationProtocol.deserializeBinaryFromReader);
      msg.addResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListIntegrationProtocolsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListIntegrationProtocolsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListIntegrationProtocolsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListIntegrationProtocolsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.IntegrationProtocol.serializeBinaryToWriter
    );
  }
};


/**
 * repeated IntegrationProtocol result = 1;
 * @return {!Array<!proto.api.IntegrationProtocol>}
 */
proto.api.ListIntegrationProtocolsReply.prototype.getResultList = function() {
  return /** @type{!Array<!proto.api.IntegrationProtocol>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.IntegrationProtocol, 1));
};


/**
 * @param {!Array<!proto.api.IntegrationProtocol>} value
 * @return {!proto.api.ListIntegrationProtocolsReply} returns this
*/
proto.api.ListIntegrationProtocolsReply.prototype.setResultList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.IntegrationProtocol=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.IntegrationProtocol}
 */
proto.api.ListIntegrationProtocolsReply.prototype.addResult = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.IntegrationProtocol, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ListIntegrationProtocolsReply} returns this
 */
proto.api.ListIntegrationProtocolsReply.prototype.clearResultList = function() {
  return this.setResultList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListBacnetIpAssignedDevicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListBacnetIpAssignedDevicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListBacnetIpAssignedDevicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListBacnetIpAssignedDevicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    integrationProtocolId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListBacnetIpAssignedDevicesRequest}
 */
proto.api.ListBacnetIpAssignedDevicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListBacnetIpAssignedDevicesRequest;
  return proto.api.ListBacnetIpAssignedDevicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListBacnetIpAssignedDevicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListBacnetIpAssignedDevicesRequest}
 */
proto.api.ListBacnetIpAssignedDevicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntegrationProtocolId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListBacnetIpAssignedDevicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListBacnetIpAssignedDevicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListBacnetIpAssignedDevicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListBacnetIpAssignedDevicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIntegrationProtocolId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string integration_protocol_id = 1;
 * @return {string}
 */
proto.api.ListBacnetIpAssignedDevicesRequest.prototype.getIntegrationProtocolId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListBacnetIpAssignedDevicesRequest} returns this
 */
proto.api.ListBacnetIpAssignedDevicesRequest.prototype.setIntegrationProtocolId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.Value.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.api.Value.ValueCase = {
  VALUE_NOT_SET: 0,
  DOUBLE_VALUE: 1,
  INTEGER_VALUE: 2,
  FLOAT_VALUE: 3,
  BOOL_VALUE: 4,
  STRING_VALUE: 5
};

/**
 * @return {proto.api.Value.ValueCase}
 */
proto.api.Value.prototype.getValueCase = function() {
  return /** @type {proto.api.Value.ValueCase} */(jspb.Message.computeOneofCase(this, proto.api.Value.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Value.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Value.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Value} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Value.toObject = function(includeInstance, msg) {
  var f, obj = {
    doubleValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    integerValue: jspb.Message.getFieldWithDefault(msg, 2, 0),
    floatValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    boolValue: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    stringValue: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Value}
 */
proto.api.Value.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Value;
  return proto.api.Value.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Value} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Value}
 */
proto.api.Value.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDoubleValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIntegerValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatValue(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolValue(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Value.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Value.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Value} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Value.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional double double_value = 1;
 * @return {number}
 */
proto.api.Value.prototype.getDoubleValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.api.Value} returns this
 */
proto.api.Value.prototype.setDoubleValue = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.api.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.Value} returns this
 */
proto.api.Value.prototype.clearDoubleValue = function() {
  return jspb.Message.setOneofField(this, 1, proto.api.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Value.prototype.hasDoubleValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 integer_value = 2;
 * @return {number}
 */
proto.api.Value.prototype.getIntegerValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Value} returns this
 */
proto.api.Value.prototype.setIntegerValue = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.api.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.Value} returns this
 */
proto.api.Value.prototype.clearIntegerValue = function() {
  return jspb.Message.setOneofField(this, 2, proto.api.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Value.prototype.hasIntegerValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional float float_value = 3;
 * @return {number}
 */
proto.api.Value.prototype.getFloatValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.api.Value} returns this
 */
proto.api.Value.prototype.setFloatValue = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.api.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.Value} returns this
 */
proto.api.Value.prototype.clearFloatValue = function() {
  return jspb.Message.setOneofField(this, 3, proto.api.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Value.prototype.hasFloatValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool bool_value = 4;
 * @return {boolean}
 */
proto.api.Value.prototype.getBoolValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Value} returns this
 */
proto.api.Value.prototype.setBoolValue = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.api.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.Value} returns this
 */
proto.api.Value.prototype.clearBoolValue = function() {
  return jspb.Message.setOneofField(this, 4, proto.api.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Value.prototype.hasBoolValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string string_value = 5;
 * @return {string}
 */
proto.api.Value.prototype.getStringValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Value} returns this
 */
proto.api.Value.prototype.setStringValue = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.api.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.Value} returns this
 */
proto.api.Value.prototype.clearStringValue = function() {
  return jspb.Message.setOneofField(this, 5, proto.api.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Value.prototype.hasStringValue = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.BacnetIpDataPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.api.BacnetIpDataPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.BacnetIpDataPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.BacnetIpDataPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataPointId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objectName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    objectType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    objectInstance: jspb.Message.getFieldWithDefault(msg, 4, 0),
    unit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    covIncrement: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    presentValue: (f = msg.getPresentValue()) && proto.api.Value.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.BacnetIpDataPoint}
 */
proto.api.BacnetIpDataPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.BacnetIpDataPoint;
  return proto.api.BacnetIpDataPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.BacnetIpDataPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.BacnetIpDataPoint}
 */
proto.api.BacnetIpDataPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDataPointId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    case 3:
      var value = /** @type {!proto.common.DataObjectTypeEnum} */ (reader.readEnum());
      msg.setObjectType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setObjectInstance(value);
      break;
    case 5:
      var value = /** @type {!proto.common.EngineeringUnits} */ (reader.readEnum());
      msg.setUnit(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCovIncrement(value);
      break;
    case 7:
      var value = new proto.api.Value;
      reader.readMessage(value,proto.api.Value.deserializeBinaryFromReader);
      msg.setPresentValue(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.BacnetIpDataPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.BacnetIpDataPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.BacnetIpDataPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.BacnetIpDataPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataPointId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjectName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getObjectType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getObjectInstance();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCovIncrement();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getPresentValue();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.api.Value.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string data_point_id = 1;
 * @return {string}
 */
proto.api.BacnetIpDataPoint.prototype.getDataPointId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.BacnetIpDataPoint} returns this
 */
proto.api.BacnetIpDataPoint.prototype.setDataPointId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string object_name = 2;
 * @return {string}
 */
proto.api.BacnetIpDataPoint.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.BacnetIpDataPoint} returns this
 */
proto.api.BacnetIpDataPoint.prototype.setObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional common.DataObjectTypeEnum object_type = 3;
 * @return {!proto.common.DataObjectTypeEnum}
 */
proto.api.BacnetIpDataPoint.prototype.getObjectType = function() {
  return /** @type {!proto.common.DataObjectTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.DataObjectTypeEnum} value
 * @return {!proto.api.BacnetIpDataPoint} returns this
 */
proto.api.BacnetIpDataPoint.prototype.setObjectType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional uint32 object_instance = 4;
 * @return {number}
 */
proto.api.BacnetIpDataPoint.prototype.getObjectInstance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.BacnetIpDataPoint} returns this
 */
proto.api.BacnetIpDataPoint.prototype.setObjectInstance = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional common.EngineeringUnits unit = 5;
 * @return {!proto.common.EngineeringUnits}
 */
proto.api.BacnetIpDataPoint.prototype.getUnit = function() {
  return /** @type {!proto.common.EngineeringUnits} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.EngineeringUnits} value
 * @return {!proto.api.BacnetIpDataPoint} returns this
 */
proto.api.BacnetIpDataPoint.prototype.setUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional float cov_increment = 6;
 * @return {number}
 */
proto.api.BacnetIpDataPoint.prototype.getCovIncrement = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.api.BacnetIpDataPoint} returns this
 */
proto.api.BacnetIpDataPoint.prototype.setCovIncrement = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional Value present_value = 7;
 * @return {?proto.api.Value}
 */
proto.api.BacnetIpDataPoint.prototype.getPresentValue = function() {
  return /** @type{?proto.api.Value} */ (
    jspb.Message.getWrapperField(this, proto.api.Value, 7));
};


/**
 * @param {?proto.api.Value|undefined} value
 * @return {!proto.api.BacnetIpDataPoint} returns this
*/
proto.api.BacnetIpDataPoint.prototype.setPresentValue = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.BacnetIpDataPoint} returns this
 */
proto.api.BacnetIpDataPoint.prototype.clearPresentValue = function() {
  return this.setPresentValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.BacnetIpDataPoint.prototype.hasPresentValue = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string description = 8;
 * @return {string}
 */
proto.api.BacnetIpDataPoint.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.BacnetIpDataPoint} returns this
 */
proto.api.BacnetIpDataPoint.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.BacnetIpAssignedDevice.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.BacnetIpAssignedDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.api.BacnetIpAssignedDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.BacnetIpAssignedDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.BacnetIpAssignedDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessDeviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accessDeviceName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    properties: (f = msg.getProperties()) && api_access_device_pb.AccessDeviceProperties.toObject(includeInstance, f),
    lastSeen: (f = msg.getLastSeen()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    bacnetDeviceId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    bacnetDeviceName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dataPointsList: jspb.Message.toObjectList(msg.getDataPointsList(),
    proto.api.BacnetIpDataPoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.BacnetIpAssignedDevice}
 */
proto.api.BacnetIpAssignedDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.BacnetIpAssignedDevice;
  return proto.api.BacnetIpAssignedDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.BacnetIpAssignedDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.BacnetIpAssignedDevice}
 */
proto.api.BacnetIpAssignedDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessDeviceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessDeviceName(value);
      break;
    case 3:
      var value = new api_access_device_pb.AccessDeviceProperties;
      reader.readMessage(value,api_access_device_pb.AccessDeviceProperties.deserializeBinaryFromReader);
      msg.setProperties(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastSeen(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBacnetDeviceId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBacnetDeviceName(value);
      break;
    case 7:
      var value = new proto.api.BacnetIpDataPoint;
      reader.readMessage(value,proto.api.BacnetIpDataPoint.deserializeBinaryFromReader);
      msg.addDataPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.BacnetIpAssignedDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.BacnetIpAssignedDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.BacnetIpAssignedDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.BacnetIpAssignedDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccessDeviceName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProperties();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      api_access_device_pb.AccessDeviceProperties.serializeBinaryToWriter
    );
  }
  f = message.getLastSeen();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBacnetDeviceId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getBacnetDeviceName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDataPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.api.BacnetIpDataPoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional string access_device_id = 1;
 * @return {string}
 */
proto.api.BacnetIpAssignedDevice.prototype.getAccessDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.BacnetIpAssignedDevice} returns this
 */
proto.api.BacnetIpAssignedDevice.prototype.setAccessDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string access_device_name = 2;
 * @return {string}
 */
proto.api.BacnetIpAssignedDevice.prototype.getAccessDeviceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.BacnetIpAssignedDevice} returns this
 */
proto.api.BacnetIpAssignedDevice.prototype.setAccessDeviceName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AccessDeviceProperties properties = 3;
 * @return {?proto.api.AccessDeviceProperties}
 */
proto.api.BacnetIpAssignedDevice.prototype.getProperties = function() {
  return /** @type{?proto.api.AccessDeviceProperties} */ (
    jspb.Message.getWrapperField(this, api_access_device_pb.AccessDeviceProperties, 3));
};


/**
 * @param {?proto.api.AccessDeviceProperties|undefined} value
 * @return {!proto.api.BacnetIpAssignedDevice} returns this
*/
proto.api.BacnetIpAssignedDevice.prototype.setProperties = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.BacnetIpAssignedDevice} returns this
 */
proto.api.BacnetIpAssignedDevice.prototype.clearProperties = function() {
  return this.setProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.BacnetIpAssignedDevice.prototype.hasProperties = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp last_seen = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.BacnetIpAssignedDevice.prototype.getLastSeen = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.BacnetIpAssignedDevice} returns this
*/
proto.api.BacnetIpAssignedDevice.prototype.setLastSeen = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.BacnetIpAssignedDevice} returns this
 */
proto.api.BacnetIpAssignedDevice.prototype.clearLastSeen = function() {
  return this.setLastSeen(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.BacnetIpAssignedDevice.prototype.hasLastSeen = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint32 bacnet_device_id = 5;
 * @return {number}
 */
proto.api.BacnetIpAssignedDevice.prototype.getBacnetDeviceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.BacnetIpAssignedDevice} returns this
 */
proto.api.BacnetIpAssignedDevice.prototype.setBacnetDeviceId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string bacnet_device_name = 6;
 * @return {string}
 */
proto.api.BacnetIpAssignedDevice.prototype.getBacnetDeviceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.BacnetIpAssignedDevice} returns this
 */
proto.api.BacnetIpAssignedDevice.prototype.setBacnetDeviceName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated BacnetIpDataPoint data_points = 7;
 * @return {!Array<!proto.api.BacnetIpDataPoint>}
 */
proto.api.BacnetIpAssignedDevice.prototype.getDataPointsList = function() {
  return /** @type{!Array<!proto.api.BacnetIpDataPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.BacnetIpDataPoint, 7));
};


/**
 * @param {!Array<!proto.api.BacnetIpDataPoint>} value
 * @return {!proto.api.BacnetIpAssignedDevice} returns this
*/
proto.api.BacnetIpAssignedDevice.prototype.setDataPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.api.BacnetIpDataPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.BacnetIpDataPoint}
 */
proto.api.BacnetIpAssignedDevice.prototype.addDataPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.api.BacnetIpDataPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.BacnetIpAssignedDevice} returns this
 */
proto.api.BacnetIpAssignedDevice.prototype.clearDataPointsList = function() {
  return this.setDataPointsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ListBacnetIpAssignedDevicesReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListBacnetIpAssignedDevicesReply.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListBacnetIpAssignedDevicesReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListBacnetIpAssignedDevicesReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListBacnetIpAssignedDevicesReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultList: jspb.Message.toObjectList(msg.getResultList(),
    proto.api.BacnetIpAssignedDevice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListBacnetIpAssignedDevicesReply}
 */
proto.api.ListBacnetIpAssignedDevicesReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListBacnetIpAssignedDevicesReply;
  return proto.api.ListBacnetIpAssignedDevicesReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListBacnetIpAssignedDevicesReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListBacnetIpAssignedDevicesReply}
 */
proto.api.ListBacnetIpAssignedDevicesReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.BacnetIpAssignedDevice;
      reader.readMessage(value,proto.api.BacnetIpAssignedDevice.deserializeBinaryFromReader);
      msg.addResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListBacnetIpAssignedDevicesReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListBacnetIpAssignedDevicesReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListBacnetIpAssignedDevicesReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListBacnetIpAssignedDevicesReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.BacnetIpAssignedDevice.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BacnetIpAssignedDevice result = 1;
 * @return {!Array<!proto.api.BacnetIpAssignedDevice>}
 */
proto.api.ListBacnetIpAssignedDevicesReply.prototype.getResultList = function() {
  return /** @type{!Array<!proto.api.BacnetIpAssignedDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.BacnetIpAssignedDevice, 1));
};


/**
 * @param {!Array<!proto.api.BacnetIpAssignedDevice>} value
 * @return {!proto.api.ListBacnetIpAssignedDevicesReply} returns this
*/
proto.api.ListBacnetIpAssignedDevicesReply.prototype.setResultList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.BacnetIpAssignedDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.BacnetIpAssignedDevice}
 */
proto.api.ListBacnetIpAssignedDevicesReply.prototype.addResult = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.BacnetIpAssignedDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ListBacnetIpAssignedDevicesReply} returns this
 */
proto.api.ListBacnetIpAssignedDevicesReply.prototype.clearResultList = function() {
  return this.setResultList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.RemoveIntegraionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.RemoveIntegraionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.RemoveIntegraionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.RemoveIntegraionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.RemoveIntegraionRequest}
 */
proto.api.RemoveIntegraionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.RemoveIntegraionRequest;
  return proto.api.RemoveIntegraionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.RemoveIntegraionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.RemoveIntegraionRequest}
 */
proto.api.RemoveIntegraionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.RemoveIntegraionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.RemoveIntegraionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.RemoveIntegraionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.RemoveIntegraionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.RemoveIntegraionRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.RemoveIntegraionRequest} returns this
 */
proto.api.RemoveIntegraionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.RemoveIntegrationReply.prototype.toObject = function(opt_includeInstance) {
  return proto.api.RemoveIntegrationReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.RemoveIntegrationReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.RemoveIntegrationReply.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.RemoveIntegrationReply}
 */
proto.api.RemoveIntegrationReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.RemoveIntegrationReply;
  return proto.api.RemoveIntegrationReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.RemoveIntegrationReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.RemoveIntegrationReply}
 */
proto.api.RemoveIntegrationReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.RemoveIntegrationReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.RemoveIntegrationReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.RemoveIntegrationReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.RemoveIntegrationReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.api);
