import { AccessDeviceSerivceClient } from '@/api/access_device_grpc_web_pb'
import { AccessDevice, AccessDeviceColumn, AccessDeviceProperties, CreateAccessDeviceParams, CreateAccessDeviceRequest, CreateAccessDeviceResult, GetDeviceStatByProjectReply, GetDeviceStatByProjectRequest, ListAccessDeviceByProjectRequest, LoRaWANDeviceProperties, RemoveAccessDeviceRequest } from '@/api/access_device_pb'
import { GRPC_ENDPOINT } from '@/const'
import { GetProjectId } from './organization'
import { Metadata } from './session'
import { ErrorHandler } from './helper'
import { DataPointLoRaWANProperties, DataPointType } from '@/api/access_device_profile_pb'
import { Region, TableOrder } from '@/common/common_pb'

export type AccessDeviceListStat = GetDeviceStatByProjectReply.AsObject
export type IAccessDevice = AccessDevice.AsObject
export type IAccessDeviceGeneralConfig = Pick<IAccessDevice, 'name' | 'note' | 'location' >
export type ILoRaWANDeviceParams = LoRaWANDeviceProperties.AsObject
export type IDataPoint = DataPointType.AsObject
export type IAccessDeviceParams = CreateAccessDeviceParams.AsObject
export type ICreateAccessDeviceResult = CreateAccessDeviceResult.AsObject

interface IState {
  list: IAccessDevice[]
  stat: AccessDeviceListStat
}

const state:IState = {
  list: [],
  stat: {
    count: 0
  }
}

const mutations = {
  fetch (state: IState, devices: IAccessDevice[]) {
    state.list = devices
  },
  delete (state: IState, id: string) {
    for (let i = 0; i < state.list.length; i++) {
      if (state.list[i].id === id) {
        state.list.splice(i, 1)
        break
      }
    }
  },

  fetchStat (state: IState, stat:AccessDeviceListStat) {
    state.stat = stat
  }
}

const getters = {
  list: (state: IState) => () => {
    return state.list
  },
  stat: (state: IState) => () => {
    return state.stat
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export class AccessDeviceStore {
  private store
  private client

  constructor (store:any) {
    this.store = store
    this.client = new AccessDeviceSerivceClient(GRPC_ENDPOINT)
  }

  FetchStat = async (search?: string): Promise<void> => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      const request = new GetDeviceStatByProjectRequest()
      const projectId = await GetProjectId(this.store)
      request.setProjectId(projectId)
      if (search) { request.setSearch(search) }
      this.client.getStatByProject(request, Metadata(this.store), (err, reply) => {
        if (err) {
          ErrorHandler(err)
          return reject(err)
        }

        const result = reply.toObject()

        this.store.commit('accessDevice/fetchStat', result as AccessDeviceListStat)
        resolve()
      })
    })
  }

  GetStat = () : AccessDeviceListStat => {
    return this.store.getters['accessDevice/stat']()
  }

  Fetch = async (orderBy: AccessDeviceColumn, order: TableOrder, limit: number, offset: number, search?: string):Promise<void> => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      const request = new ListAccessDeviceByProjectRequest()
      const projectId = await GetProjectId(this.store)

      request.setProjectId(projectId)
      request.setOrderBy(orderBy)
      request.setLimit(limit)
      request.setOffset(offset)
      request.setOrder(order)
      if (search) { request.setSearch(search) }
      this.client.listByProject(request, Metadata(this.store), (err, reply) => {
        if (err) {
          ErrorHandler(err)
          return reject(err)
        }

        const result = reply.toObject().resultList
        console.log(result)
        this.store.commit('accessDevice/fetch', result)
        resolve()
      })
    })
  }

  List = () => {
    return this.store.getters['accessDevice/list']()
  }

  Create = (data: IAccessDeviceParams[]):Promise<ICreateAccessDeviceResult[]> => {
    const Object2GRPC = (obj:IAccessDeviceParams) : CreateAccessDeviceParams => {
      const data = new CreateAccessDeviceParams()
      data.setName(obj.name)
      data.setAccessProtocolId(obj.accessProtocolId)
      data.setLocation(obj.location)
      data.setNote(obj.note)
      data.setAccessDeviceProfileId(obj.accessDeviceProfileId)
      data.setAccessProtocolId(obj.accessProtocolId)
      const properties = new AccessDeviceProperties()
      const lorawanProps = new LoRaWANDeviceProperties()
      lorawanProps.setRegion(obj.properties?.lorawan?.region || Region.EU868)
      lorawanProps.setDevEui(obj.properties?.lorawan?.devEui || '')
      lorawanProps.setAppKey(obj.properties?.lorawan?.appKey || '')
      lorawanProps.setJoinEui(obj.properties?.lorawan?.joinEui || '')
      lorawanProps.setAppsKey(obj.properties?.lorawan?.appsKey || '')
      lorawanProps.setNwksKey(obj.properties?.lorawan?.nwksKey || '')
      lorawanProps.setDevAddr(obj.properties?.lorawan?.devAddr || '')
      properties.setLorawan(lorawanProps)
      data.setProperties(properties)
      for (const dp of obj.dataPointList) {
        const datapoint = new DataPointType()
        datapoint.setChannel(dp.channel)
        datapoint.setName(dp.name)
        datapoint.setUnit(dp.unit)
        datapoint.setUpdateInterval(dp.unit)
        datapoint.setType(dp.type)
        const dpLorawanProps = new DataPointLoRaWANProperties()
        datapoint.setWritable(dp.writable)
        datapoint.setLorawan(dpLorawanProps)
        data.addDataPoint(datapoint)
      }

      return data
    }
    return new Promise<ICreateAccessDeviceResult[]>((resolve, reject) => {
      // const ProfielStore = new AccessDeviceProfileStore(this.store)
      const request = new CreateAccessDeviceRequest()
      for (const item of data) {
        request.addDevices(Object2GRPC(item))
      }

      this.client.create(request, Metadata(this.store), (err, reply) => {
        if (err) {
          ErrorHandler(err)
          return reject(err)
        }

        const result = reply.toObject()
        return resolve(result.resultList)
      })
    })
  }

  Delete = async (id: string) : Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      const request = new RemoveAccessDeviceRequest()
      request.setId(id)

      this.client.remove(request, Metadata(this.store), (err, _reply) => {
        if (err) {
          ErrorHandler(err)
          reject(err)
        }
        this.store.commit('accessDevice/delete', id)
        resolve()
      })
    })
  }

  Get = (id: string): IAccessDevice | undefined => {
    const list = this.List()
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === id) {
        return list[i]
      }
    }
  }
}
