export interface IMqttMessage {
  topic : string,
  payload: any
}

interface IState {
    view? : IMqttMessage,
    pickedUp?: IMqttMessage
}

const state:IState = {

}

const mutations = {
  set (state: IState, data: { topic: string, payload: any }) {
    state.view = {
      topic: data.topic,
      payload: data.payload
    }
  },

  clear (state: IState) {
    state.view = undefined
  },

  pickup (state: IState, data: { topic: string, payload: any}) {
    state.pickedUp = {
      topic: data.topic,
      payload: data.payload
    }
  }
}

const getters = {
  get: (state: IState) => () => {
    return state.view
  },
  getPickup: (state: IState) => () => {
    return state.pickedUp
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
