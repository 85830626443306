/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.19.1
// source: api/access_device_profile.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var common_common_pb = require('../common/common_pb.js')
const proto = {};
proto.api = require('./access_device_profile_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.AccessDeviceProfileServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.AccessDeviceProfileServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.ListAccessDeviceProfileRequest,
 *   !proto.api.ListAccessDeviceProfileReply>}
 */
const methodDescriptor_AccessDeviceProfileService_List = new grpc.web.MethodDescriptor(
  '/api.AccessDeviceProfileService/List',
  grpc.web.MethodType.UNARY,
  proto.api.ListAccessDeviceProfileRequest,
  proto.api.ListAccessDeviceProfileReply,
  /**
   * @param {!proto.api.ListAccessDeviceProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.ListAccessDeviceProfileReply.deserializeBinary
);


/**
 * @param {!proto.api.ListAccessDeviceProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.ListAccessDeviceProfileReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.ListAccessDeviceProfileReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.AccessDeviceProfileServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.AccessDeviceProfileService/List',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceProfileService_List,
      callback);
};


/**
 * @param {!proto.api.ListAccessDeviceProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.ListAccessDeviceProfileReply>}
 *     Promise that resolves to the response
 */
proto.api.AccessDeviceProfileServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.AccessDeviceProfileService/List',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceProfileService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.ListAccessDeviceProfileRequest,
 *   !proto.api.ListAccessDeviceProfileReply>}
 */
const methodDescriptor_AccessDeviceProfileService_ListByUser = new grpc.web.MethodDescriptor(
  '/api.AccessDeviceProfileService/ListByUser',
  grpc.web.MethodType.UNARY,
  proto.api.ListAccessDeviceProfileRequest,
  proto.api.ListAccessDeviceProfileReply,
  /**
   * @param {!proto.api.ListAccessDeviceProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.ListAccessDeviceProfileReply.deserializeBinary
);


/**
 * @param {!proto.api.ListAccessDeviceProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.ListAccessDeviceProfileReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.ListAccessDeviceProfileReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.AccessDeviceProfileServiceClient.prototype.listByUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.AccessDeviceProfileService/ListByUser',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceProfileService_ListByUser,
      callback);
};


/**
 * @param {!proto.api.ListAccessDeviceProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.ListAccessDeviceProfileReply>}
 *     Promise that resolves to the response
 */
proto.api.AccessDeviceProfileServicePromiseClient.prototype.listByUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.AccessDeviceProfileService/ListByUser',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceProfileService_ListByUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.ListAccessDeviceProfileByCategoryRequest,
 *   !proto.api.ListAccessDeviceProfileByCategoryReply>}
 */
const methodDescriptor_AccessDeviceProfileService_ListByCategory = new grpc.web.MethodDescriptor(
  '/api.AccessDeviceProfileService/ListByCategory',
  grpc.web.MethodType.UNARY,
  proto.api.ListAccessDeviceProfileByCategoryRequest,
  proto.api.ListAccessDeviceProfileByCategoryReply,
  /**
   * @param {!proto.api.ListAccessDeviceProfileByCategoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.ListAccessDeviceProfileByCategoryReply.deserializeBinary
);


/**
 * @param {!proto.api.ListAccessDeviceProfileByCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.ListAccessDeviceProfileByCategoryReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.ListAccessDeviceProfileByCategoryReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.AccessDeviceProfileServiceClient.prototype.listByCategory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.AccessDeviceProfileService/ListByCategory',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceProfileService_ListByCategory,
      callback);
};


/**
 * @param {!proto.api.ListAccessDeviceProfileByCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.ListAccessDeviceProfileByCategoryReply>}
 *     Promise that resolves to the response
 */
proto.api.AccessDeviceProfileServicePromiseClient.prototype.listByCategory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.AccessDeviceProfileService/ListByCategory',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceProfileService_ListByCategory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.CreateAccessDeviceProfileRequest,
 *   !proto.api.CreateAccessDeviceProfileReply>}
 */
const methodDescriptor_AccessDeviceProfileService_Create = new grpc.web.MethodDescriptor(
  '/api.AccessDeviceProfileService/Create',
  grpc.web.MethodType.UNARY,
  proto.api.CreateAccessDeviceProfileRequest,
  proto.api.CreateAccessDeviceProfileReply,
  /**
   * @param {!proto.api.CreateAccessDeviceProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.CreateAccessDeviceProfileReply.deserializeBinary
);


/**
 * @param {!proto.api.CreateAccessDeviceProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.CreateAccessDeviceProfileReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.CreateAccessDeviceProfileReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.AccessDeviceProfileServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.AccessDeviceProfileService/Create',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceProfileService_Create,
      callback);
};


/**
 * @param {!proto.api.CreateAccessDeviceProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.CreateAccessDeviceProfileReply>}
 *     Promise that resolves to the response
 */
proto.api.AccessDeviceProfileServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.AccessDeviceProfileService/Create',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceProfileService_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.DeleteAccessDeviceProfileRequest,
 *   !proto.api.DeleteAccessDeviceProfileReply>}
 */
const methodDescriptor_AccessDeviceProfileService_Remove = new grpc.web.MethodDescriptor(
  '/api.AccessDeviceProfileService/Remove',
  grpc.web.MethodType.UNARY,
  proto.api.DeleteAccessDeviceProfileRequest,
  proto.api.DeleteAccessDeviceProfileReply,
  /**
   * @param {!proto.api.DeleteAccessDeviceProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.DeleteAccessDeviceProfileReply.deserializeBinary
);


/**
 * @param {!proto.api.DeleteAccessDeviceProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.DeleteAccessDeviceProfileReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.DeleteAccessDeviceProfileReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.AccessDeviceProfileServiceClient.prototype.remove =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.AccessDeviceProfileService/Remove',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceProfileService_Remove,
      callback);
};


/**
 * @param {!proto.api.DeleteAccessDeviceProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.DeleteAccessDeviceProfileReply>}
 *     Promise that resolves to the response
 */
proto.api.AccessDeviceProfileServicePromiseClient.prototype.remove =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.AccessDeviceProfileService/Remove',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceProfileService_Remove);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.UpdateAccessDeviceProfileRequest,
 *   !proto.api.UpdateAccessDeviceProfileReply>}
 */
const methodDescriptor_AccessDeviceProfileService_Update = new grpc.web.MethodDescriptor(
  '/api.AccessDeviceProfileService/Update',
  grpc.web.MethodType.UNARY,
  proto.api.UpdateAccessDeviceProfileRequest,
  proto.api.UpdateAccessDeviceProfileReply,
  /**
   * @param {!proto.api.UpdateAccessDeviceProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.UpdateAccessDeviceProfileReply.deserializeBinary
);


/**
 * @param {!proto.api.UpdateAccessDeviceProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.UpdateAccessDeviceProfileReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.UpdateAccessDeviceProfileReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.AccessDeviceProfileServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.AccessDeviceProfileService/Update',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceProfileService_Update,
      callback);
};


/**
 * @param {!proto.api.UpdateAccessDeviceProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.UpdateAccessDeviceProfileReply>}
 *     Promise that resolves to the response
 */
proto.api.AccessDeviceProfileServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.AccessDeviceProfileService/Update',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceProfileService_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.GetAccessDeviceProfileRequest,
 *   !proto.api.GetAccessDeviceProfileReply>}
 */
const methodDescriptor_AccessDeviceProfileService_Get = new grpc.web.MethodDescriptor(
  '/api.AccessDeviceProfileService/Get',
  grpc.web.MethodType.UNARY,
  proto.api.GetAccessDeviceProfileRequest,
  proto.api.GetAccessDeviceProfileReply,
  /**
   * @param {!proto.api.GetAccessDeviceProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.GetAccessDeviceProfileReply.deserializeBinary
);


/**
 * @param {!proto.api.GetAccessDeviceProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.GetAccessDeviceProfileReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.GetAccessDeviceProfileReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.AccessDeviceProfileServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.AccessDeviceProfileService/Get',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceProfileService_Get,
      callback);
};


/**
 * @param {!proto.api.GetAccessDeviceProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.GetAccessDeviceProfileReply>}
 *     Promise that resolves to the response
 */
proto.api.AccessDeviceProfileServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.AccessDeviceProfileService/Get',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceProfileService_Get);
};


module.exports = proto.api;

