/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.19.1
// source: api/datapoint.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var common_common_pb = require('../common/common_pb.js')
const proto = {};
proto.api = require('./datapoint_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.DataPointServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.DataPointServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.ListDataPointsRequest,
 *   !proto.api.ListDataPointsReply>}
 */
const methodDescriptor_DataPointService_List = new grpc.web.MethodDescriptor(
  '/api.DataPointService/List',
  grpc.web.MethodType.UNARY,
  proto.api.ListDataPointsRequest,
  proto.api.ListDataPointsReply,
  /**
   * @param {!proto.api.ListDataPointsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.ListDataPointsReply.deserializeBinary
);


/**
 * @param {!proto.api.ListDataPointsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.ListDataPointsReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.ListDataPointsReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.DataPointServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.DataPointService/List',
      request,
      metadata || {},
      methodDescriptor_DataPointService_List,
      callback);
};


/**
 * @param {!proto.api.ListDataPointsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.ListDataPointsReply>}
 *     Promise that resolves to the response
 */
proto.api.DataPointServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.DataPointService/List',
      request,
      metadata || {},
      methodDescriptor_DataPointService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.GetDataPointStatByProjectRequest,
 *   !proto.api.GetDataPointStatByProjectReply>}
 */
const methodDescriptor_DataPointService_GetStatByProject = new grpc.web.MethodDescriptor(
  '/api.DataPointService/GetStatByProject',
  grpc.web.MethodType.UNARY,
  proto.api.GetDataPointStatByProjectRequest,
  proto.api.GetDataPointStatByProjectReply,
  /**
   * @param {!proto.api.GetDataPointStatByProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.GetDataPointStatByProjectReply.deserializeBinary
);


/**
 * @param {!proto.api.GetDataPointStatByProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.GetDataPointStatByProjectReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.GetDataPointStatByProjectReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.DataPointServiceClient.prototype.getStatByProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.DataPointService/GetStatByProject',
      request,
      metadata || {},
      methodDescriptor_DataPointService_GetStatByProject,
      callback);
};


/**
 * @param {!proto.api.GetDataPointStatByProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.GetDataPointStatByProjectReply>}
 *     Promise that resolves to the response
 */
proto.api.DataPointServicePromiseClient.prototype.getStatByProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.DataPointService/GetStatByProject',
      request,
      metadata || {},
      methodDescriptor_DataPointService_GetStatByProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.ListByDeviceRequest,
 *   !proto.api.ListByDeviceReply>}
 */
const methodDescriptor_DataPointService_ListByDevice = new grpc.web.MethodDescriptor(
  '/api.DataPointService/ListByDevice',
  grpc.web.MethodType.UNARY,
  proto.api.ListByDeviceRequest,
  proto.api.ListByDeviceReply,
  /**
   * @param {!proto.api.ListByDeviceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.ListByDeviceReply.deserializeBinary
);


/**
 * @param {!proto.api.ListByDeviceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.ListByDeviceReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.ListByDeviceReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.DataPointServiceClient.prototype.listByDevice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.DataPointService/ListByDevice',
      request,
      metadata || {},
      methodDescriptor_DataPointService_ListByDevice,
      callback);
};


/**
 * @param {!proto.api.ListByDeviceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.ListByDeviceReply>}
 *     Promise that resolves to the response
 */
proto.api.DataPointServicePromiseClient.prototype.listByDevice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.DataPointService/ListByDevice',
      request,
      metadata || {},
      methodDescriptor_DataPointService_ListByDevice);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.DataPointValueServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.DataPointValueServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.ListDataPointValueRequest,
 *   !proto.api.ListDataPointValueReply>}
 */
const methodDescriptor_DataPointValueService_List = new grpc.web.MethodDescriptor(
  '/api.DataPointValueService/List',
  grpc.web.MethodType.UNARY,
  proto.api.ListDataPointValueRequest,
  proto.api.ListDataPointValueReply,
  /**
   * @param {!proto.api.ListDataPointValueRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.ListDataPointValueReply.deserializeBinary
);


/**
 * @param {!proto.api.ListDataPointValueRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.ListDataPointValueReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.ListDataPointValueReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.DataPointValueServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.DataPointValueService/List',
      request,
      metadata || {},
      methodDescriptor_DataPointValueService_List,
      callback);
};


/**
 * @param {!proto.api.ListDataPointValueRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.ListDataPointValueReply>}
 *     Promise that resolves to the response
 */
proto.api.DataPointValueServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.DataPointValueService/List',
      request,
      metadata || {},
      methodDescriptor_DataPointValueService_List);
};


module.exports = proto.api;

