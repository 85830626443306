import { AccessDeviceProfileServiceClient } from '@/api/access_device_profile_grpc_web_pb'
import { AccessDeviceProfile, AccessDeviceProfileProperties, CreateAccessDeviceProfileRequest, DataPointBACnetProperties, DataPointLoRaWANProperties, DataPointType, DeleteAccessDeviceProfileRequest, GetAccessDeviceProfileRequest, ListAccessDeviceProfileRequest, LoRaWANDeviceProfileProperties, UpdateAccessDeviceProfileRequest } from '@/api/access_device_profile_pb'
import { GRPC_ENDPOINT } from '@/const'
import { Metadata } from './session'
import { ErrorHandler } from './helper'
import { Region } from '@/common/common_pb'

export type IDeviceProfile = AccessDeviceProfile.AsObject
interface IProfileState {
  list: IDeviceProfile[]
}

const state:IProfileState = {
  list: []
}
const actions = {}
const mutations = {
  fetch (state: IProfileState, list: IDeviceProfile[]) {
    state.list = list
  },
  delete (state: IProfileState, id: string) {
    for (const i in state.list) {
      if (state.list[i].id === id) {
        state.list.splice(parseInt(i), 1)
        break
      }
    }
  }
}

const getters = {
  list: (state: IProfileState) => () => {
    return state.list
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export class AccessDeviceProfileStore {
  private store
  private client

  constructor (store:any) {
    this.store = store
    this.client = new AccessDeviceProfileServiceClient(GRPC_ENDPOINT, null, { deadline: 1000 })
  }

  Fetch = async (): Promise<AccessDeviceProfile.AsObject[]> => {
    return new Promise<AccessDeviceProfile.AsObject[]>((resolve, reject) => {
      const request = new ListAccessDeviceProfileRequest()

      this.client.list(request, Metadata(this.store), (err, reply) => {
        if (err) {
          return reject(err)
        }
        const result = reply.toObject().resultList
        this.store.commit('deviceProfile/fetch', result)
        return resolve(result)
      })
    })
  }

  List = (): IDeviceProfile[] => {
    return this.store.getters['deviceProfile/list']()
  }

  private ObjectToMessage (data:IDeviceProfile) : AccessDeviceProfile {
    const profile = new AccessDeviceProfile()
    try {
      // console.log(JSON.stringify(data))
      if (data.id) {
        profile.setId(data.id)
      }
      profile.setName(data.name)
      profile.setCategory(data.category)
      profile.setModel(data.model)
      profile.setVendor(data.vendor)
      profile.setNote(data.note)

      data.dataPointsList.forEach(item => {
        const datapoint = new DataPointType()
        datapoint.setChannel(item.channel)
        datapoint.setName(item.name)
        datapoint.setType(item.type)
        datapoint.setUnit(item.unit)
        datapoint.setWritable(item.writable)
        if (item.bacnet) {
          const bacnet = new DataPointBACnetProperties()
          bacnet.setCovIncrement(item.bacnet.covIncrement)
          datapoint.setBacnet(bacnet)
        }

        if (item.lorawan) {
          const lorawan = new DataPointLoRaWANProperties()
          lorawan.setDownlinkFport(item.lorawan.downlinkFport)
          datapoint.setLorawan(lorawan)
        }
        profile.addDataPoints(datapoint)
      })

      const properties = new AccessDeviceProfileProperties()

      if (data.properties?.codec) {
        properties.setCodec(data.properties.codec)
      }

      if (data.properties?.lorawan) {
        const lorawanProp = new LoRaWANDeviceProfileProperties()
        const Regions = new Array<Region>()
        for (let i = 0; i < data.properties.lorawan.regionList.length; i++) {
          const R = data.properties.lorawan.regionList[i]
          Regions.push(R)
        }
        lorawanProp.setRegionList(Regions)
        lorawanProp.setMacVersion(data.properties.lorawan.macVersion)
        lorawanProp.setRegionalParametersRevision(data.properties.lorawan.regionalParametersRevision)
        lorawanProp.setSupportsOtaa(data.properties.lorawan.supportsOtaa)
        lorawanProp.setSupportsClassB(data.properties.lorawan.supportsClassB)
        lorawanProp.setSupportsClassC(data.properties.lorawan.supportsClassC)
        properties.setLorawan(lorawanProp)
      // properties.setLorawan(data)
      }
      profile.setProperties(properties)
    } catch (err:any) {
      console.log(err.message)
      console.error(err.stack)
    }
    return profile
  }

  Create = (data: IDeviceProfile) => {
    return new Promise<string>((resolve, reject) => {
      const request = new CreateAccessDeviceProfileRequest()
      request.setProfile(this.ObjectToMessage(data))

      this.client.create(request, Metadata(this.store), (err, reply) => {
        if (err) {
          ErrorHandler(err)
          return reject(err)
        }

        const result = reply.toObject()
        return resolve(result.id)
      })
    })
  }

  Delete = (id: string) => {
    return new Promise<void>((resolve, reject) => {
      const request = new DeleteAccessDeviceProfileRequest()
      request.setId(id)

      this.client.remove(request, Metadata(this.store), (err, reply) => {
        if (err) {
          ErrorHandler(err)
          return reject(err)
        }
        this.store.commit('deviceProfile/delete', id)
        return resolve()
      })
    })
  }

  Get = (id: string): IDeviceProfile | undefined => {
    const list = this.List()
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === id) {
        return list[i]
      }
    }
  }

  Update = async (data: IDeviceProfile) :Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      const request = new UpdateAccessDeviceProfileRequest()
      request.setProfile(this.ObjectToMessage(data))

      this.client.update(request, Metadata(this.store), (err, reply) => {
        if (err) {
          ErrorHandler(err)
          return reject(err)
        }

        resolve()
      })
    })
  }

  RemoteGet = async (id: string) : Promise<IDeviceProfile> => {
    return new Promise<IDeviceProfile>((resolve, reject) => {
      const request = new GetAccessDeviceProfileRequest()
      request.setId(id)

      this.client.get(request, Metadata(this.store), (err, reply) => {
        if (err) {
          ErrorHandler(err)
          return reject(err)
        }
        const profile = reply.getProfile()
        if (!profile) {
          return reject(new Error('Invalid response'))
        }
        resolve(profile.toObject())
      })
    })
  }
}
