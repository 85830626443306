import { DataPointServiceClient, DataPointServicePromiseClient } from '@/api/datapoint_grpc_web_pb'
import { DataPoint, DataPointTableColumn, GetDataPointStatByProjectReply, GetDataPointStatByProjectRequest, ListByDeviceReply, ListByDeviceRequest, ListDataPointsRequest } from '@/api/datapoint_pb'
import { GRPC_ENDPOINT } from '@/const'
import { Metadata } from './session'
import { ErrorHandler } from './helper'
import { GetProjectId } from './organization'
import { TableOrder } from '@/common/common_pb'

export type DataPointListStat = GetDataPointStatByProjectReply.AsObject
export type IDataPoint = DataPoint.AsObject

interface IState {
  list: IDataPoint[],
  stat: DataPointListStat
  listByDevice: Map<string, IDataPoint[]>,
}

const state:IState = {
  list: [],
  stat: {
    count: 0
  },
  listByDevice: new Map<string, IDataPoint[]>()
}

const mutations = {
  fetch (state: IState, list: DataPoint.AsObject[]) {
    state.list = list
  },

  fetchStat (state: IState, stat: DataPointListStat) {
    state.stat = stat
  },

  fetchByDevice (state: IState, { id, data }:{id: string, data:IDataPoint[]}) {
    state.listByDevice.set(id, data)
  }
}

const getters = {
  list: (state:IState) => () => {
    return state.list
  },
  get: (state: IState) => (id: string) => {
    return state.list.filter(item => {
      return item.id === id
    })[0]
  },
  stat: (state: IState) => () => {
    return state.stat
  },

  listByDevice: (state: IState) => (id: string) => {
    return state.listByDevice.get(id) || []
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export class DatapointStore {
  private store:any
  private client: DataPointServicePromiseClient

  constructor (store:any) {
    this.store = store
    this.client = new DataPointServicePromiseClient(GRPC_ENDPOINT)
  }

  FetchStat = async (search?: string): Promise<void> => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      const request = new GetDataPointStatByProjectRequest()
      const projectId = await GetProjectId(this.store)
      request.setProjectId(projectId)
      if (search) { request.setSearch(search) }
      this.client.getStatByProject(request, Metadata(this.store)).then((reply) => {
        const result = reply.toObject()
        this.store.commit('datapoint/fetchStat', result as DataPointListStat)
        resolve()
      }).catch((err:any) => {
        if (err) {
          ErrorHandler(err)
          return reject(err)
        }
      })
    })
  }

  FetchByDevice = async (id: string) => {
    return new Promise<void>((resolve, reject) => {
      const request = new ListByDeviceRequest()
      request.setAccessDeviceId(id)
      this.client.listByDevice(request, Metadata(this.store)).then((reply:ListByDeviceReply) => {
        const data = reply.toObject().resultList
        this.store.commit('datapoint/fetchByDevice', { id: id, data: data })
        resolve()
      }).catch((err:any) => {
        ErrorHandler(err)
        return reject(err)
      })
    })
  }

  Fetch = (orderBy: DataPointTableColumn, order: TableOrder, limit: number, offset: number, search?: string) :Promise<void> => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      const projectId = GetProjectId(this.store)
      const request = new ListDataPointsRequest()

      console.log(`${projectId} ###`)
      request.setOrderBy(orderBy)
      request.setOrder(order)
      request.setProjectId(projectId)
      request.setOffset(offset)
      request.setLimit(limit)
      if (search) {
        console.log(`with search ${search}`)
        request.setSearch(search)
      }
      this.client.list(request, Metadata(this.store)).then((reply) => {
        const result = reply.toObject().dataPointsList
        console.log(result)
        this.store.commit('datapoint/fetch', result)
        resolve()
      }).catch(err => {
        if (err) {
          ErrorHandler(err)
          return reject(err)
        }
      })
    })
  }

  List = (): DataPoint.AsObject[] => {
    return this.store.getters['datapoint/list']()
  }

  Get = (id: string):DataPoint.AsObject => {
    return this.store.getters['datapoint/get'](id)
  }

  GetStat = () : DataPointListStat => {
    return this.store.getters['datapoint/stat']()
  }

  ListByDevice = (id: string) : IDataPoint[] => {
    return this.store.getters['datapoint/listByDevice'](id)
  }
}
