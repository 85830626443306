import { GatewayServicePromiseClient } from '@/api/gateway_grpc_web_pb'
import { CheckInRequest, CreateGatewayRequest, DeleteGatewayRequest, Gateway, ListGatewayRequest } from '@/api/gateway_pb'
import { GRPC_ENDPOINT } from '@/const'
import { Metadata } from './session'
import { ErrorHandler } from './helper'
import { getOrganizationId, GetProjectId } from './organization'

export type IGateway = Gateway.AsObject
export type IGatewaySystem = CheckInRequest.System.AsObject;

export type ICreateGatewayParams = Omit<Gateway.AsObject, 'id' | 'createdAt' | 'updatedAt'>
interface IState {
  list: IGateway[]
}

const state:IState = {
  list: []
}

const mutations = {
  fetch (state: IState, gateways: IGateway[]) {
    state.list = gateways
  },
  remove (state: IState, id: string) {
    console.log(`try to remove access gateway: ${id}`)
    for (let i = 0; i < state.list.length; i++) {
      if (state.list[i].id === id) {
        state.list.splice(i, 1)
        break
      }
    }
  }
}

const getters = {
  list: (state: IState) => () => {
    return state.list
  },
  get: (state: IState) => (id: string) => {
    return state.list.find(item => { return item.id === id })
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export class GatewayStore {
  private store:any
  private client: GatewayServicePromiseClient
  constructor (store: any) {
    this.store = store
    this.client = new GatewayServicePromiseClient(GRPC_ENDPOINT)
  }

  Fetch = async () => {
    try {
      const request = new ListGatewayRequest()
      request.setProjectId(GetProjectId(this.store))
      request.setOrganizationId(getOrganizationId(this.store))
      const reply = await this.client.list(request, Metadata(this.store))
      const result = reply.toObject().resultList

      this.store.commit('gateway/fetch', result)
      return Promise.resolve()
    } catch (err:any) {
      ErrorHandler(err)
      return Promise.reject(err)
    }
  }

  List = ():IGateway[] => {
    return this.store.getters['gateway/list']()
  }

  Create = async (data: ICreateGatewayParams):Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const request = new CreateGatewayRequest()

      const params = new Gateway()
      params.setName(data.name)
      params.setNote(data.note)
      params.setHardwareId(data.hardwareId)
      params.setProjectId(GetProjectId(this.store))
      params.setOrganizationId(getOrganizationId(this.store))
      params.setChallengeKey(data.challengeKey)
      params.setLocation(data.location)
      params.setShared(data.shared)
      request.setParams(params)

      this.client.create(request, Metadata(this.store)).then(result => {
        resolve(result.getId())
      }).catch(err => {
        ErrorHandler(err)
        reject(err)
      })
    })
  }

  Delete = async (id: string) : Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      const request = new DeleteGatewayRequest()
      request.setId(id)

      this.client.delete(request, Metadata(this.store)).then(() => {
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  }

  Get = (id: string) => {
    return this.store.getters['gateway/get'](id)
  }
}
