import { AccessProtocolServiceClient } from '@/api/access_protocol_grpc_web_pb'
import { AccessProtocol, AccessProtocolProperties, CreateAccessProtocolRequest, DeleteAccessProtocolRequest, ListAccessProtocolRequest, LoRaWANAccessProtocolProperties, MqttAccessProtocolProperties } from '@/api/access_protocol_pb'
import { GRPC_ENDPOINT } from '@/const'
import { Metadata } from '@/store/session'
import { GetProjectId } from './organization'
import { Store } from 'vuex'
import { CategoryMap } from '@/common/common_pb'
import { handleError } from 'vue'
import { ErrorHandler } from './helper'

export type IAccessProtocol = AccessProtocol.AsObject
export type ICreateProtocolParams = CreateAccessProtocolRequest.AsObject
export interface ILoRaWANProtocolParams extends LoRaWANAccessProtocolProperties.AsObject {
  name: string
}
export type ICreateProtocolMqttParams = MqttAccessProtocolProperties.AsObject

interface IAccessProtocolState {
  list: AccessProtocol.AsObject[]
}

const state:IAccessProtocolState = {
  list: []
}

const mutations = {
  fetch (state: IAccessProtocolState, gateways: AccessProtocol.AsObject[]) {
    state.list = gateways
  },
  remove (state: IAccessProtocolState, id: string) {
    console.log(`try to remove access gateway: ${id}`)
    for (let i = 0; i < state.list.length; i++) {
      if (state.list[i].id === id) {
        state.list.splice(i, 1)
        break
      }
    }
  }
}

const getters = {
  list: (state: IAccessProtocolState) => () => {
    return state.list
  }
}
const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export class AccessProtocolStore {
  private client: AccessProtocolServiceClient
  private store
  constructor (store:Store<any>) {
    this.store = store
    this.client = new AccessProtocolServiceClient(GRPC_ENDPOINT)
  }

  Create = async (data: CreateAccessProtocolRequest.AsObject):Promise<string> => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string>(async (resolve, reject) => {
      const projectId = await GetProjectId(this.store)
      const request = new CreateAccessProtocolRequest()
      request.setName(data.name)
      request.setGatewayId(data.gatewayId)
      request.setCategory(data.category)
      request.setNote(data.note)
      request.setProjectId(projectId)

      const properties = new AccessProtocolProperties()
      switch (data.category) {
        case CategoryMap.CATEGORY_MQTT:
        {
          const mqttdata = data.properties!.mqtt
          if (mqttdata) {
            const mqtt = new MqttAccessProtocolProperties()
            mqtt.setBroker(mqttdata.broker)
            mqtt.setPort(mqttdata.port)
            mqtt.setUsername(mqttdata.username)
            mqtt.setPassword(mqttdata.password)
            mqtt.setEnableSsl(mqttdata.enableSsl)
            mqtt.setProtocol(mqttdata.protocol)
            mqtt.setTlsVersion(mqttdata.tlsVersion)
            mqtt.setCaCert(mqttdata.caCert)
            mqtt.setTlsCert(mqttdata.tlsCert)
            mqtt.setTlsKey(mqttdata.tlsKey)
            mqtt.setCleanSession(mqttdata.cleanSession)
            mqtt.setKeepAlive(mqttdata.keepAlive)
            mqtt.setClientId(mqttdata.clientId)
            mqtt.setQos(mqttdata.qos)
            properties.setMqtt(mqtt)
          }
          break
        }
        case CategoryMap.CATEGORY_LORAWAN:break
        default: break
      }
      request.setProperties(properties)
      try {
        this.client.create(request, Metadata(this.store), (err, reply) => {
          if (err) {
            ErrorHandler(err)
            return reject(err)
          }
          const result = reply.toObject()
          resolve(result.id)
        })
      } catch (err: any) {
        reject(err)
      }
    })
  }

  Fetch = async () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      const request = new ListAccessProtocolRequest()
      const projectId = await GetProjectId(this.store)
      request.setProjectId(projectId)
      this.client.list(request, Metadata(this.store), (err, reply) => {
        if (err) {
          ErrorHandler(err)
          return reject(err)
        }

        const result = reply.toObject().resultList
        this.store.commit('accessProtocol/fetch', result)

        resolve()
      })
    })
  }

  List = ():AccessProtocol.AsObject[] => {
    return this.store.getters['accessProtocol/list']()
  }

  Delete = async (id: string) => {
    return new Promise<void>((resolve, reject) => {
      const request = new DeleteAccessProtocolRequest()
      request.setId(id)
      this.client.remove(request, Metadata(this.store), (err, reply) => {
        if (err) {
          ErrorHandler(err)
          return reject(err)
        }
        this.store.commit('accessProtocol/remove', id)
        resolve()
      })
    })
  }

  Get = (id: string): IAccessProtocol | undefined => {
    const list = this.List()
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === id) {
        return list[i]
      }
    }
  }
}
