import { SystemServiceClient } from '@/api/system_grpc_web_pb'
import { IpIface, ListIpIfaceReply, ListIpIfaceRequest } from '@/api/system_pb'
import { GRPC_ENDPOINT } from '@/const'
import { Metadata } from './session'
import { RpcError } from 'grpc-web'
import { ErrorHandler } from './helper'

interface ISystemState {
    IpIfaces: Array<IpIface.AsObject>
}

const state:ISystemState = {
  IpIfaces: []
}

const getters = {
  IpIfaces: (state:ISystemState) => () => {
    return state.IpIfaces
  }
}

const actions = {}

const mutations = {
  IpIfaces: (state:ISystemState, ipIfaces: Array<IpIface.AsObject>) => {
    state.IpIfaces = ipIfaces
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export class SystemStore {
    private store:any
    private client: SystemServiceClient
    constructor (store: any) {
      this.store = store
      this.client = new SystemServiceClient(GRPC_ENDPOINT)
    }

    FetchIpIfaces = async ():Promise<void> => {
      return new Promise<void>((resolve, reject) => {
        const request = new ListIpIfaceRequest()
        try {
          this.client.listIpv4Iface(request, Metadata(this.store), (err: RpcError, reply:ListIpIfaceReply) => {
            if (err) {
              ErrorHandler(err)
              return reject(err)
            }

            const result = reply.toObject().resultList
            this.store.commit('system/IpIfaces', result)
            resolve()
          })
        } catch (err:any) {
          reject(err)
        }
      })
    }

    GetIpIfaces = (): IpIface.AsObject[] => {
      return this.store.getters['system/IpIfaces']()
    }
}
