<template>
  <ElContainer id="login-container" >
    <ElAside id="login-aside">
      <ElRow>
        <ElCol :span="18" :offset="3">
          <ElForm label-position="right" label-width="200px" :model="loginForm">
            <ElFormItem label="Username"  >
              <ElInput type="text" v-model="loginForm.email" />
            </ElFormItem>
            <ElFormItem label="Password">
              <ElInput type="password" show-password v-model="loginForm.password" />
            </ElFormItem>
            <ElFormItem label=" ">
              <ElButton type="primary" size="large" @click="Submit" v-loading="loading">Login</ElButton>
            </ElFormItem>
          </ElForm>
        </ElCol>
    </ElRow>
    </ElAside>
    <ElMain>
    </ElMain>
  </ElContainer>
</template>
<script setup lang="ts">
import { AuthenticationRequest } from '@/api/authentication_pb'
import { ElContainer, ElForm, ElFormItem, ElInput, ElMessage } from 'element-plus'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { SessionStore } from '@/store/session'
const loginForm = ref<AuthenticationRequest.AsObject>({
  email: '',
  password: ''
})
const store = useStore()
const router = useRouter()
const loading = ref<boolean>(false)
const session = new SessionStore(store)
const Submit = async () => {
  loading.value = true
  session.Login(loginForm.value).then(() => {
    router.push({ name: 'Organization', replace: true })
  }).catch(err => {
    ElMessage.error(err.message)
  }).finally(() => {
    loading.value = false
  })
}

</script>
<style lang="scss">
#login-container {
  flex: 1;
  height: 100%;
  width: 100%;
}

#login-aside {
  width: 800px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>
