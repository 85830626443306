// source: common/common.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.common.CategoryMap', null, global);
goog.exportSymbol('proto.common.DataObjectTypeEnum', null, global);
goog.exportSymbol('proto.common.DataValueType', null, global);
goog.exportSymbol('proto.common.EngineeringUnits', null, global);
goog.exportSymbol('proto.common.MacVersion', null, global);
goog.exportSymbol('proto.common.Modulation', null, global);
goog.exportSymbol('proto.common.RegParamsRevision', null, global);
goog.exportSymbol('proto.common.Region', null, global);
goog.exportSymbol('proto.common.TableOrder', null, global);
goog.exportSymbol('proto.common.UserRole', null, global);
/**
 * @enum {number}
 */
proto.common.CategoryMap = {
  CATEGORY_LORAWAN: 0,
  CATEGORY_MQTT: 1,
  CATEGORY_BACNET_IP: 2,
  CATEGORY_BACNET_MSTP: 3,
  CATEGORY_BACNET_SC: 4,
  CATEGORY_MODBUS_RS485: 5,
  CATEGORY_MODBUS_TCP: 6
};

/**
 * @enum {number}
 */
proto.common.UserRole = {
  ADMIN: 0,
  MAINTAINER: 1,
  GUEST: 2
};

/**
 * @enum {number}
 */
proto.common.Modulation = {
  LORA: 0,
  FSK: 1,
  LR_FHSS: 2
};

/**
 * @enum {number}
 */
proto.common.Region = {
  EU868: 0,
  US915: 2,
  CN779: 3,
  EU433: 4,
  AU915: 5,
  CN470: 6,
  AS923: 7,
  AS923_2: 12,
  AS923_3: 13,
  AS923_4: 14,
  KR920: 8,
  IN865: 9,
  RU864: 10,
  ISM2400: 11
};

/**
 * @enum {number}
 */
proto.common.MacVersion = {
  LORAWAN_1_0_0: 0,
  LORAWAN_1_0_1: 1,
  LORAWAN_1_0_2: 2,
  LORAWAN_1_0_3: 3,
  LORAWAN_1_0_4: 4,
  LORAWAN_1_1_0: 5
};

/**
 * @enum {number}
 */
proto.common.RegParamsRevision = {
  A: 0,
  B: 1,
  RP002_1_0_0: 2,
  RP002_1_0_1: 3,
  RP002_1_0_2: 4,
  RP002_1_0_3: 5,
  RP002_1_0_4: 6
};

/**
 * @enum {number}
 */
proto.common.DataObjectTypeEnum = {
  ANALOG: 0,
  BINARY: 1,
  MULTI_STATE: 2,
  OCTET_STRING: 3
};

/**
 * @enum {number}
 */
proto.common.DataValueType = {
  UINT16: 0,
  INT16: 1,
  UINT32_ABCD: 2,
  INT32_ABCD: 3,
  UINT32_CDAB: 4,
  INT32_CDAB: 5,
  FLOAT_ABCD: 6,
  FLOAT_CDAB: 7
};

/**
 * @enum {number}
 */
proto.common.TableOrder = {
  ASC: 0,
  DESC: 1
};

/**
 * @enum {number}
 */
proto.common.EngineeringUnits = {
  SQUARE_METERS: 0,
  SQUARE_FEET: 1,
  MILLIAMPERES: 2,
  AMPERES: 3,
  OHMS: 4,
  VOLTS: 5,
  KILOVOLTS: 6,
  MEGAVOLTS: 7,
  VOLT_AMPERES: 8,
  KILOVOLT_AMPERES: 9,
  MEGAVOLT_AMPERES: 10,
  VOLT_AMPERES_REACTIVE: 11,
  KILOVOLT_AMPERES_REACTIVE: 12,
  MEGAVOLT_AMPERES_REACTIVE: 13,
  DEGREES_PHASE: 14,
  POWER_FACTOR: 15,
  JOULES: 16,
  KILOJOULES: 17,
  WATT_HOURS: 18,
  KILOWATT_HOURS: 19,
  BTUS: 20,
  THERMS: 21,
  TON_HOURS: 22,
  JOULES_PER_KILOGRAM_DRY_AIR: 23,
  BTUS_PER_POUND_DRY_AIR: 24,
  CYCLES_PER_HOUR: 25,
  CYCLES_PER_MINUTE: 26,
  HERTZ: 27,
  GRAMS_OF_WATER_PER_KILOGRAM_DRY_AIR: 28,
  PERCENT_RELATIVE_HUMIDITY: 29,
  MILLIMETERS: 30,
  METERS: 31,
  INCHES: 32,
  FEET: 33,
  WATTS_PER_SQUARE_FOOT: 34,
  WATTS_PER_SQUARE_METER: 35,
  LUMENS: 36,
  LUXES: 37,
  FOOT_CANDLES: 38,
  KILOGRAMS: 39,
  POUNDS_MASS: 40,
  TONS: 41,
  KILOGRAMS_PER_SECOND: 42,
  KILOGRAMS_PER_MINUTE: 43,
  KILOGRAMS_PER_HOUR: 44,
  POUNDS_MASS_PER_MINUTE: 45,
  POUNDS_MASS_PER_HOUR: 46,
  WATTS: 47,
  KILOWATTS: 48,
  MEGAWATTS: 49,
  BTUS_PER_HOUR: 50,
  HORSEPOWER: 51,
  TONS_REFRIGERATION: 52,
  PASCALS: 53,
  KILOPASCALS: 54,
  BARS: 55,
  POUNDS_FORCE_PER_SQUARE_INCH: 56,
  CENTIMETERS_OF_WATER: 57,
  INCHES_OF_WATER: 58,
  MILLIMETERS_OF_MERCURY: 59,
  CENTIMETERS_OF_MERCURY: 60,
  INCHES_OF_MERCURY: 61,
  DEGREES_CELSIUS: 62,
  DEGREES_KELVIN: 63,
  DEGREES_FAHRENHEIT: 64,
  DEGREE_DAYS_CELSIUS: 65,
  DEGREE_DAYS_FAHRENHEIT: 66,
  YEARS: 67,
  MONTHS: 68,
  WEEKS: 69,
  DAYS: 70,
  HOURS: 71,
  MINUTES: 72,
  SECONDS: 73,
  METERS_PER_SECOND: 74,
  KILOMETERS_PER_HOUR: 75,
  FEET_PER_SECOND: 76,
  FEET_PER_MINUTE: 77,
  MILES_PER_HOUR: 78,
  CUBIC_FEET: 79,
  CUBIC_METERS: 80,
  IMPERIAL_GALLONS: 81,
  LITERS: 82,
  US_GALLONS: 83,
  CUBIC_FEET_PER_MINUTE: 84,
  CUBIC_METERS_PER_SECOND: 85,
  IMPERIAL_GALLONS_PER_MINUTE: 86,
  LITERS_PER_SECOND: 87,
  LITERS_PER_MINUTE: 88,
  LITERS_PER_HOUR: 89,
  US_GALLONS_PER_MINUTE: 90,
  DEGREES_ANGULAR: 91,
  DEGREES_CELSIUS_PER_HOUR: 92,
  DEGREES_CELSIUS_PER_MINUTE: 93,
  DEGREES_FAHRENHEIT_PER_HOUR: 94,
  DEGREES_FAHRENHEIT_PER_MINUTE: 95,
  NO_UNITS: 96,
  PARTS_PER_MILLION: 97,
  PARTS_PER_BILLION: 98,
  PERCENT: 99,
  PERCENT_PER_SECOND: 100,
  PER_MINUTE: 101,
  PER_SECOND: 102,
  PSI_PER_DEGREE_FAHRENHEIT: 103,
  RADIANS: 104,
  CURRENCY_1: 105,
  CURRENCY_2: 106,
  CURRENCY_3: 107,
  CURRENCY_4: 108,
  CURRENCY_5: 109,
  CURRENCY_6: 110,
  CURRENCY_7: 111,
  CURRENCY_8: 112,
  CURRENCY_9: 113,
  CURRENCY_10: 114,
  SQUARE_INCHES: 115,
  SQUARE_CENTIMETERS: 116,
  BTUS_PER_POUND: 117,
  CENTIMETERS: 118,
  POUNDS_MASS_PER_SECOND: 119,
  DELTA_DEGREES_FAHRENHEIT: 120,
  DELTA_DEGREES_KELVIN: 121,
  KILOHMS: 122,
  MEGOHMS: 123,
  MILLIVOLTS: 124,
  KILOJOULES_PER_KILOGRAM: 125,
  MEGAJOULES: 126,
  JOULES_PER_DEGREE_KELVIN: 127,
  JOULES_PER_KILOGRAM_DEGREE_KELVIN: 128,
  KILOHERTZ: 129,
  MEGAHERTZ: 130,
  PER_HOUR: 131,
  MILLIWATTS: 132,
  HECTOPASCALS: 133,
  MILLIBARS: 134,
  CUBIC_METERS_PER_HOUR: 135,
  KILOWATT_HOURS_PER_SQUARE_METER: 136,
  KILOWATT_HOURS_PER_SQUARE_FOOT: 137,
  MEGAJOULES_PER_SQUARE_METER: 138,
  WATTS_PER_SQUARE_METER_DEGREE_KELVIN: 140,
  MEGAJOULES_PER_SQUARE_FOOT: 141,
  CUBIC_FEET_PER_SECOND: 142,
  PERCENT_OBSCURATION_PER_FOOT: 143,
  PERCENT_OBSCURATION_PER_METER: 144,
  MILLIOHMS: 145,
  MEGAWATT_HOURS: 146,
  KILO_BTUS: 147,
  MEGA_BTUS: 148,
  KILOJOULES_PER_KILOGRAM_DRY_AIR: 149,
  MEGAJOULES_PER_KILOGRAM_DRY_AIR: 150,
  KILOJOULES_PER_DEGREE_KELVIN: 151,
  MEGAJOULES_PER_DEGREE_KELVIN: 152,
  NEWTON: 153,
  GRAMS_PER_SECOND: 154,
  GRAMS_PER_MINUTE: 155,
  TONS_PER_HOUR: 156,
  KILO_BTUS_PER_HOUR: 157,
  HUNDREDTHS_SECONDS: 158,
  MILLISECONDS: 159,
  NEWTON_METERS: 160,
  MILLIMETERS_PER_SECOND: 161,
  MILLIMETERS_PER_MINUTE: 162,
  METERS_PER_MINUTE: 163,
  METERS_PER_HOUR: 164,
  CUBIC_METERS_PER_MINUTE: 165,
  METERS_PER_SECOND_PER_SECOND: 166,
  AMPERES_PER_METER: 167,
  AMPERES_PER_SQUARE_METER: 168,
  AMPERE_SQUARE_METERS: 169,
  FARADS: 170,
  HENRYS: 171,
  OHM_METERS: 172,
  SIEMENS: 173,
  SIEMENS_PER_METER: 174,
  TESLAS: 175,
  VOLTS_PER_DEGREE_KELVIN: 176,
  VOLTS_PER_METER: 177,
  WEBERS: 178,
  CANDELAS: 179,
  CANDELAS_PER_SQUARE_METER: 180,
  DEGREES_KELVIN_PER_HOUR: 181,
  DEGREES_KELVIN_PER_MINUTE: 182,
  JOULE_SECONDS: 183,
  RADIANS_PER_SECOND: 184,
  SQUARE_METERS_PER_NEWTON: 185,
  KILOGRAMS_PER_CUBIC_METER: 186,
  NEWTON_SECONDS: 187,
  NEWTONS_PER_METER: 188,
  WATTS_PER_METER_PER_DEGREE_KELVIN: 189,
  MICRO_SIEMENS: 190,
  CUBIC_FEET_PER_HOUR: 191,
  US_GALLONS_PER_HOUR: 192,
  KILOMETERS: 193,
  MICROMETERS: 194,
  GRAMS: 195,
  MILLIGRAMS: 196,
  MILLILITERS: 197,
  MILLILITERS_PER_SECOND: 198,
  DECIBELS: 199,
  DECIBELS_MILLIVOLT: 200,
  DECIBELS_VOLT: 201,
  MILLISIEMENS: 202,
  WATT_HOURS_REACTIVE: 203,
  KILOWATT_HOURS_REACTIVE: 204,
  MEGAWATT_HOURS_REACTIVE: 205,
  MILLIMETERS_OF_WATER: 206,
  PER_MILLE: 207,
  GRAMS_PER_GRAM: 208,
  KILOGRAMS_PER_KILOGRAM: 209,
  GRAMS_PER_KILOGRAM: 210,
  MILLIGRAMS_PER_GRAM: 211,
  MILLIGRAMS_PER_KILOGRAM: 212,
  GRAMS_PER_MILLILITER: 213,
  GRAMS_PER_LITER: 214,
  MILLIGRAMS_PER_LITER: 215,
  MICROGRAMS_PER_LITER: 216,
  GRAMS_PER_CUBIC_METER: 217,
  MILLIGRAMS_PER_CUBIC_METER: 218,
  MICROGRAMS_PER_CUBIC_METER: 219,
  NANOGRAMS_PER_CUBIC_METER: 220,
  GRAMS_PER_CUBIC_CENTIMETER: 221,
  BECQUERELS: 222,
  KILOBECQUERELS: 223,
  MEGABECQUERELS: 224,
  GRAY: 225,
  MILLIGRAY: 226,
  MICROGRAY: 227,
  SIEVERTS: 228,
  MILLISIEVERTS: 229,
  MICROSIEVERTS: 230,
  MICROSIEVERTS_PER_HOUR: 231,
  DISS_INTEGRATIONS_PER_MINUTE: 232,
  NEPER: 233,
  NEPER_PER_SECOND: 234,
  PERCENT_PER_MINUTE: 235,
  PERCENT_PER_HOUR: 236,
  CHARGE_DENSITY: 237,
  SURFACE_CHARGE_DENSITY: 238,
  CURRENT_DENSITY: 239,
  MAGNETIC_FIELD_STRENGTH: 240,
  AMOUNT_OF_SUBSTANCE_CONCENTRATION: 241,
  MASS_CONCENTRATION: 242,
  LUMINANCE: 243,
  MOLAR_ENERGY: 244,
  MOLAR_MASS: 245,
  MOLAR_VOLUME: 246,
  SIESMIC_INTENSITY: 247,
  SIESMIC_VELOCITY: 248,
  ELECTRIC_CHARGE: 249,
  ELECTRIC_CURRENT: 250,
  ELECTRIC_VOLTAGE: 251,
  ELECTROMOTIVE_FORCE: 252,
  CAPACITANCE: 253,
  INDUCTANCE: 254,
  RESISTANCE: 255
};

goog.object.extend(exports, proto.common);
