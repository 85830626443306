/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.19.1
// source: api/access_protocol.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var common_common_pb = require('../common/common_pb.js')
const proto = {};
proto.api = require('./access_protocol_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.AccessProtocolServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.AccessProtocolServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.ListAccessProtocolRequest,
 *   !proto.api.ListAccessProtocolReply>}
 */
const methodDescriptor_AccessProtocolService_List = new grpc.web.MethodDescriptor(
  '/api.AccessProtocolService/List',
  grpc.web.MethodType.UNARY,
  proto.api.ListAccessProtocolRequest,
  proto.api.ListAccessProtocolReply,
  /**
   * @param {!proto.api.ListAccessProtocolRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.ListAccessProtocolReply.deserializeBinary
);


/**
 * @param {!proto.api.ListAccessProtocolRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.ListAccessProtocolReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.ListAccessProtocolReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.AccessProtocolServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.AccessProtocolService/List',
      request,
      metadata || {},
      methodDescriptor_AccessProtocolService_List,
      callback);
};


/**
 * @param {!proto.api.ListAccessProtocolRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.ListAccessProtocolReply>}
 *     Promise that resolves to the response
 */
proto.api.AccessProtocolServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.AccessProtocolService/List',
      request,
      metadata || {},
      methodDescriptor_AccessProtocolService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.CreateAccessProtocolRequest,
 *   !proto.api.CreateAccessProtocolReply>}
 */
const methodDescriptor_AccessProtocolService_Create = new grpc.web.MethodDescriptor(
  '/api.AccessProtocolService/Create',
  grpc.web.MethodType.UNARY,
  proto.api.CreateAccessProtocolRequest,
  proto.api.CreateAccessProtocolReply,
  /**
   * @param {!proto.api.CreateAccessProtocolRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.CreateAccessProtocolReply.deserializeBinary
);


/**
 * @param {!proto.api.CreateAccessProtocolRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.CreateAccessProtocolReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.CreateAccessProtocolReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.AccessProtocolServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.AccessProtocolService/Create',
      request,
      metadata || {},
      methodDescriptor_AccessProtocolService_Create,
      callback);
};


/**
 * @param {!proto.api.CreateAccessProtocolRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.CreateAccessProtocolReply>}
 *     Promise that resolves to the response
 */
proto.api.AccessProtocolServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.AccessProtocolService/Create',
      request,
      metadata || {},
      methodDescriptor_AccessProtocolService_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.DeleteAccessProtocolRequest,
 *   !proto.api.DeleteAccessProtocolReply>}
 */
const methodDescriptor_AccessProtocolService_Remove = new grpc.web.MethodDescriptor(
  '/api.AccessProtocolService/Remove',
  grpc.web.MethodType.UNARY,
  proto.api.DeleteAccessProtocolRequest,
  proto.api.DeleteAccessProtocolReply,
  /**
   * @param {!proto.api.DeleteAccessProtocolRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.DeleteAccessProtocolReply.deserializeBinary
);


/**
 * @param {!proto.api.DeleteAccessProtocolRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.DeleteAccessProtocolReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.DeleteAccessProtocolReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.AccessProtocolServiceClient.prototype.remove =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.AccessProtocolService/Remove',
      request,
      metadata || {},
      methodDescriptor_AccessProtocolService_Remove,
      callback);
};


/**
 * @param {!proto.api.DeleteAccessProtocolRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.DeleteAccessProtocolReply>}
 *     Promise that resolves to the response
 */
proto.api.AccessProtocolServicePromiseClient.prototype.remove =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.AccessProtocolService/Remove',
      request,
      metadata || {},
      methodDescriptor_AccessProtocolService_Remove);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.GetAccessProtocolRequest,
 *   !proto.api.GetAccessProtocolReply>}
 */
const methodDescriptor_AccessProtocolService_Get = new grpc.web.MethodDescriptor(
  '/api.AccessProtocolService/Get',
  grpc.web.MethodType.UNARY,
  proto.api.GetAccessProtocolRequest,
  proto.api.GetAccessProtocolReply,
  /**
   * @param {!proto.api.GetAccessProtocolRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.GetAccessProtocolReply.deserializeBinary
);


/**
 * @param {!proto.api.GetAccessProtocolRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.GetAccessProtocolReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.GetAccessProtocolReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.AccessProtocolServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.AccessProtocolService/Get',
      request,
      metadata || {},
      methodDescriptor_AccessProtocolService_Get,
      callback);
};


/**
 * @param {!proto.api.GetAccessProtocolRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.GetAccessProtocolReply>}
 *     Promise that resolves to the response
 */
proto.api.AccessProtocolServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.AccessProtocolService/Get',
      request,
      metadata || {},
      methodDescriptor_AccessProtocolService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.UpdateAccessProtocolRequest,
 *   !proto.api.UpdateAccessProtocolReply>}
 */
const methodDescriptor_AccessProtocolService_Update = new grpc.web.MethodDescriptor(
  '/api.AccessProtocolService/Update',
  grpc.web.MethodType.UNARY,
  proto.api.UpdateAccessProtocolRequest,
  proto.api.UpdateAccessProtocolReply,
  /**
   * @param {!proto.api.UpdateAccessProtocolRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.UpdateAccessProtocolReply.deserializeBinary
);


/**
 * @param {!proto.api.UpdateAccessProtocolRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.UpdateAccessProtocolReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.UpdateAccessProtocolReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.AccessProtocolServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.AccessProtocolService/Update',
      request,
      metadata || {},
      methodDescriptor_AccessProtocolService_Update,
      callback);
};


/**
 * @param {!proto.api.UpdateAccessProtocolRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.UpdateAccessProtocolReply>}
 *     Promise that resolves to the response
 */
proto.api.AccessProtocolServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.AccessProtocolService/Update',
      request,
      metadata || {},
      methodDescriptor_AccessProtocolService_Update);
};


module.exports = proto.api;

