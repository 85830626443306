/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.19.1
// source: api/access_device.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var common_common_pb = require('../common/common_pb.js')

var api_access_device_profile_pb = require('../api/access_device_profile_pb.js')
const proto = {};
proto.api = require('./access_device_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.AccessDeviceSerivceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.AccessDeviceSerivcePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.CreateAccessDeviceRequest,
 *   !proto.api.CreateAccessDeviceReply>}
 */
const methodDescriptor_AccessDeviceSerivce_Create = new grpc.web.MethodDescriptor(
  '/api.AccessDeviceSerivce/Create',
  grpc.web.MethodType.UNARY,
  proto.api.CreateAccessDeviceRequest,
  proto.api.CreateAccessDeviceReply,
  /**
   * @param {!proto.api.CreateAccessDeviceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.CreateAccessDeviceReply.deserializeBinary
);


/**
 * @param {!proto.api.CreateAccessDeviceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.CreateAccessDeviceReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.CreateAccessDeviceReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.AccessDeviceSerivceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.AccessDeviceSerivce/Create',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceSerivce_Create,
      callback);
};


/**
 * @param {!proto.api.CreateAccessDeviceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.CreateAccessDeviceReply>}
 *     Promise that resolves to the response
 */
proto.api.AccessDeviceSerivcePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.AccessDeviceSerivce/Create',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceSerivce_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.GetAccessDeviceRequest,
 *   !proto.api.GetAccessDeviceReply>}
 */
const methodDescriptor_AccessDeviceSerivce_Get = new grpc.web.MethodDescriptor(
  '/api.AccessDeviceSerivce/Get',
  grpc.web.MethodType.UNARY,
  proto.api.GetAccessDeviceRequest,
  proto.api.GetAccessDeviceReply,
  /**
   * @param {!proto.api.GetAccessDeviceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.GetAccessDeviceReply.deserializeBinary
);


/**
 * @param {!proto.api.GetAccessDeviceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.GetAccessDeviceReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.GetAccessDeviceReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.AccessDeviceSerivceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.AccessDeviceSerivce/Get',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceSerivce_Get,
      callback);
};


/**
 * @param {!proto.api.GetAccessDeviceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.GetAccessDeviceReply>}
 *     Promise that resolves to the response
 */
proto.api.AccessDeviceSerivcePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.AccessDeviceSerivce/Get',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceSerivce_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.RemoveAccessDeviceRequest,
 *   !proto.api.RemoveAccessDeviceReply>}
 */
const methodDescriptor_AccessDeviceSerivce_Remove = new grpc.web.MethodDescriptor(
  '/api.AccessDeviceSerivce/Remove',
  grpc.web.MethodType.UNARY,
  proto.api.RemoveAccessDeviceRequest,
  proto.api.RemoveAccessDeviceReply,
  /**
   * @param {!proto.api.RemoveAccessDeviceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.RemoveAccessDeviceReply.deserializeBinary
);


/**
 * @param {!proto.api.RemoveAccessDeviceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.RemoveAccessDeviceReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.RemoveAccessDeviceReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.AccessDeviceSerivceClient.prototype.remove =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.AccessDeviceSerivce/Remove',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceSerivce_Remove,
      callback);
};


/**
 * @param {!proto.api.RemoveAccessDeviceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.RemoveAccessDeviceReply>}
 *     Promise that resolves to the response
 */
proto.api.AccessDeviceSerivcePromiseClient.prototype.remove =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.AccessDeviceSerivce/Remove',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceSerivce_Remove);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.ListAccessDeviceRequest,
 *   !proto.api.ListAccessDeviceReply>}
 */
const methodDescriptor_AccessDeviceSerivce_List = new grpc.web.MethodDescriptor(
  '/api.AccessDeviceSerivce/List',
  grpc.web.MethodType.UNARY,
  proto.api.ListAccessDeviceRequest,
  proto.api.ListAccessDeviceReply,
  /**
   * @param {!proto.api.ListAccessDeviceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.ListAccessDeviceReply.deserializeBinary
);


/**
 * @param {!proto.api.ListAccessDeviceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.ListAccessDeviceReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.ListAccessDeviceReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.AccessDeviceSerivceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.AccessDeviceSerivce/List',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceSerivce_List,
      callback);
};


/**
 * @param {!proto.api.ListAccessDeviceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.ListAccessDeviceReply>}
 *     Promise that resolves to the response
 */
proto.api.AccessDeviceSerivcePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.AccessDeviceSerivce/List',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceSerivce_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.ListAccessDeviceByProjectRequest,
 *   !proto.api.ListAccessDeviceByProjectReply>}
 */
const methodDescriptor_AccessDeviceSerivce_ListByProject = new grpc.web.MethodDescriptor(
  '/api.AccessDeviceSerivce/ListByProject',
  grpc.web.MethodType.UNARY,
  proto.api.ListAccessDeviceByProjectRequest,
  proto.api.ListAccessDeviceByProjectReply,
  /**
   * @param {!proto.api.ListAccessDeviceByProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.ListAccessDeviceByProjectReply.deserializeBinary
);


/**
 * @param {!proto.api.ListAccessDeviceByProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.ListAccessDeviceByProjectReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.ListAccessDeviceByProjectReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.AccessDeviceSerivceClient.prototype.listByProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.AccessDeviceSerivce/ListByProject',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceSerivce_ListByProject,
      callback);
};


/**
 * @param {!proto.api.ListAccessDeviceByProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.ListAccessDeviceByProjectReply>}
 *     Promise that resolves to the response
 */
proto.api.AccessDeviceSerivcePromiseClient.prototype.listByProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.AccessDeviceSerivce/ListByProject',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceSerivce_ListByProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.ListAccessByProtocolRequest,
 *   !proto.api.ListAccessByProtocolReply>}
 */
const methodDescriptor_AccessDeviceSerivce_ListByProtocol = new grpc.web.MethodDescriptor(
  '/api.AccessDeviceSerivce/ListByProtocol',
  grpc.web.MethodType.UNARY,
  proto.api.ListAccessByProtocolRequest,
  proto.api.ListAccessByProtocolReply,
  /**
   * @param {!proto.api.ListAccessByProtocolRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.ListAccessByProtocolReply.deserializeBinary
);


/**
 * @param {!proto.api.ListAccessByProtocolRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.ListAccessByProtocolReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.ListAccessByProtocolReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.AccessDeviceSerivceClient.prototype.listByProtocol =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.AccessDeviceSerivce/ListByProtocol',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceSerivce_ListByProtocol,
      callback);
};


/**
 * @param {!proto.api.ListAccessByProtocolRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.ListAccessByProtocolReply>}
 *     Promise that resolves to the response
 */
proto.api.AccessDeviceSerivcePromiseClient.prototype.listByProtocol =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.AccessDeviceSerivce/ListByProtocol',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceSerivce_ListByProtocol);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.UpdateAccessDeviceRequest,
 *   !proto.api.UpdateAccessDeviceReply>}
 */
const methodDescriptor_AccessDeviceSerivce_Update = new grpc.web.MethodDescriptor(
  '/api.AccessDeviceSerivce/Update',
  grpc.web.MethodType.UNARY,
  proto.api.UpdateAccessDeviceRequest,
  proto.api.UpdateAccessDeviceReply,
  /**
   * @param {!proto.api.UpdateAccessDeviceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.UpdateAccessDeviceReply.deserializeBinary
);


/**
 * @param {!proto.api.UpdateAccessDeviceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.UpdateAccessDeviceReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.UpdateAccessDeviceReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.AccessDeviceSerivceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.AccessDeviceSerivce/Update',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceSerivce_Update,
      callback);
};


/**
 * @param {!proto.api.UpdateAccessDeviceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.UpdateAccessDeviceReply>}
 *     Promise that resolves to the response
 */
proto.api.AccessDeviceSerivcePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.AccessDeviceSerivce/Update',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceSerivce_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.GetDeviceStatByProjectRequest,
 *   !proto.api.GetDeviceStatByProjectReply>}
 */
const methodDescriptor_AccessDeviceSerivce_GetStatByProject = new grpc.web.MethodDescriptor(
  '/api.AccessDeviceSerivce/GetStatByProject',
  grpc.web.MethodType.UNARY,
  proto.api.GetDeviceStatByProjectRequest,
  proto.api.GetDeviceStatByProjectReply,
  /**
   * @param {!proto.api.GetDeviceStatByProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.GetDeviceStatByProjectReply.deserializeBinary
);


/**
 * @param {!proto.api.GetDeviceStatByProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.GetDeviceStatByProjectReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.GetDeviceStatByProjectReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.AccessDeviceSerivceClient.prototype.getStatByProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.AccessDeviceSerivce/GetStatByProject',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceSerivce_GetStatByProject,
      callback);
};


/**
 * @param {!proto.api.GetDeviceStatByProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.GetDeviceStatByProjectReply>}
 *     Promise that resolves to the response
 */
proto.api.AccessDeviceSerivcePromiseClient.prototype.getStatByProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.AccessDeviceSerivce/GetStatByProject',
      request,
      metadata || {},
      methodDescriptor_AccessDeviceSerivce_GetStatByProject);
};


module.exports = proto.api;

