import { AuthenticationServiceClient } from '@/api/authentication_grpc_web_pb'
import { AuthenticationRequest } from '@/api/authentication_pb'
import { GRPC_ENDPOINT } from '@/const'
import * as grpc from 'grpc-web'
import { Commit, Store } from 'vuex'

interface SessionState {
  metaData: grpc.Metadata
}
const state = {
  metaData: {
    authorization: localStorage.getItem('token'),
    'grpc-timeout': '5000m',
    'Access-Control-Max-Age': 86400
  }
}

const mutations = {
  updateToken (state: SessionState, token: string) {
    state.metaData.authorization = 'Bearer ' + token
    localStorage.setItem('token', 'Bearer ' + token)
  },

  clearToken (state: SessionState) {
    localStorage.removeItem('token')
    delete state.metaData.authorization
  }
}

const actions = {
  async login ({ commit }: { commit: Commit }, data: AuthenticationRequest.AsObject) {
    return new Promise<void>((resolve, reject) => {
      const client = new AuthenticationServiceClient(GRPC_ENDPOINT)
      const request = new AuthenticationRequest()
      request.setEmail(data.email)
      request.setPassword(data.password)

      client.auth(request, undefined, (err, response) => {
        if (err) {
          console.log(`${err.toString()} ${err.code} `)
          return reject(err)
        }
        commit('updateToken', response.toObject().token)
        return resolve()
      })
    })
  }
}

const getters = {
  metadata: (state: SessionState) => () => {
    return state.metaData
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export class SessionStore {
  private store

  constructor (store:Store<any>) {
    this.store = store
  }

  GetMetadata = ():grpc.Metadata => {
    return this.store.getters['session/metadata']()
  }

  Login = (data: AuthenticationRequest.AsObject) => {
    return this.store.dispatch('session/login', data)
  }

  Logout = () => {
    this.store.commit('session/clearToken')
  }
}

export const Metadata = (store: any):grpc.Metadata => {
  const session = new SessionStore(store)
  return session.GetMetadata()
}
