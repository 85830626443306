// source: api/gateway.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var common_common_pb = require('../common/common_pb.js');
goog.object.extend(proto, common_common_pb);
goog.exportSymbol('proto.api.CheckInReply', null, global);
goog.exportSymbol('proto.api.CheckInRequest', null, global);
goog.exportSymbol('proto.api.CheckInRequest.Device', null, global);
goog.exportSymbol('proto.api.CheckInRequest.Interface', null, global);
goog.exportSymbol('proto.api.CheckInRequest.Network', null, global);
goog.exportSymbol('proto.api.CheckInRequest.Route', null, global);
goog.exportSymbol('proto.api.CheckInRequest.Serial', null, global);
goog.exportSymbol('proto.api.CheckInRequest.System', null, global);
goog.exportSymbol('proto.api.CreateGatewayReply', null, global);
goog.exportSymbol('proto.api.CreateGatewayRequest', null, global);
goog.exportSymbol('proto.api.DeleteGatewayReply', null, global);
goog.exportSymbol('proto.api.DeleteGatewayRequest', null, global);
goog.exportSymbol('proto.api.Gateway', null, global);
goog.exportSymbol('proto.api.ListGatewayReply', null, global);
goog.exportSymbol('proto.api.ListGatewayRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DeleteGatewayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DeleteGatewayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DeleteGatewayRequest.displayName = 'proto.api.DeleteGatewayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DeleteGatewayReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DeleteGatewayReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DeleteGatewayReply.displayName = 'proto.api.DeleteGatewayReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CreateGatewayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CreateGatewayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CreateGatewayRequest.displayName = 'proto.api.CreateGatewayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CreateGatewayReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CreateGatewayReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CreateGatewayReply.displayName = 'proto.api.CreateGatewayReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListGatewayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListGatewayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListGatewayRequest.displayName = 'proto.api.ListGatewayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Gateway = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Gateway, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Gateway.displayName = 'proto.api.Gateway';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListGatewayReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ListGatewayReply.repeatedFields_, null);
};
goog.inherits(proto.api.ListGatewayReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListGatewayReply.displayName = 'proto.api.ListGatewayReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CheckInRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CheckInRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CheckInRequest.displayName = 'proto.api.CheckInRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CheckInRequest.Interface = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CheckInRequest.Interface, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CheckInRequest.Interface.displayName = 'proto.api.CheckInRequest.Interface';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CheckInRequest.Route = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CheckInRequest.Route, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CheckInRequest.Route.displayName = 'proto.api.CheckInRequest.Route';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CheckInRequest.Serial = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CheckInRequest.Serial, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CheckInRequest.Serial.displayName = 'proto.api.CheckInRequest.Serial';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CheckInRequest.System = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CheckInRequest.System, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CheckInRequest.System.displayName = 'proto.api.CheckInRequest.System';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CheckInRequest.Network = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.CheckInRequest.Network.repeatedFields_, null);
};
goog.inherits(proto.api.CheckInRequest.Network, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CheckInRequest.Network.displayName = 'proto.api.CheckInRequest.Network';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CheckInRequest.Device = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.CheckInRequest.Device.repeatedFields_, null);
};
goog.inherits(proto.api.CheckInRequest.Device, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CheckInRequest.Device.displayName = 'proto.api.CheckInRequest.Device';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CheckInReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CheckInReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CheckInReply.displayName = 'proto.api.CheckInReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DeleteGatewayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DeleteGatewayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DeleteGatewayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteGatewayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DeleteGatewayRequest}
 */
proto.api.DeleteGatewayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DeleteGatewayRequest;
  return proto.api.DeleteGatewayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DeleteGatewayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DeleteGatewayRequest}
 */
proto.api.DeleteGatewayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DeleteGatewayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DeleteGatewayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DeleteGatewayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteGatewayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.DeleteGatewayRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.DeleteGatewayRequest} returns this
 */
proto.api.DeleteGatewayRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DeleteGatewayReply.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DeleteGatewayReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DeleteGatewayReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteGatewayReply.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DeleteGatewayReply}
 */
proto.api.DeleteGatewayReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DeleteGatewayReply;
  return proto.api.DeleteGatewayReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DeleteGatewayReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DeleteGatewayReply}
 */
proto.api.DeleteGatewayReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DeleteGatewayReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DeleteGatewayReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DeleteGatewayReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteGatewayReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CreateGatewayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CreateGatewayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CreateGatewayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateGatewayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    params: (f = msg.getParams()) && proto.api.Gateway.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CreateGatewayRequest}
 */
proto.api.CreateGatewayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CreateGatewayRequest;
  return proto.api.CreateGatewayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CreateGatewayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CreateGatewayRequest}
 */
proto.api.CreateGatewayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.Gateway;
      reader.readMessage(value,proto.api.Gateway.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CreateGatewayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CreateGatewayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CreateGatewayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateGatewayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.Gateway.serializeBinaryToWriter
    );
  }
};


/**
 * optional Gateway params = 1;
 * @return {?proto.api.Gateway}
 */
proto.api.CreateGatewayRequest.prototype.getParams = function() {
  return /** @type{?proto.api.Gateway} */ (
    jspb.Message.getWrapperField(this, proto.api.Gateway, 1));
};


/**
 * @param {?proto.api.Gateway|undefined} value
 * @return {!proto.api.CreateGatewayRequest} returns this
*/
proto.api.CreateGatewayRequest.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CreateGatewayRequest} returns this
 */
proto.api.CreateGatewayRequest.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CreateGatewayRequest.prototype.hasParams = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CreateGatewayReply.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CreateGatewayReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CreateGatewayReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateGatewayReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CreateGatewayReply}
 */
proto.api.CreateGatewayReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CreateGatewayReply;
  return proto.api.CreateGatewayReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CreateGatewayReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CreateGatewayReply}
 */
proto.api.CreateGatewayReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CreateGatewayReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CreateGatewayReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CreateGatewayReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateGatewayReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.CreateGatewayReply.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreateGatewayReply} returns this
 */
proto.api.CreateGatewayReply.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListGatewayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListGatewayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListGatewayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListGatewayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organizationId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListGatewayRequest}
 */
proto.api.ListGatewayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListGatewayRequest;
  return proto.api.ListGatewayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListGatewayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListGatewayRequest}
 */
proto.api.ListGatewayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListGatewayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListGatewayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListGatewayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListGatewayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.api.ListGatewayRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListGatewayRequest} returns this
 */
proto.api.ListGatewayRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string organization_id = 2;
 * @return {string}
 */
proto.api.ListGatewayRequest.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListGatewayRequest} returns this
 */
proto.api.ListGatewayRequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Gateway.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Gateway.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Gateway} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Gateway.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hardwareId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    challengeKey: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    note: jspb.Message.getFieldWithDefault(msg, 6, ""),
    location: jspb.Message.getFieldWithDefault(msg, 7, ""),
    shared: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    organizationId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    checkinAt: (f = msg.getCheckinAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    projectId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    checkin: (f = msg.getCheckin()) && proto.api.CheckInRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Gateway}
 */
proto.api.Gateway.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Gateway;
  return proto.api.Gateway.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Gateway} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Gateway}
 */
proto.api.Gateway.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setChallengeKey(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShared(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCheckinAt(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 12:
      var value = new proto.api.CheckInRequest;
      reader.readMessage(value,proto.api.CheckInRequest.deserializeBinaryFromReader);
      msg.setCheckin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Gateway.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Gateway.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Gateway} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Gateway.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHardwareId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getChallengeKey();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getShared();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCheckinAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCheckin();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.api.CheckInRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.Gateway.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Gateway} returns this
 */
proto.api.Gateway.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.Gateway.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Gateway} returns this
 */
proto.api.Gateway.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string hardware_id = 3;
 * @return {string}
 */
proto.api.Gateway.prototype.getHardwareId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Gateway} returns this
 */
proto.api.Gateway.prototype.setHardwareId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string challenge_key = 4;
 * @return {string}
 */
proto.api.Gateway.prototype.getChallengeKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Gateway} returns this
 */
proto.api.Gateway.prototype.setChallengeKey = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.Gateway.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.Gateway} returns this
*/
proto.api.Gateway.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Gateway} returns this
 */
proto.api.Gateway.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Gateway.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string note = 6;
 * @return {string}
 */
proto.api.Gateway.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Gateway} returns this
 */
proto.api.Gateway.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string location = 7;
 * @return {string}
 */
proto.api.Gateway.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Gateway} returns this
 */
proto.api.Gateway.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool shared = 8;
 * @return {boolean}
 */
proto.api.Gateway.prototype.getShared = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Gateway} returns this
 */
proto.api.Gateway.prototype.setShared = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string organization_id = 9;
 * @return {string}
 */
proto.api.Gateway.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Gateway} returns this
 */
proto.api.Gateway.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp checkin_at = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.Gateway.prototype.getCheckinAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.Gateway} returns this
*/
proto.api.Gateway.prototype.setCheckinAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Gateway} returns this
 */
proto.api.Gateway.prototype.clearCheckinAt = function() {
  return this.setCheckinAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Gateway.prototype.hasCheckinAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string project_id = 11;
 * @return {string}
 */
proto.api.Gateway.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Gateway} returns this
 */
proto.api.Gateway.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional CheckInRequest checkin = 12;
 * @return {?proto.api.CheckInRequest}
 */
proto.api.Gateway.prototype.getCheckin = function() {
  return /** @type{?proto.api.CheckInRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.CheckInRequest, 12));
};


/**
 * @param {?proto.api.CheckInRequest|undefined} value
 * @return {!proto.api.Gateway} returns this
*/
proto.api.Gateway.prototype.setCheckin = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Gateway} returns this
 */
proto.api.Gateway.prototype.clearCheckin = function() {
  return this.setCheckin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Gateway.prototype.hasCheckin = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ListGatewayReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListGatewayReply.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListGatewayReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListGatewayReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListGatewayReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultList: jspb.Message.toObjectList(msg.getResultList(),
    proto.api.Gateway.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListGatewayReply}
 */
proto.api.ListGatewayReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListGatewayReply;
  return proto.api.ListGatewayReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListGatewayReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListGatewayReply}
 */
proto.api.ListGatewayReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.Gateway;
      reader.readMessage(value,proto.api.Gateway.deserializeBinaryFromReader);
      msg.addResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListGatewayReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListGatewayReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListGatewayReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListGatewayReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.Gateway.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Gateway result = 1;
 * @return {!Array<!proto.api.Gateway>}
 */
proto.api.ListGatewayReply.prototype.getResultList = function() {
  return /** @type{!Array<!proto.api.Gateway>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.Gateway, 1));
};


/**
 * @param {!Array<!proto.api.Gateway>} value
 * @return {!proto.api.ListGatewayReply} returns this
*/
proto.api.ListGatewayReply.prototype.setResultList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.Gateway=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.Gateway}
 */
proto.api.ListGatewayReply.prototype.addResult = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.Gateway, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ListGatewayReply} returns this
 */
proto.api.ListGatewayReply.prototype.clearResultList = function() {
  return this.setResultList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CheckInRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CheckInRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CheckInRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CheckInRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    hardwareId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    system: (f = msg.getSystem()) && proto.api.CheckInRequest.System.toObject(includeInstance, f),
    network: (f = msg.getNetwork()) && proto.api.CheckInRequest.Network.toObject(includeInstance, f),
    device: (f = msg.getDevice()) && proto.api.CheckInRequest.Device.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CheckInRequest}
 */
proto.api.CheckInRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CheckInRequest;
  return proto.api.CheckInRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CheckInRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CheckInRequest}
 */
proto.api.CheckInRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareId(value);
      break;
    case 2:
      var value = new proto.api.CheckInRequest.System;
      reader.readMessage(value,proto.api.CheckInRequest.System.deserializeBinaryFromReader);
      msg.setSystem(value);
      break;
    case 3:
      var value = new proto.api.CheckInRequest.Network;
      reader.readMessage(value,proto.api.CheckInRequest.Network.deserializeBinaryFromReader);
      msg.setNetwork(value);
      break;
    case 4:
      var value = new proto.api.CheckInRequest.Device;
      reader.readMessage(value,proto.api.CheckInRequest.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CheckInRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CheckInRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CheckInRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CheckInRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardwareId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSystem();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.CheckInRequest.System.serializeBinaryToWriter
    );
  }
  f = message.getNetwork();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.CheckInRequest.Network.serializeBinaryToWriter
    );
  }
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.api.CheckInRequest.Device.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CheckInRequest.Interface.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CheckInRequest.Interface.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CheckInRequest.Interface} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CheckInRequest.Interface.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ipaddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    netmask: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CheckInRequest.Interface}
 */
proto.api.CheckInRequest.Interface.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CheckInRequest.Interface;
  return proto.api.CheckInRequest.Interface.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CheckInRequest.Interface} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CheckInRequest.Interface}
 */
proto.api.CheckInRequest.Interface.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpaddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetmask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CheckInRequest.Interface.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CheckInRequest.Interface.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CheckInRequest.Interface} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CheckInRequest.Interface.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIpaddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNetmask();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.api.CheckInRequest.Interface.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CheckInRequest.Interface} returns this
 */
proto.api.CheckInRequest.Interface.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ipaddress = 2;
 * @return {string}
 */
proto.api.CheckInRequest.Interface.prototype.getIpaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CheckInRequest.Interface} returns this
 */
proto.api.CheckInRequest.Interface.prototype.setIpaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string netmask = 3;
 * @return {string}
 */
proto.api.CheckInRequest.Interface.prototype.getNetmask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CheckInRequest.Interface} returns this
 */
proto.api.CheckInRequest.Interface.prototype.setNetmask = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CheckInRequest.Route.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CheckInRequest.Route.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CheckInRequest.Route} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CheckInRequest.Route.toObject = function(includeInstance, msg) {
  var f, obj = {
    destination: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gateway: jspb.Message.getFieldWithDefault(msg, 2, ""),
    genmask: jspb.Message.getFieldWithDefault(msg, 3, ""),
    flags: jspb.Message.getFieldWithDefault(msg, 4, ""),
    metric: jspb.Message.getFieldWithDefault(msg, 5, 0),
    iface: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CheckInRequest.Route}
 */
proto.api.CheckInRequest.Route.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CheckInRequest.Route;
  return proto.api.CheckInRequest.Route.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CheckInRequest.Route} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CheckInRequest.Route}
 */
proto.api.CheckInRequest.Route.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestination(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGateway(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGenmask(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlags(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMetric(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIface(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CheckInRequest.Route.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CheckInRequest.Route.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CheckInRequest.Route} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CheckInRequest.Route.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDestination();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGateway();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGenmask();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFlags();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMetric();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getIface();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string destination = 1;
 * @return {string}
 */
proto.api.CheckInRequest.Route.prototype.getDestination = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CheckInRequest.Route} returns this
 */
proto.api.CheckInRequest.Route.prototype.setDestination = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string gateway = 2;
 * @return {string}
 */
proto.api.CheckInRequest.Route.prototype.getGateway = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CheckInRequest.Route} returns this
 */
proto.api.CheckInRequest.Route.prototype.setGateway = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string genmask = 3;
 * @return {string}
 */
proto.api.CheckInRequest.Route.prototype.getGenmask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CheckInRequest.Route} returns this
 */
proto.api.CheckInRequest.Route.prototype.setGenmask = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string flags = 4;
 * @return {string}
 */
proto.api.CheckInRequest.Route.prototype.getFlags = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CheckInRequest.Route} returns this
 */
proto.api.CheckInRequest.Route.prototype.setFlags = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 metric = 5;
 * @return {number}
 */
proto.api.CheckInRequest.Route.prototype.getMetric = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.CheckInRequest.Route} returns this
 */
proto.api.CheckInRequest.Route.prototype.setMetric = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string iface = 6;
 * @return {string}
 */
proto.api.CheckInRequest.Route.prototype.getIface = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CheckInRequest.Route} returns this
 */
proto.api.CheckInRequest.Route.prototype.setIface = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CheckInRequest.Serial.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CheckInRequest.Serial.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CheckInRequest.Serial} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CheckInRequest.Serial.toObject = function(includeInstance, msg) {
  var f, obj = {
    portName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    devPath: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CheckInRequest.Serial}
 */
proto.api.CheckInRequest.Serial.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CheckInRequest.Serial;
  return proto.api.CheckInRequest.Serial.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CheckInRequest.Serial} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CheckInRequest.Serial}
 */
proto.api.CheckInRequest.Serial.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPortName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CheckInRequest.Serial.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CheckInRequest.Serial.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CheckInRequest.Serial} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CheckInRequest.Serial.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPortName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDevPath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string port_name = 1;
 * @return {string}
 */
proto.api.CheckInRequest.Serial.prototype.getPortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CheckInRequest.Serial} returns this
 */
proto.api.CheckInRequest.Serial.prototype.setPortName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dev_path = 2;
 * @return {string}
 */
proto.api.CheckInRequest.Serial.prototype.getDevPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CheckInRequest.Serial} returns this
 */
proto.api.CheckInRequest.Serial.prototype.setDevPath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CheckInRequest.System.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CheckInRequest.System.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CheckInRequest.System} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CheckInRequest.System.toObject = function(includeInstance, msg) {
  var f, obj = {
    cpuUsage: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    memTotal: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    memFree: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    uptime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    osName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    osRelease: jspb.Message.getFieldWithDefault(msg, 6, ""),
    systemTime: (f = msg.getSystemTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    swVersion: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CheckInRequest.System}
 */
proto.api.CheckInRequest.System.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CheckInRequest.System;
  return proto.api.CheckInRequest.System.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CheckInRequest.System} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CheckInRequest.System}
 */
proto.api.CheckInRequest.System.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCpuUsage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMemTotal(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMemFree(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUptime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOsName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOsRelease(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSystemTime(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSwVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CheckInRequest.System.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CheckInRequest.System.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CheckInRequest.System} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CheckInRequest.System.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCpuUsage();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getMemTotal();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getMemFree();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getUptime();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getOsName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOsRelease();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSystemTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSwVersion();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional float cpu_usage = 1;
 * @return {number}
 */
proto.api.CheckInRequest.System.prototype.getCpuUsage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.api.CheckInRequest.System} returns this
 */
proto.api.CheckInRequest.System.prototype.setCpuUsage = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float mem_total = 2;
 * @return {number}
 */
proto.api.CheckInRequest.System.prototype.getMemTotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.api.CheckInRequest.System} returns this
 */
proto.api.CheckInRequest.System.prototype.setMemTotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float mem_free = 3;
 * @return {number}
 */
proto.api.CheckInRequest.System.prototype.getMemFree = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.api.CheckInRequest.System} returns this
 */
proto.api.CheckInRequest.System.prototype.setMemFree = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional uint64 uptime = 4;
 * @return {number}
 */
proto.api.CheckInRequest.System.prototype.getUptime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.CheckInRequest.System} returns this
 */
proto.api.CheckInRequest.System.prototype.setUptime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string os_name = 5;
 * @return {string}
 */
proto.api.CheckInRequest.System.prototype.getOsName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CheckInRequest.System} returns this
 */
proto.api.CheckInRequest.System.prototype.setOsName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string os_release = 6;
 * @return {string}
 */
proto.api.CheckInRequest.System.prototype.getOsRelease = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CheckInRequest.System} returns this
 */
proto.api.CheckInRequest.System.prototype.setOsRelease = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp system_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.CheckInRequest.System.prototype.getSystemTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.CheckInRequest.System} returns this
*/
proto.api.CheckInRequest.System.prototype.setSystemTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CheckInRequest.System} returns this
 */
proto.api.CheckInRequest.System.prototype.clearSystemTime = function() {
  return this.setSystemTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CheckInRequest.System.prototype.hasSystemTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string sw_version = 8;
 * @return {string}
 */
proto.api.CheckInRequest.System.prototype.getSwVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CheckInRequest.System} returns this
 */
proto.api.CheckInRequest.System.prototype.setSwVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.CheckInRequest.Network.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CheckInRequest.Network.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CheckInRequest.Network.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CheckInRequest.Network} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CheckInRequest.Network.toObject = function(includeInstance, msg) {
  var f, obj = {
    interfaceList: jspb.Message.toObjectList(msg.getInterfaceList(),
    proto.api.CheckInRequest.Interface.toObject, includeInstance),
    routeList: jspb.Message.toObjectList(msg.getRouteList(),
    proto.api.CheckInRequest.Route.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CheckInRequest.Network}
 */
proto.api.CheckInRequest.Network.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CheckInRequest.Network;
  return proto.api.CheckInRequest.Network.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CheckInRequest.Network} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CheckInRequest.Network}
 */
proto.api.CheckInRequest.Network.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.CheckInRequest.Interface;
      reader.readMessage(value,proto.api.CheckInRequest.Interface.deserializeBinaryFromReader);
      msg.addInterface(value);
      break;
    case 2:
      var value = new proto.api.CheckInRequest.Route;
      reader.readMessage(value,proto.api.CheckInRequest.Route.deserializeBinaryFromReader);
      msg.addRoute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CheckInRequest.Network.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CheckInRequest.Network.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CheckInRequest.Network} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CheckInRequest.Network.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInterfaceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.CheckInRequest.Interface.serializeBinaryToWriter
    );
  }
  f = message.getRouteList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.api.CheckInRequest.Route.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Interface interface = 1;
 * @return {!Array<!proto.api.CheckInRequest.Interface>}
 */
proto.api.CheckInRequest.Network.prototype.getInterfaceList = function() {
  return /** @type{!Array<!proto.api.CheckInRequest.Interface>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.CheckInRequest.Interface, 1));
};


/**
 * @param {!Array<!proto.api.CheckInRequest.Interface>} value
 * @return {!proto.api.CheckInRequest.Network} returns this
*/
proto.api.CheckInRequest.Network.prototype.setInterfaceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.CheckInRequest.Interface=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.CheckInRequest.Interface}
 */
proto.api.CheckInRequest.Network.prototype.addInterface = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.CheckInRequest.Interface, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.CheckInRequest.Network} returns this
 */
proto.api.CheckInRequest.Network.prototype.clearInterfaceList = function() {
  return this.setInterfaceList([]);
};


/**
 * repeated Route route = 2;
 * @return {!Array<!proto.api.CheckInRequest.Route>}
 */
proto.api.CheckInRequest.Network.prototype.getRouteList = function() {
  return /** @type{!Array<!proto.api.CheckInRequest.Route>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.CheckInRequest.Route, 2));
};


/**
 * @param {!Array<!proto.api.CheckInRequest.Route>} value
 * @return {!proto.api.CheckInRequest.Network} returns this
*/
proto.api.CheckInRequest.Network.prototype.setRouteList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.api.CheckInRequest.Route=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.CheckInRequest.Route}
 */
proto.api.CheckInRequest.Network.prototype.addRoute = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.api.CheckInRequest.Route, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.CheckInRequest.Network} returns this
 */
proto.api.CheckInRequest.Network.prototype.clearRouteList = function() {
  return this.setRouteList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.CheckInRequest.Device.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CheckInRequest.Device.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CheckInRequest.Device.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CheckInRequest.Device} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CheckInRequest.Device.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialList: jspb.Message.toObjectList(msg.getSerialList(),
    proto.api.CheckInRequest.Serial.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CheckInRequest.Device}
 */
proto.api.CheckInRequest.Device.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CheckInRequest.Device;
  return proto.api.CheckInRequest.Device.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CheckInRequest.Device} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CheckInRequest.Device}
 */
proto.api.CheckInRequest.Device.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.CheckInRequest.Serial;
      reader.readMessage(value,proto.api.CheckInRequest.Serial.deserializeBinaryFromReader);
      msg.addSerial(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CheckInRequest.Device.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CheckInRequest.Device.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CheckInRequest.Device} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CheckInRequest.Device.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.CheckInRequest.Serial.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Serial serial = 1;
 * @return {!Array<!proto.api.CheckInRequest.Serial>}
 */
proto.api.CheckInRequest.Device.prototype.getSerialList = function() {
  return /** @type{!Array<!proto.api.CheckInRequest.Serial>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.CheckInRequest.Serial, 1));
};


/**
 * @param {!Array<!proto.api.CheckInRequest.Serial>} value
 * @return {!proto.api.CheckInRequest.Device} returns this
*/
proto.api.CheckInRequest.Device.prototype.setSerialList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.CheckInRequest.Serial=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.CheckInRequest.Serial}
 */
proto.api.CheckInRequest.Device.prototype.addSerial = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.CheckInRequest.Serial, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.CheckInRequest.Device} returns this
 */
proto.api.CheckInRequest.Device.prototype.clearSerialList = function() {
  return this.setSerialList([]);
};


/**
 * optional string hardware_id = 1;
 * @return {string}
 */
proto.api.CheckInRequest.prototype.getHardwareId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CheckInRequest} returns this
 */
proto.api.CheckInRequest.prototype.setHardwareId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional System system = 2;
 * @return {?proto.api.CheckInRequest.System}
 */
proto.api.CheckInRequest.prototype.getSystem = function() {
  return /** @type{?proto.api.CheckInRequest.System} */ (
    jspb.Message.getWrapperField(this, proto.api.CheckInRequest.System, 2));
};


/**
 * @param {?proto.api.CheckInRequest.System|undefined} value
 * @return {!proto.api.CheckInRequest} returns this
*/
proto.api.CheckInRequest.prototype.setSystem = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CheckInRequest} returns this
 */
proto.api.CheckInRequest.prototype.clearSystem = function() {
  return this.setSystem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CheckInRequest.prototype.hasSystem = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Network network = 3;
 * @return {?proto.api.CheckInRequest.Network}
 */
proto.api.CheckInRequest.prototype.getNetwork = function() {
  return /** @type{?proto.api.CheckInRequest.Network} */ (
    jspb.Message.getWrapperField(this, proto.api.CheckInRequest.Network, 3));
};


/**
 * @param {?proto.api.CheckInRequest.Network|undefined} value
 * @return {!proto.api.CheckInRequest} returns this
*/
proto.api.CheckInRequest.prototype.setNetwork = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CheckInRequest} returns this
 */
proto.api.CheckInRequest.prototype.clearNetwork = function() {
  return this.setNetwork(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CheckInRequest.prototype.hasNetwork = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Device device = 4;
 * @return {?proto.api.CheckInRequest.Device}
 */
proto.api.CheckInRequest.prototype.getDevice = function() {
  return /** @type{?proto.api.CheckInRequest.Device} */ (
    jspb.Message.getWrapperField(this, proto.api.CheckInRequest.Device, 4));
};


/**
 * @param {?proto.api.CheckInRequest.Device|undefined} value
 * @return {!proto.api.CheckInRequest} returns this
*/
proto.api.CheckInRequest.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CheckInRequest} returns this
 */
proto.api.CheckInRequest.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CheckInRequest.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CheckInReply.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CheckInReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CheckInReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CheckInReply.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CheckInReply}
 */
proto.api.CheckInReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CheckInReply;
  return proto.api.CheckInReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CheckInReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CheckInReply}
 */
proto.api.CheckInReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CheckInReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CheckInReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CheckInReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CheckInReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.api);
