import * as IntegrationPb from '@/api/integration_pb'
import * as IntegrationService from '@/api/integration_grpc_web_pb'
import { CategoryMap } from '@/common/common_pb'
import { GRPC_ENDPOINT } from '@/const'
import { Metadata } from './session'
import { ErrorHandler } from './helper'
import { GetProjectId } from './organization'
import { RpcError } from 'grpc-web'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

export type AssignDeviceToBacnetIpParams = IntegrationPb.AssignDeviceToBacnetEntry.AsObject
export type IIntegrationProtocol = IntegrationPb.IntegrationProtocol.AsObject
export type IBACnetIPAssignedDevice = IntegrationPb.BacnetIpAssignedDevice.AsObject
export type IBACnetIPDataPoint = IntegrationPb.BacnetIpDataPoint.AsObject
export type IBACnetIPIntegrationParams = IntegrationPb.IntegrationBACnetIPProperties.AsObject

export abstract class IntegrationProtocol implements IIntegrationProtocol {
  id: string
  name: string
  category: CategoryMap
  bacnetIp: IntegrationPb.IntegrationBACnetIPProperties.AsObject
  createdAt?: Timestamp.AsObject | undefined
  note: string
  location: string
  projectId: string
  assignedDevices: any[]
  store: any
  gatewayId: string

  constructor (params:IIntegrationProtocol, store:any) {
    this.id = params.id
    this.name = params.name
    this.category = params.category
    this.note = params.note
    this.location = params.location
    this.projectId = params.projectId
    this.gatewayId = params.gatewayId
    this.createdAt = params.createdAt
    this.bacnetIp = params.bacnetIp!
    this.store = store
    this.assignedDevices = []
  }

  abstract FetchAssignedDevices():Promise<void>;
}
export class BACnetIntegration extends IntegrationProtocol {
  async FetchAssignedDevices () {
    // eslint-disable-next-line no-async-promise-executor

    const client = new IntegrationService.IntegrationServicePromiseClient(GRPC_ENDPOINT)
    const request = new IntegrationPb.ListBacnetIpAssignedDevicesRequest()
    request.setIntegrationProtocolId(this.id)

    const reply = await client.listBacnetIpAssignedDevices(request, Metadata(this.store))

    const result = reply.toObject().resultList as IBACnetIPAssignedDevice[]

    this.assignedDevices = result
  }

  GetAssignedDevices = () => {
    return this.assignedDevices
  }

  AssignDevices = async (devices: Array<AssignDeviceToBacnetIpParams>):Promise<string[]> => {
    return new Promise<string[]>((resolve, reject) => {
      const client = new IntegrationService.IntegrationServiceClient(GRPC_ENDPOINT)
      const request = new IntegrationPb.AssignDeviceToBacnetIpRequest()
      const Entries = new Array<IntegrationPb.AssignDeviceToBacnetEntry>()

      for (const item of devices) {
        const entry = new IntegrationPb.AssignDeviceToBacnetEntry()
        entry.setAccessDeviceId(item.accessDeviceId)
        entry.setBacnetDeviceId(item.bacnetDeviceId)
        entry.setBacnetDeviceName(item.bacnetDeviceName)
        Entries.push(entry)
      }

      request.setIntegrationProtocolId(this.id)
      request.setEntriesList(Entries)

      try {
        client.assignDeviceToBacnetIp(request, Metadata(this.store), async (err:RpcError, reply:IntegrationPb.AssignDeviceToBacnetIpReply) => {
          if (err) {
            ErrorHandler(err)
            return reject(err)
          }

          const reuslt = reply.toObject().resultList
          await this.FetchAssignedDevices()
          resolve(reuslt)
        })
      } catch (err:any) {
        reject(err)
      }
    })
  }

  GetNextBACnetDeviceId = (excludes?:number[]): number | undefined => {
    for (let i = this.bacnetIp.baseBacnetDeviceId + 1; i < 65535; i++) {
      if (!this.assignedDevices?.map(item => item.bacnetDeviceId).includes(i)) {
        if (excludes === undefined || !excludes.includes(i)) {
          return i
        }
      }
    }
  }
}

interface IState {
    list: Array<IntegrationProtocol>
}

const state:IState = {
  list: new Array<IntegrationProtocol>()
}

const actions = {}

const getters = {
  list: (state: IState) => () => {
    return state.list
  },

  get: (state: IState) => (id: string) => {
    return state.list.find(p => p.id === id)
  }
}

const mutations = {
  fetch (state: IState, protocols: IntegrationProtocol[]) {
    state.list = protocols
  },

  remove (state: IState, id: string) {
    console.log(`try to remove integration protocol: ${id}`)
    for (let i = 0; i < state.list.length; i++) {
      if (state.list[i].id === id) {
        state.list.splice(i, 1)
        break
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export class IntegrationStore {
    private store:any
    private client: IntegrationService.IntegrationServicePromiseClient
    constructor (store: any) {
      this.store = store
      this.client = new IntegrationService.IntegrationServicePromiseClient(GRPC_ENDPOINT)
    }

    Fetch = async ():Promise<void> => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<void>(async (resolve, reject) => {
        const request = new IntegrationPb.ListIntegrationProtocolsRequest()
        const projectId = await GetProjectId(this.store)
        request.setProjectId(projectId)
        try {
          const reply = await this.client.list(request, Metadata(this.store))
          const protocols = new Array<IntegrationProtocol>()
          for (const item of reply.toObject().resultList) {
            switch (item.category) {
              case CategoryMap.CATEGORY_BACNET_IP: {
                const bacnetIntegration = new BACnetIntegration(item, this.store)
                await bacnetIntegration.FetchAssignedDevices()
                protocols.push(bacnetIntegration)
                break
              }
              default:
                console.error(`Unsupported Integration Category: ${item.category}`)
                break
            }
          }

          this.store.commit('integration/fetch', protocols)
          resolve()
        } catch (err:any) {
          ErrorHandler(err)
          reject(err)
        }
      })
    }

    List = (): Array<IntegrationProtocol> => {
      return this.store.getters['integration/list']()
    }

    Create = async (protocol: IntegrationPb.IntegrationProtocol.AsObject): Promise<string> => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<string>(async (resolve, reject) => {
        const request = new IntegrationPb.CreateIntegrationRequest()
        const params = new IntegrationPb.IntegrationProtocol()
        const projectId = await GetProjectId(this.store)

        params.setName(protocol.name)
        params.setCategory(protocol.category)
        params.setProjectId(projectId)
        params.setNote(protocol.note)
        params.setLocation(protocol.location)
        params.setGatewayId(protocol.gatewayId)
        switch (protocol.category) {
          case CategoryMap.CATEGORY_BACNET_IP: {
            const properties = new IntegrationPb.IntegrationBACnetIPProperties()
            properties.setIface(protocol.bacnetIp!.iface)
            properties.setIpPort(protocol.bacnetIp!.ipPort)

            properties.setBbmdAddress(protocol.bacnetIp!.bbmdAddress)
            properties.setBbmdPort(protocol.bacnetIp!.bbmdPort)

            properties.setApduRetry(protocol.bacnetIp!.apduRetry)
            properties.setApduTimeout(protocol.bacnetIp!.apduTimeout)
            properties.setBaseBacnetDeviceId(protocol.bacnetIp!.baseBacnetDeviceId)

            properties.setNatAddress(protocol.bacnetIp!.natAddress)
            properties.setBroadcastBindAddr(protocol.bacnetIp!.broadcastBindAddr)
            params.setBacnetIp(properties)
            break
          }
        }

        request.setParams(params)
        try {
          const reply = await this.client.create(request, Metadata(this.store))
          resolve(reply.getId())
        } catch (err:any) {
          ErrorHandler(err)
          console.error(`Unable to create Integration Protocol: ${err.message}`)
          reject(err)
        }
      })
    }

    Get = (id:string) : IntegrationProtocol => {
      return this.store.getters['integration/get'](id)
    }

    Delete = async (id: string) => {
      const request = new IntegrationPb.RemoveIntegraionRequest()
      request.setId(id)

      await this.client.remove(request, Metadata(this.store))
      this.store.commit('integration/remove', id)
    }
}
